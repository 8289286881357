import { modalGroupsData } from "../../model/components/modalGroupsData"

const processModalGroups = (item) => {
let modalGroups

//Determining if ModalGroups are internal or loaded from modalGroupsData
if (Array.isArray(item.modalGroups) ) modalGroups = item.modalGroups
if (typeof item.modalGroups === 'string') modalGroups = modalGroupsData[item.modalGroups].modalGroups

return modalGroups

}

export default processModalGroups