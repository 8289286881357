import { useEffect } from "react";
import Triangle from "../generic/Triangle";
import { TextControl } from "../generic/Core";
import { RelativeWrapper } from "../generic/Layout";
import useKeyPress from "../../hooks/useKeyPress";

const SelectInput = ({ showModal, placeholder, valueName, name, isRed }) => {
    const enterPressed = useKeyPress('Enter')

    useEffect(() => {
        const focusedElem = document.activeElement;
        if (enterPressed && focusedElem.name === 'countryName') {
            showModal()
        }
    }, [enterPressed]);

    return (
        <RelativeWrapper >
            <TextControl  onClick={showModal} value={valueName} name={name} placeholder={placeholder} autoComplete={'password-new'} onChange={ (e) => console.log('New Value', e.target.value)}
                {...(isRed && { required: true })}
            >
            </TextControl>
            <Triangle {...(isRed? { display: 'none' }: {display: 'block'})}/>
        </RelativeWrapper>
    );
};

export default SelectInput


