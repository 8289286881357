import { useEffect } from "react";
import { proxy, useSnapshot } from "valtio";

const authState = proxy({data:{  }})

const useAuth = () => {
const snap = useSnapshot(authState)
    const Auth = snap.data

    useEffect(() => {
        //      console.log('Auth:', Auth)
    }, [Auth])

    const setAuth = (obj) => {
        authState.data = obj
    }


    return { Auth, setAuth }
}

export default useAuth;
