import { useEffect } from 'react'
import { pageViewData } from '../../model/components/pageViewData'
import { useParams, useSearchParams } from 'react-router-dom'
import generateEndpoint from '../../utils/generateEndpoint'
import useSettings from '../../hooks/useSettings'

import transferSettings from '../pageView/processSettings'

//setup using the API
import { useQuery } from "react-query"
import useApi from "../../hooks/useApi"

//Importing the Subcomponents
import PageLayout from './PageLayout'
import TransitionLayer from './layers/TransitionLayer'


import Loader from '../generic/Loader'

import { useStore } from './Page'


const PageView = ({ type, options, dispatch }) => {
  //Config to read the data from the Model
  let config = pageViewData[type]

  //Setting up the state for the form.
  const [loaded, setState] = useStore((s) => s['loaded'])

  const { getMe } = useApi()
  const { sData } = useSettings()
  //console.log('[PageView] sData: ', sData)

  //Getting the option params
  const mode = options.mode
  const load = options.load

  //Id of the entity shown taken from the URL. Collects both id and id2 if it exists
  const { id, id2 } = useParams()
  const [searchParams] = useSearchParams();

  // Retrieve the value of the 'state' parameter and store it in the sliug variable
  const slug = searchParams.get('state');

  //this formats the endpoint to include the first id in the main route in case of a subroute
  let endpoint;
  if (config && config.path !== undefined) {
    endpoint = generateEndpoint(config.path, id);
  }

  //this assigns id2 if that exists otherwise it is id
  let item = id2 ?? id
  if (options.forcedId) item = options.forcedId
  if (options.forcedPath) endpoint = options.forcedPath

  if (options.createFromEntity) {
    options.createFromId = item
  }

  let result = {}

  if (load) {
    let queryKey
    if (slug) queryKey = item !== undefined ? [`${endpoint}`, `${item}`, `${slug}`] : [`${endpoint}`, `${slug}`];
    else queryKey = item !== undefined ? [`${endpoint}`, `${item}`] : [`${endpoint}`];
    result = useQuery(queryKey, () => getMe(`${endpoint}${item ? `/${item}` : ''}`), {
      refetchOnMount: true,
    });
  } else if (!load) {
    if (result) {
      let data = {
        data: { data: { state: 'draft' } },
        isSuccess: true
      }
      result = { ...data }
    }
  }

  //Initilize the state on successfull load.
  useEffect(
    () => {
      let enrichedData
      if (result.data && !loaded) {
        //Temp Fix in case the id is empty
        //if (result.data.data.id === '') result.data.data.id = item
        // Set settings in data if available
        if (config?.loadSettings !== undefined && sData) {
          enrichedData = transferSettings(result.data.data, sData, config?.loadSettings)
          setState({ ...enrichedData, loaded: true, entity: config.entity })
        } else {
          setState({ ...result.data.data, loaded: true, entity: config.entity })
        }
      }
    }
    , [result.isSuccess]
  )

  // Here we wait for settings to finish loading if it exists
  if (result.isLoading) { return <Loader /> }

  //Here we wait for the Record to finish Loading.
  if (result.isError) { return <span>Error: {result.data.message}</span> }


  if (result.isSuccess && loaded) {
    //console.log('Rendering PageView')
    return (
      <>
        {config.transition?.active? <TransitionLayer type={type} options={options} dispatch={dispatch} config={config} /> 
        : <PageLayout type={type} options={options} dispatch={dispatch} />}
      </>
    );

  }
}

export default PageView