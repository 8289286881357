import { Icon_PeopleFill, Icon_Factory, Icon_Purchases, Icon_CustomerService, 
    Icon_Inventory, Icon_Task, Icon_Invoice, Icon_Briefcase, Icon_Contacts, 
    Icon_Warehouse} from "../../styles/icons";

export const appListData = [
    {
        appId: 'Invoice',
        appTitle: 'app.list.invoice.title',
        appDescription: 'app.list.invoice.description',
        appIcon: Icon_Invoice,
        appUrl: '/sales'
    },
    {
        appId: 'CRM',
        appTitle: 'app.list.01.title',
        appDescription: 'app.list.01.description',
        appUrl: '/crm',
        appIcon: Icon_PeopleFill
    },
    {
        appId: 'Service',
        appTitle: 'app.list.04.title',
        appUrl: '/service',
        appDescription: 'app.list.04.description',
        appIcon: Icon_CustomerService
    },
    {
        appId: 'Inventory',
        appTitle: 'app.list.05.title',
        appUrl: '/inventory',
        appDescription: 'app.list.05.description',
        appIcon: Icon_Inventory
    },
    {
        appId: 'Manufacturing',
        appTitle: 'app.list.02.title',
        appDescription: 'app.list.02.description',
        appIcon: Icon_Factory
    },
    {
        appId: 'Accounting',
        appTitle: 'app.list.03.title',
        appDescription: 'app.list.03.description',
        appIcon: Icon_Briefcase
    },
    {
        appId: 'Project',
        appTitle: 'app.list.06.title',
        appDescription: 'app.list.06.description',
        appIcon: Icon_Task
    },
    {
        appId: 'Purchase',
        appTitle: 'app.list.07.title',
        appUrl: '/purchases',
        appDescription: 'app.list.07.description',
        appIcon: Icon_Purchases
    }
]