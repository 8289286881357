import styled from "styled-components";

import { Icon_Drag } from "../../styles/icons.jsx";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";

const StyledHandle = styled(PanelResizeHandle)`
  position: relative;
  width: 0.5rem;
  margin-inline: 0.25rem;
  :hover {
    background-color: ${({ theme }) => theme.area.highBg};
  }

`
const HandleIcon = styled.div`
    position:absolute;
    top:35vh;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 0%);
`

const ResizeHandle = () => {
  return (
    <StyledHandle><HandleIcon>{Icon_Drag}</HandleIcon></StyledHandle>
  )
}

export {ResizeHandle, PanelGroup, Panel}

