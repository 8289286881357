import { useState } from 'react'

const useSectionState =  (config, options) => {
    let formOpen = true
    options.collapse ? formOpen = false : formOpen = true

    let sectionExpanded = {}
    config.sections.forEach(
        (section) => {
            sectionExpanded[section.meta.name] = formOpen
        }
    )


    const [expanded, setExpanded] = useState(sectionExpanded)

    const toggleExpanded = (sectionName) => {
        setExpanded(prevState => ({
            ...prevState,
            [sectionName]: !prevState[sectionName] // Toggle the value based on sectionName
        }));
    }

    return {expanded, toggleExpanded}

}

export default useSectionState

