import React from 'react';
import MobileActionButton from "./MobileActionButton"
import MobileOptionPanel from "./MobileOptionPanel"


const MobilePanel = ({ config, validated, useStore }) => {

    return (

        config.sections.map((section, i) => {
            return (
                <React.Fragment key={i}>
                    {(section.type === 'actionButton') ? <MobileActionButton validated={validated}
                        config={section} ></MobileActionButton> : <></>}
                    {(section.type === 'optionPanel') ? <MobileOptionPanel validated={validated}
                        config={section} useStore={useStore}></MobileOptionPanel>: <></>}

                </React.Fragment>
            )
        })

    )

}

export default MobilePanel