import React, { useState, useRef } from 'react';
import DropDown from '../generic/DropDown';

//Import the useClick Outside
import useOutsideClick from '../../hooks/useOutsideClick';
import useScroll from '../../hooks/useScroll';
import { useNavigate } from 'react-router-dom';


export const Switcher = ({ currentOption, options, handleSelect, startPos, bg }) => {
    const [isVisible, setIsVisible] = useState(false);
    const menuRef = useRef(null)
    const navigate = useNavigate()

    if (startPos === null) {
        startPos = -1
    }

    const { handleKeyNav } = useScroll(menuRef, isVisible, setIsVisible, options, startPos)

    const handleToggleClick = () => {
        setIsVisible(!isVisible)
    }

    const clickItem = (option) => {
        setIsVisible(false)
        handleSelect(option)
    }

    //Handle Click Outside the Object
    useOutsideClick(menuRef, handleToggleClick, 'dropdown');

    const manyItems = (options.lenght > 1)
    const items = options.map((option, key) => {
        return (
            <React.Fragment key={`F-${option.id}`}>
                {option.type === 'item' ? <DropDown.Item key={option.id} onClick={() => clickItem(option)} > {manyItems ? 'No Other entry' : option.name} </DropDown.Item> : <></>}
                {option.type === 'inactive' ? <DropDown.Item $inactive key={option.id} > {option.name} </DropDown.Item> : <></>}
                {option.type === 'action' ? <DropDown.Item className='action' key={option.id} onClick={() => navigate(option.path)}> {option.name}</DropDown.Item> : <></>}
                {option.type === 'divider' ? <DropDown.Divider key={option.id} /> : <></>}
            </React.Fragment>
        )
    });

    return (
        <>
            <DropDown $margin='0 0.5rem 0 0' name='Dropdown'>
                <DropDown.Toggle
                    name='Toggle'
                    onClick={handleToggleClick}
                    onKeyDown={handleKeyNav}
                    $bg={bg}
                >
                    {currentOption}</DropDown.Toggle>
                {isVisible && (<DropDown.Menu ref={menuRef} name='Menu' >
                    {items}
                </DropDown.Menu>)}
            </DropDown>
        </>
    );
}