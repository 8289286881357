import { getFromLS, saveToLS } from "../utils/localStore/localStore";
import { useState, useEffect } from 'react';

const useActiveElement = (storeEntry, initialActiveFilter, lookupKey) => {
  const [activeElem, setActiveElem] = useState(initialActiveFilter);

  useEffect(() => {
//    const currentActiveKey = getFromLS(storeEntry, lookupKey);
    if (!initialActiveFilter) { setActiveElem(0) } 
  }, []);

  const handleActiveElem = (value) => {
    setActiveElem(value);
    saveToLS(storeEntry, lookupKey, value);
  };

  return { activeElem, handleActiveElem };
};

export default useActiveElement;
