const logicData = {
    invoice: [
        // Calculates Net Sales Amount on the Line from the price and quantity on Invoice Line 
        {
            logicType: 'calcFields',
            operationType: 'sub.multiply',
            subEntity: 'lines',
            fieldsToOperate: ['netSalesPrice', 'itemQuantity'],
            targetField: 'netSalesAmount'
        },
        // Calculates totalNetSalesAmount by summing up the netSalesAmount of each Line
        {
            logicType: 'calcFields',
            operationType: 'header.sumLines',
            subEntity: 'lines',
            fieldsToOperate: 'netSalesAmount',
            targetField: 'totalNetSalesAmount'
        },
        // Calculates taxAmount on the line by multipling the taxRate and the SalesAmount on the line
        {
            logicType: 'calcFields',
            operationType: 'sub.multiply',
            subEntity: 'lines',
            fieldsToOperate: ['itemTaxRate', 'netSalesAmount'],
            targetField: 'taxAmount'
        },
        // calculates vatTaxes, by grouping by itemTaxId, vatTaxId.. creates vatTaxes group where
        // taxAmount is summed into vatTaxAmount & netSalesAmount is summed into vatTaxableAmount 
        {
            logicType: 'calcFields',
            operationType: 'sub.aggregate',
            entityToAggregate: 'lines',
            aggregateBy: ['itemTaxId', 'vatTaxId'],
            targetEntity: 'vatTaxes',
            sumFields: [['taxAmount', 'vatTaxAmount'], ['netSalesAmount', 'vatTaxableAmount']],
            transferFields: [['itemTaxRate', 'vatTaxRate']]
        },
        // calculates totalVatTaxAmount by summing vatTaxAmount on vatTaxes Array
        {
            logicType: 'calcFields',
            operationType: 'header.sumLines',
            subEntity: 'vatTaxes',
            fieldsToOperate: 'vatTaxAmount',
            targetField: 'totalVatTaxAmount'
        },
        // calculates totalGrossSalesAmount by summing up totalVatTaxAmount and totalNetSalesAmount
        {
            logicType: 'calcFields',
            operationType: 'header.sum',
            fieldsToOperate: ['totalVatTaxAmount', 'totalNetSalesAmount'],
            targetField: 'totalGrossSalesAmount'
        },
        // sets numPayments based on number of entries on the payments array
        {
            logicType: 'calcFields',
            operationType: 'condi.setValue',
            targetField: 'numPayments',
            conditionField: 'payments',
            "conditionValueMap": {
                "<=0": 0,
                ">0": "{count}"  // Use a placeholder for the count
            }
        },
        //This ensures that the total of payments is equal to totalGrossSalesAmount 
        {
            logicType: 'verify',
            operationType: 'sub.checkTotal',
            field: 'totalGrossSalesAmount',
            subEntity: 'payments',
            subEntityField: 'paymentAmount',
            fieldCount: 'numPayments'
        },
        // sets paymentSchedule value based on number of payments
        {
            logicType: 'calcFields',
            operationType: 'condi.setValue',
            targetField: 'paymentSchedule',
            conditionField: 'payments',
            "conditionValueMap": {
                "<=1": false,
                ">1": true
            }
        },
        // this resets the Payments array when the totalGrossSalesAmount changes
        // It calculates Installments if paymentSchedule is true
        {
            logicType: 'calcFields',
            operationType: 'sub.calcPayments',
            conditionField: 'totalGrossSalesAmount',
            subEntity: 'payments',
            transferMap: {
                'paymentDate': 'paymentDate',
                'totalGrossSalesAmount': 'paymentAmount',
            }
        },
        // This ensures that payments have a valid id 
        {
            logicType: 'verify',
            operationType: 'sub.checkField',
            subEntity: 'payments',
            subEntityField: 'paymentId',
        },
        //ensures that nSerie has the correct value
        {
            logicType: 'calcFields',
            sourceField: 'number',
            operationType: 'serie.calculate',
            targetField: 'nSerie'
        }
    ],
    creditNote: [
        // Calculates Net Sales Amount on the Line from the price and quantity on CreditNote Line 
        {
            logicType: 'calcFields',
            operationType: 'sub.multiply',
            subEntity: 'lines',
            fieldsToOperate: ['netSalesPrice', 'itemQuantity'],
            targetField: 'netSalesAmount'
        },
        // Calculates totalNetSalesAmount by summing up the netSalesAmount of each Line */
        {
            logicType: 'calcFields',
            operationType: 'header.sumLines',
            subEntity: 'lines',
            fieldsToOperate: 'netSalesAmount',
            targetField: 'totalNetSalesAmount'
        },
        // Calculates taxAmount on the line by multipling the taxRate and the SalesAmount on the line */
        {
            logicType: 'calcFields',
            operationType: 'sub.multiply',
            subEntity: 'lines',
            fieldsToOperate: ['itemTaxRate', 'netSalesAmount'],
            targetField: 'taxAmount'
        },
        // calculates vatTaxes, by grouping by itemTaxId, vatTaxId.. creates vatTaxes group where
        // taxAmount is summed into vatTaxAmount & netSalesAmount is summed into vatTaxableAmount 
        {
            logicType: 'calcFields',
            operationType: 'sub.aggregate',
            entityToAggregate: 'lines',
            aggregateBy: ['itemTaxId', 'vatTaxId'],
            targetEntity: 'vatTaxes',
            sumFields: [['taxAmount', 'vatTaxAmount'], ['netSalesAmount', 'vatTaxableAmount']],
            transferFields: [['itemTaxRate', 'vatTaxRate']]
        },
        // calculates totalVatTaxAmount by summing vatTaxAmount on vatTaxes Array  */
        {
            logicType: 'calcFields',
            operationType: 'header.sumLines',
            subEntity: 'vatTaxes',
            fieldsToOperate: 'vatTaxAmount',
            targetField: 'totalVatTaxAmount'
        },
        // calculates totalGrossAmount by summing up totalVatTaxAmount and totalNetSalesAmount   */
        {
            logicType: 'calcFields',
            operationType: 'header.sum',
            fieldsToOperate: ['totalVatTaxAmount', 'totalNetSalesAmount'],
            targetField: 'totalGrossSalesAmount'
        },
        //ensures that nSerie has the correct value
        {
            logicType: 'calcFields',
            sourceField: 'number',
            operationType: 'serie.calculate',
            targetField: 'nSerie'
        }
    ],
    stockAdjustment: [
        // Calculates totalProducts by summing up the quantity of each Line
        {
            logicType: 'calcFields',
            operationType: 'header.sumLines',
            subEntity: 'lines',
            fieldsToOperate: 'quantity',
            targetField: 'totalProducts'
        },
        //ensures that nSerie has the correct value
        {
            logicType: 'calcFields',
            sourceField: 'number',
            operationType: 'serie.calculate',
            targetField: 'nSerie'
        }
    ],
    stockMovement: [
        // Calculates totalProducts by summing up the quantity of each Line
        {
            logicType: 'calcFields',
            operationType: 'header.sumLines',
            subEntity: 'lines',
            fieldsToOperate: 'quantity',
            targetField: 'totalProducts'
        },
        //ensures that nSerie has the correct value
        {
            logicType: 'calcFields',
            sourceField: 'number',
            operationType: 'serie.calculate',
            targetField: 'nSerie'
        }
    ]
}

export default logicData