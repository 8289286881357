import { Wrapper } from "../generic/Layout"
import { Button } from "../generic/Core"
import useTranslation from "../../hooks/useTranslation"
import { useNavigate } from "react-router-dom"
import BackButton from "../generic/BackButton"

const ViewTitle = ({ config }) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const title = config.title
    const path = config.title.newPath

    function handleClick() {
        navigate(`${path}`);
    }

    return (
        <>
            <Wrapper>
                <h2>
                    {config.showNavigation && <BackButton config={{}} />}
                    {t(title.text)}
                </h2>
                {(title.createButton) && <Button $shadow={true} $variant='primary' onClick={handleClick}>{t(title.createButton)}</Button>}            </Wrapper>
        </>
    )
}

export default ViewTitle