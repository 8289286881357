function isFixedElementOffscreen(element) {
    if (!isHTMLElement(element)) { return false }
    const { position, left, width } = getComputedStyle(element);

    if (position === 'fixed') {
        const leftValue = parseInt(left, 10);
        const widthValue = parseInt(width, 10);
        return leftValue + widthValue <= 0;
    }

    return false;
}

function isElementHidden(element) {
    if (!isHTMLElement(element)) { return false }
    const styles = window.getComputedStyle(element);
    return styles.display === 'none';
}

function isElementInvisible(element) {
    if (!isHTMLElement(element)) { return false }
    const styles = window.getComputedStyle(element);
    return styles.visibility === 'hidden';
}

function isHTMLElement(obj) {
    return obj instanceof HTMLElement;
}

export default function isElementOnScreen(element) {
    if (isElementInvisible(element)) return false
    if (isElementHidden(element)) return false
    if (isFixedElementOffscreen(element)) return true
    return true
}