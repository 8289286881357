import ListView from '../../../components/listView/ListView'
import CardList from '../../../components/cardList/CardList'
import Page from '../../../components/pageView/Page'

const routesHome = [
    {
        path: "/home",
        element: CardList,
        props: {
            type: 'appList',
        }
    },
    {
        path: "/home/apps",
        element: CardList,
        props: {
            type: 'appList',
        }
    },
    {
        path: "/home/organization",
        element: ListView,
        props: {
            type: 'organizationList',
        }
    },
    {
        path: "/home/user",
        element: ListView,
        props: {
            type: 'userList',
        }
    },
    {
        path: "/home/organization/:id",
        element: Page,
        props: {
            type: 'organizationCard',
            options: {
                mode: 'edit',
                load: true
            }
        }
    },
    {
        path: "/home/organization/new",
        element: Page,
        props: {
            type: 'organizationCard',
            options: { mode: 'new' }
        }
    },
    {
        path: "/home/user/:id",
        element: Page,
        props: {
            type: 'userCard',
            options: { 
                mode: 'edit',
                load: true
             }
        }
    },
    {
        path: "/home/user/new",
        element: Page,
        props: {
            type: 'userCardNew',
            options: { mode: 'new' }
        }
    }
]

export default routesHome