import { useState, useEffect } from "react";

import DragNDrop from "./DragNDrop";
import ImageDisplay from "./ImageDisplay";
import ImageLoader from "./ImageLoader";

// Setup Use of API
import useApi from '../../hooks/useApi';
import { useQuery, useQueryClient } from 'react-query';


const Upload = ({ value, onChange, dataItems }) => {
    const queryClient = useQueryClient();
    const [files, setFiles] = useState([])

    //Setting up API 
    const { getMe, getMeStream, deleteMe, postMeForm } = useApi();


    useEffect((
    ) => {
        if (files.length > 0 && !value) {
            saveFile()
        }
    }, [files])


    const saveFile = async () => {
        const savedFile = await postMeForm('file', files[0], dataItems)
        //Save the new Reference on the Form
        onChange(
            { target: { name: 'invoiceLogo', value: savedFile.data.record.id } })
    }

    const handleReload = async () => {
        const record = await getMe(`${dataItems.endpoint}`)
        onChange({ target: { name: 'invoiceLogo', value: record.data.invoiceLogo } })
    }


    //Api Retrieval function to get list
    const getData = async (key) => {
        // If the fileId is '' then do not get the data
        if (!key.queryKey[1]) return;

        const fileId = key.queryKey[1];
        const resource = `file/${fileId}`;

        try {
            const file = await getMeStream(resource);
            console.log('FILE:', file)

            // Check if blob is not empty before updating the array
            if (file && file.size > 0) {
                return file;
            } else {
                // Handle the case when the blob is empty or null
                console.log('Received empty or null blob.');
                handleReload()
                queryClient.invalidateQueries(['salesSettings']);
                console.log('Value:', value)
                return null;
            }
        } catch (error) {
            // Handle the error from getMeStream
            console.error('Error getting data:', error);
            return null;
        }
    };


    //Request to get file from API 
    const result = useQuery(['files', value], getData, {
        onSuccess: (file) => {
            if (file !== undefined) setFiles((prevFiles) => [file])
            if (file === undefined) setFiles([])
        }
    })

    const handleDelete = async () => {
        if (value) await deleteMe('file', value)
        setFiles([])
        onChange(
            { target: { name: 'invoiceLogo', value: '' } })
    }




    if (result.isLoading) {
        return <span>Loading...</span>
    }

    if (result.isError) {
        return <span>Error: {result.error ? result.error.message : 'Internet Issues'}</span>
    }

    if (result.isSuccess) {

        return (
            <div>
                {!value && files.length <= 0 && <DragNDrop width='300px' onFilesSelected={setFiles} />}
                {value && files.length <= 0 && <ImageLoader />}
                {files.length > 0 && <ImageDisplay source={files[0]} onDelete={handleDelete} />}
            </div>
        )
    }
}

export default Upload