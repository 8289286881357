import * as BsIcons from 'react-icons/bs'

import styled from 'styled-components'

export const DownArrow = styled(BsIcons.BsArrowDown )`
    max-width: 1rem;
    display: inline;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0;
    &:hover{
        opacity: 80%;
        cursor: pointer;
    }
`

export const UpArrow = styled(BsIcons.BsArrowUp )`
    max-width: 1rem;
    display: inline;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    padding: 0;
    &:hover{
        opacity: 80%;
        cursor: pointer;
    }
`