import styled from 'styled-components'

export const Row = styled.div`
    --layout-gutter-x: 1.5rem;
    --layout-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--layout-gutter-y));
    margin-right: calc(-.5 * var(--layout-gutter-x));
    margin-left: calc(-.5 * var(--layout-gutter-x));

    & >* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--layout-gutter-x) * .5);
    padding-left: calc(var(--layout-gutter-x) * .5);
    margin-top: var(--layout-gutter-y);
    padding-block: ${({ $paddingBlock }) => $paddingBlock};
}
`
export const Container = styled.div`
    --layout-gutter-x: 1.5rem;
    --layout-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--layout-gutter-x) * .5);
    padding-left: calc(var(--layout-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
    margin-top: ${({ $marginTop }) => $marginTop};
    margin-bottom: ${({ $marginBottom }) => $marginBottom};    
`

// Component has a optional prop.grow that determines if its growth is 0
export const Col = styled.div`
    display: flex;
    padding: ${({ $padding }) => $padding}; 
    @media (min-width: 576px) {
    flex: ${({ $flex }) => $flex}; 
    }
`

export const Line = styled.div`
    --line-gutter-x: 1.5rem;
    --line-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--line-gutter-y));
    margin-right: calc(-.5 * var(--line-gutter-x));
    margin-left: calc(-.5 * var(--line-gutter-x));
`

export const Cell = styled.div`
    display: flex;
    padding: ${({ $padding }) => $padding}; 
    flex: ${({ $flex }) => $flex}; 
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
`;



export const FlexBox = styled.div`
  display: flex;
  flex-wrap: ${ ({$wrap}) => $wrap };;
  gap: ${ ({$gap}) => $gap };
`

export const TextLine = styled.p`
    margin-top: ${ ({$mt}) => $mt};
    margin-bottom: ${ ({$mb}) => $mb};
    font-size: var(--font-XS);
`