import { useEffect } from "react";
import { proxy, useSnapshot } from "valtio";

const vState = proxy({data:false})

const useFormValidation = () => {
    const snap = useSnapshot(vState)
    const validated = snap.data

    useEffect(() => {
        //      console.log('FDATA:', fData)
    }, [validated])

    const setValidated = (boolean) => {
        vState.data = boolean
    }


    return { validated, setValidated }


}

export default useFormValidation
