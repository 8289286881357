import { useState, useEffect } from "react";
import Alert from "./Alert";
import useTranslation from "../../hooks/useTranslation";
import { FlexBox } from "../generic/Layout";
import { ButtonLine } from "../generic/Core";
import SpinnerButton from "../generic/SpinnerButton";

function MessageBar({ title, message, type, setMessage, action, handleAction }) {
  const [show, setShow] = useState(true);
  const { t } = useTranslation()

  function processMessage(message) {
    if (typeof message === 'string') {
      return <p>{t(message)}</p>;
    } else if (Array.isArray(message)) {
      return (
        <>
          {message.map((msg, index) => (
            <p key={index}>{t(msg)}</p>
          ))}
        </>
      );
    } else {
      return null;
    }
  }

  const messages = processMessage(message)

  useEffect(() => {
    if (type === 'success') {
      const timer = setTimeout(() => {
        setShow(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Alert className={type} style={{ display: show ? "block" : "none" }}>
      <FlexBox>
        <Alert.Icon type={type} />
        {title && <Alert.Heading>{t(title)}</Alert.Heading>}
      </FlexBox>
      {messages}
      {type === 'error' && (<Alert.Close onClick={() => setMessage({ show: false })} />)}
      {action && <ButtonLine>
        <SpinnerButton onClickEvent={e => handleAction(action.formChanges)}>{action.message}</SpinnerButton>
      </ButtonLine>
      }
    </Alert>
  );
}

export default MessageBar;
