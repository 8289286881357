export const settingsData = {
    salesSettings:
    {
        "root": {
            "defaultPaymentTermId": "paymentTermId",
            "defaultPaymentTermName": "paymentTermName",
            "defaultPaymentTermDays": "paymentTermDays",
            "invoiceLanguage": "language",
            "invoiceLanguageName": "languageName",
            "invoiceCurrency": "currency",
            "invoiceCurrencyName": "currencyName"
        },
        "seller": [
            "companyName",
            "companyEmail",
            "companyAddress_street",
            "companyAddress_city",
            "companyAddress_postCode",
            "companyCountry",
            "companyCountryName",
            "invoiceLogo"
        ],
        "settings": [
            "nSeries"
        ]
    },
    inventorySettings:
    {
        "root": {
        },
        "settings": [
            "nSeries"
        ]
    }
}

