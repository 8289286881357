import transitionLogicData from "../model/logic/transitionLogicData.js"
import changeValues from '../../common/logic/transition/changeValues.js'
import deleteFields from '../../common/logic/transition/deleteFields.js'
import addFields from '../../common/logic/transition/addFields.js'
import reduceArray from '../../common/logic/transition/reduceArray.js'

const runTransitionLogic = (data, transitionType, addData = {}, prevData = {}) => {
    let processedData = { ...data }

    if (transitionLogicData[transitionType]) {
        const config = transitionLogicData[transitionType]

        for (const operation of config) {
            if (operation.operationType === 'changeValues') {
                processedData = changeValues(processedData, operation);
            } else if (operation.operationType === 'deleteFields') {
                processedData = deleteFields(processedData, operation);
            } else if (operation.operationType === 'addFields') {
                processedData = addFields(processedData, operation, addData);
            } else if (operation.operationType === 'reduceArray') {
                processedData = reduceArray(processedData, operation);  
            } else {
                console.log('Unknown operation type:', operation.operationType);
            }
        }
    } else {
        console.log('No transition logic found for entity:', transitionType);
    }
    return processedData
}

export default runTransitionLogic