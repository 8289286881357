import React, { useState } from 'react';
import styled from "styled-components";
import CountryControl from "./CountryControl";
import CountryControl_m from './CountryControl_m'
import addHeaders from "../../utils/addHeader";


// Setup Use of API
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';

//setup for language State
import { useSnapshot } from 'valtio';
import useTranslation from "../../hooks/useTranslation";
import useProfile from "../../hooks/useProfile"
import FlagsPng from "./FlagsPng" 

import generateSetValueObject, {resetSetValueObject} from "../select/generateSetValueObject";

const SearchBarContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;
`


const CountrySelect = React.memo(({
    valueName,
    valueId,
    valueMap,
    setValue,
    isRed
}) => {
    const [country, setCountry] = useState("");

    //Setting up API 
    const { getMe } = useApi();

    //Setting up translations 
    const {tState} = useTranslation()
    const snap = useSnapshot(tState)

    //Setting up the profile
    const {pState} = useProfile()
    const profile = useSnapshot(pState)
    const orgCountry = profile.currentOrgCountry

    //Setting up language Variables
    const language = snap.currentLang
    const placeholder = `country.placeholder`


    //Api Retrieval function to get list
    const getData = async (key) => {
        const orgCountry = key.queryKey[1]
        const resource = `country/?country=${orgCountry}`
        const response = await getMe(resource)
        const result = addHeaders(response.data)
        return result
    }

    //Return Data to Form
    const returnCountry = (country, valueMap) => {
        setCountry(country)       
        const result = generateSetValueObject(country, valueMap)
        console.log('COUNTRY:', country)
        console.log('RESULT:', result)
        setValue(result);
    }


    const handleBlur = (e) => {
        //If the field is not completed, it will reset it to no value
        if (e.target.value !== valueName) {
            const result = resetSetValueObject(valueMap)           
            setValue(result);
        }

    }


    //Request to get Countries from API 
    const result = useQuery(['countries', orgCountry], getData, { keepPreviousData: true })

    if (result.isLoading) {
        return <span>Loading...</span>
    }

    if (result.isError) {
        return <span>Error: {result.error? result.error.message : 'Internet Issues'}</span>
    }


    if (result.isSuccess) {
        return (
            <>
                <div>
                    <SearchBarContainer id='SearchBarContainer'>
                        <CountryControl
                            valueName={valueName}
                            valueId={valueId}
                            setValue={setValue}
                            data={result.data}
                            placeholder={placeholder}
                            onSelect={ (country) => returnCountry(country, valueMap)}
                            handleBlur={handleBlur}
                            isRed={isRed}
                            flags={FlagsPng}
                        />
                        <CountryControl_m
                            valueName={valueName}
                            valueId={valueId}
                            setValue={setValue}
                            data={result.data}
                            placeholder={placeholder}
                            onSelect={country => returnCountry(country, valueMap)}
                            isRed={isRed}
                            flags={FlagsPng}
                        />
                    </SearchBarContainer>
                </div>
            </>
        )
    }
});

export default CountrySelect;