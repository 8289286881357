import React from 'react';
import styled from "styled-components";
import CloseButton from "../generic/CloseButton";
import WidgetSwitcher from "./WidgetSwitcher";

const GridWidget = React.forwardRef(({ 
  style, className, onMouseDown, onMouseUp, onTouchEnd, children, 
  isDraggable, handleDelete, type, widget,
   ...props }, ref) => {
  return (
    <StyledDiv name='GridWidget' style={{ /* styles */ ...style }} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd} data-grid={widget}>
      {/* Some other content */}
      <WidgetSwitcher type={type}></WidgetSwitcher>
      {isDraggable && <CloseButton
                                className="deleteButton"
                                handleClick={() => handleDelete(widget.i)}
                                $size={'1rem'}
                                $padding={'0rem 0.75rem'}
                                $top={'5px'}
                            />}
      {children} {/* Make sure to include children to add resizable handle */}
    </StyledDiv>
  );
}
)

const StyledDiv = styled.div`
  background: ${ ({theme})=> theme.card.background };
  border-radius: 1rem;
  overflow: hidden;
  padding: 0.75rem;
  box-shadow: ${ ({theme})=> theme.card.boxShadow };
  

  p {
    font-size: var(--font-S);
  }
`

export default GridWidget