import styled from "styled-components";
import { Row, Cell } from '../generic/Layout'


const CountryName = styled.p`
    font-size: var(--font-XS);
    margin: 0;
    padding-top:0.5rem;
`

const HeaderGroup = styled.li`
    padding-left:3rem;
    pointer-events: none;
`

const ListGroupItem = styled.li`
    cursor: pointer;
    text-decoration: none;
    list-style: none;
    &.active, &:hover {
    background-color: ${({ theme }) => theme.dropdown.selectedBackground};
    border-color: #d3d5d7;
    color: ${({ theme }) => theme.dropdown.selectedFont};
    }   

    @media (max-width: 578px) {
        padding-block:5px;
    }
`

const FlagWrapper = styled.div`
    width: 3.5rem;
`

export const CountryFlag = styled.img`
    max-width: 2rem;
    padding-left:0.5rem;
    max-height: 1.25rem;
`

const CountryMenuItem = ({
    name,
    id,
    type,
    onSelectItem,
    isHighlighted,
    flag
}) => {
    return (
        <>
            {type ? <HeaderGroup id={id}>{name}</HeaderGroup> :
                <ListGroupItem
                    className={`list-group-item ${isHighlighted ? "active highlighted" : ""
                        }`}
                    onClick={onSelectItem}
                >
                    <Row $paddingBlock='0.25rem'>
                        <FlagWrapper >
                            {<CountryFlag src={flag} />}
                        </FlagWrapper>
                        <Cell $flex='1 0 0%' $padding='0'>
                            <CountryName>
                                {name} {type}
                            </CountryName>
                        </Cell>
                    </Row>
                </ListGroupItem>
            }
        </>

    );
};

export default CountryMenuItem;