// Hook to deal with Profile
import { sbState } from "../context/sidebarContext"

const useSidebar = () => {

    const toggleSidebar =  () => {
      sbState.visible = !sbState.visible
    }

    return {toggleSidebar, sbState};
}

export default useSidebar;