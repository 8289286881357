import styled from "styled-components"

const CloseWrapper = styled.button`
    position: absolute;
    top: ${(props) => props.$top ? props.$top : '0px'};
    right: ${(props) => props.$right ? props.$right : '0px'};
    z-index: 2;
    padding: ${(props) => props.$padding};
    color: ${({ theme }) => theme.font.color};;
    cursor: pointer;
    box-sizing: content-box;
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};
    background: transparent ;
    box-shadow: none;
    background-position: center;
    background-size: 1rem auto;
    background-repeat: no-repeat;
    border: none
`

const CloseButton = ({ handleClick, $size, $padding, $top, $right }) => {
    return (
        <CloseWrapper $top={$top} $right={$right} $size={$size || '2rem'} $padding={$padding || '1rem 1rem'} onClick={handleClick} onTouchStart={handleClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" >
                <path fill="currentColor" d="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z" />
            </svg>
        </CloseWrapper>
    )
}

export default CloseButton