import styled from "styled-components";
import { Button } from "./Core";

const WrapperButton = styled(Button)` 
    height: ${props => (props.$size ?  props.$size : '2rem')};
    width: ${props => (props.$size ?  props.$size : '2rem')};
    padding: 0px;
    box-shadow: ${ ({theme}) => theme.card.boxShadow };
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 800;
    border-radius: ${props => (props.$rounded ? '2rem' : '')};
    flex-shrink: 0;

&>svg{
    width: 60%;
    height: 60%;
}


`

const IconButton = ({ icon, $variant, clickAction, $rounded, $size, disabled }) => {
    return (
        <WrapperButton type="button" disabled={disabled?disabled:false} $variant={$variant} $rounded={$rounded} $size={$size} onClick={(clickAction)} >
            {icon}
        </WrapperButton>
    )

}

export default IconButton