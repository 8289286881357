import { useState, useEffect } from "react";

//The Hook takes a reference to the scrollable element, visibilit state pair, list options and an option startPos
const useScroll = (menuRef, isVisible, setIsVisible, options, startPos) => {
    const [cursor, setCursor] = useState(-1);
    const [previous, setPrevious] = useState(null)
    if (!startPos) {
        startPos = -1
    }

    //On Load Reset the cursor Position
    useEffect(
        () => {setCursor(startPos)} , [isVisible]
    )

    //Moving the cursor
    const scrollIntoView = position => {
        menuRef.current.scrollTo({
            top: position,
            behavior: "smooth"
        });
        if (previous) {
            previous.classList.remove('selected')
        }
        menuRef.current.children[cursor].classList.add('selected')
        setPrevious(menuRef.current.children[cursor])
    };

    const resetPos = () => {
        if(!isVisible) {setIsVisible(true)}
        setCursor(startPos)
    }

    //Monitors and manages cursor changes
    useEffect(() => {
        if (cursor < 0 || cursor > options.length || !menuRef) {
            return () => { };
        }
        if (menuRef.current) {
            let listItems = Array.from(menuRef.current.children);
            listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop);
        }
    }, [cursor]);

    const handleKeyNav = e => {
        if (e.key === "ArrowDown") {
            isVisible
                ? setCursor(c => (c < options.length - 1 ? c + 1 : c))
                : resetPos();
        }

        if (e.key === "ArrowUp") {
            setCursor(c => (c > 0 ? c - 1 : 0));
        }

        if (e.key === "Escape") {
            setIsVisible(false);
        }

        if (e.key === "Enter" && cursor >= 0) {
            menuRef.current.children[cursor].click()
            setIsVisible(false)
        }
    };

    return { handleKeyNav }

}

export default useScroll

