import styled from "styled-components"
import useTranslation from "../../hooks/useTranslation"
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"

import useSidebar from "../../hooks/useSidebar"
import { useSnapshot } from "valtio"

const MenuItem = styled.div`
    width: 4.5rem;
    height: 3rem;

    p {
        font-size: 0.65rem;
        padding-top: 0rem;
        margin-block-start: 0.25rem
}

`

const MenuIconWrapper = styled.div`
    width: 36px;
    height: 36px;
    padding-top: 0.5rem;
    margin: auto;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const MobileBarLink = styled(Link)`
    color: ${({ theme }) => theme.nav.font};
    list-style: none;
    text-decoration: none;
   &:hover {
        background: ${({ theme }) => theme.nav.active};
        color: ${({ theme }) => theme.nav.stripe};
        cursor: pointer;
    }
    &.active {
        background: ${({ theme }) => theme.nav.active};
        color: ${({ theme }) => theme.nav.stripe};
        cursor: pointer;
    }


    &.disabled {
        pointer-events: none;
    }

`

const MobileNavItem = ({ item }) => {
    const { t } = useTranslation()

    const location = useLocation()
    const isActivePath = (location.pathname === item.path);

    const {toggleSidebar, sbState} = useSidebar()
    const snap = useSnapshot(sbState)
    const sidebarVisibility = snap.visible

    const clickItem = () => {
        !item.path && toggleSidebar()
    }

    return (
        <MenuItem>
            <MobileBarLink to={item.path} onTouchStart={clickItem} className={isActivePath? 'active' : ''}>
                <MenuIconWrapper >
                    {item.icon}
                </MenuIconWrapper>
                <p>{t(item.title)}</p>
            </MobileBarLink>
        </MenuItem>

    )
}

export default MobileNavItem