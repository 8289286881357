import { useMediaQuery } from '@react-hook/media-query';

const useScreenSize = () => {

    const breakpoint=  {
        xs: '568px',
        s: '768px',
        m: '1024px',
        l: '1200px',
        xl: '1600px',
        xxl: '2000px'
    }

  const isMobile = useMediaQuery(`(max-width: ${breakpoint.xs})`);
  // Define other breakpoints here as needed

  const isSmallTablet = useMediaQuery(`(max-width: ${breakpoint.s})`);
  // Define other breakpoints here as needed

  const isTablet = useMediaQuery(`(max-width: ${breakpoint.m})`);
  // Define other breakpoints here as needed


  return {isMobile, isSmallTablet, isTablet};
};

export default useScreenSize;
