import { useState } from "react";
import { Button } from "../generic/Core";
import { PopUpModal } from "./PopUpModal";

import createFormContext from "../../context/formContext";

const { FormStateProvider: ModalProvider, useStore: useModalStore } = createFormContext({
    title: '',
    message: '',
});

export {useModalStore}

export function PopUp({ show, setShow, config, setAction, mode, data = {} }) {
    return (
        <ModalProvider>
            <PopUpModal show={show} setShow={setShow} config={config} setAction={setAction} mode={mode} data={data}/>
        </ModalProvider>
    );
}

export function PopUpButton({ config, mode }) {
    const [show, setShow] = useState(false);

    return (
        <>
            <Button name='popupbutton' $variant="secondary" onClick={() => setShow(true)}>
                Launch PopUp
            </Button>
            <PopUp
                show={show}
                setShow={setShow}
                config={config}
                mode={mode}
            />
        </>
    );
}
