import { useEffect, useState } from "react"

import PageLayout from "../PageLayout"
import Loader from "../../generic/Loader"

import runTransitionLogic from "../../../../../common/logic/runTransitionLogic.js"
import runLogic from "../../../../../common/logic/runLogic"

import { useStore } from "../Page"

//setup using the API
import { useQuery } from "react-query"
import useApi from "../../../hooks/useApi"

const TransitionLayer = ({ type, options, dispatch, config }) => {
    const [state, setState] = useStore((s) => s)
    const { getMe } = useApi()

    //manages when the components is ready to proceed.
    const [skip, setSkip] = useState(false)
  
    const createFrom = { entity: options.createFromEntity, id: options.createFromId }
    let result = {}

    //Load Entity and pass it along as data.
    if (options.createFromEntity) {
        const queryKey = `${options.createFromEntity}}/${options.createFromId}`
        result = useQuery(queryKey, () => getMe(`${options.createFromEntity}/${options.createFromId}`));
    }

    //On Load apply any transition you want to the loaded data :-) Amazing
    useEffect(() => {
        if (options.mode === 'new' && options.load ) {
            const transitionData = runTransitionLogic(state, config.transition.type, createFrom)
            const processedData = runLogic(transitionData, transitionData.entity) 
            setState(processedData, { replace: true })
            if (options.createFromEntity === undefined) setSkip(true)
        }
    }, [])

    useEffect(
        () => {
            if (result.data) {
                const transitionData = runTransitionLogic(state, config.transition.type, result.data.data)
                const processedData = runLogic(transitionData, transitionData.entity)
                console.log('[TransitionLayer] Processed Data:', processedData)
                setState(processedData)
            }

        }, [result.isSuccess]
    )


    if (result.isLoading) { return <Loader /> }

    if (result.isSuccess || skip) {
        //console.log('re-rendering PageLayout')

        return (
            <>
                <PageLayout type={type} options={options} dispatch={dispatch} />
            </>
        )
    }


}

export default TransitionLayer