import EntryForm from '../../pages/entry/EntryForm'

const entryRoutes = [
    {
        path: "/login",
        element: EntryForm,
        props:{title:'Login to your application', purpose:'login', cta:'Login'},        
    },
    {
        path: "/register",
        element: EntryForm,
        props:{title:'Register your account', purpose:'register', cta:'Sign Up'},        
    },
];

export default entryRoutes;