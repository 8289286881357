import ListView from "../../../components/listView/ListView";
import Page from "../../../components/pageView/Page";
import WidgetPage from "../../../components/widgetPage/WidgetPage";
import StepView from "../../../components/stepView/StepView";

const routesSales = [
  {
    path: "/sales",
    element: WidgetPage,
    props: {
      type: "sales",
      draggable: false,
      resizable: false,
    },
  },
  {
    path: "/sales/contact",
    element: ListView,
    props: {
      type: "contactList",
    },
  },
  {
    path: "/sales/paymentTerm",
    element: ListView,
    props: {
      type: "paymentTermList",
    },
  },
  {
    path: "/sales/paymentTerm/:id",
    element: Page,
    props: {
      type: "paymentTermPage",
      options: {
        mode: "edit",
        load: true,
        collapse: false,
      },
    },
  },
  {
    path: "/sales/paymentTerm/new",
    element: Page,
    props: {
      type: "paymentTermPage",
      options: { mode: "new", load: false },
    },
  },
  {
    path: "/sales/contact/:id",
    element: Page,
    props: {
      type: "contactMultiPage",
      options: {
        mode: "edit", 
        load: true,
        collapse: true,
      },
    },
  },
  {
    path: "/sales/contact/new",
    element: Page,
    props: {
      type: "contactPage",
      options: { mode: "new", load: false },
    },
  },
  {
    path: "/sales/contact/:id/interaction/:id2",
    element: Page,
    props: {
      type: "interactionPage",
      options: { mode: "edit", load: true },
    },
  },
  {
    path: "/sales/contact/:id/interaction/new",
    element: Page,
    props: {
      type: "interactionPage",
      options: { mode: "new", load: false },
    },
  },
  {
    path: "/sales/contact/:id/contactPerson/:id2",
    element: Page,
    props: {
      type: "contactPersonPage",
      options: { mode: "edit", load: true },
    },
  },
  {
    path: "/sales/contact/:id/contactPerson/new",
    element: Page,
    props: {
      type: "contactPersonPage",
      options: { mode: "new", load: false },
    },
  },
  {
    path: "/sales/product",
    element: ListView,
    props: {
      type: "productList",
    },
  },
  {
    path: "/sales/product/:id",
    element: Page,
    props: {
      type: "productPage",
      options: { mode: "edit", load: true },
    },
  },
  {
    path: "/sales/product/new",
    element: Page,
    props: {
      type: "productPage",
      options: { mode: "new", load: false },
    },
  },
  {
    path: "/sales/invoice",
    element: ListView,
    props: {
      type: "invoiceList",
    },
  },
  {
    path: "/sales/invoice/:id",
    element: Page,
    props: {
      type: "invoicePage",
      options: {
        mode: "edit",
        load: true,
        collapse: true,
        resizeable: true,
      },
    },
  },
  {
    path: "/sales/invoice/new",
    element: Page,
    props: {
      type: "invoicePage",
      options: {
        mode: "new",
        load: false,
        collapse: true,
        resizeable: true,
      },
    },
  },
  {
    path: "/sales/contact/:id/invoice/new",
    element: Page,
    props: {
      type: "invoiceFromContactPage",
      options: { 
        mode: "new", 
        load: false,
        collapse: true,
        resizeable: true,
        createFromEntity: 'contact'
       },
    },
  },
  {
    path: "/sales/creditNote",
    element: ListView,
    props: {
      type: "creditNoteList",
    },
  },
  {
    path: "/sales/creditNote/:id",
    element: Page,
    props: {
      type: "creditNotePage",
      options: {
        mode: "edit",
        load: true,
        collapse: true,
        resizeable: true,
      },
    },
  },
  {
    path: "/sales/creditNote/newFromInvoice/:id",
    element: Page,
    props: {
      type: "creditNoteNewPage",
      options: {
        mode: "new",
        load: true,
        collapse: true,
        resizeable: true,
      },
    },
  },
  {
    path: "/sales/creditNote/new",
    element: StepView,
    props: {
      type: "creditNoteWizard"
    },
  },
  {
    path: "/sales/creditNote/new/:id",
    element: StepView,
    props: {
      type: "creditNoteWizard",
      options: {
        step: 1
      },
    },
  },
  {
    path: "/sales/settings",
    element: Page,
    props: {
      type: "salesSettingsPage",
      options: {
        mode: "edit", 
        load: true,
        collapse: true
      },
    },
  },
];

export default routesSales;
