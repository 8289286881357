import { useState, useEffect } from "react"
import { Separator } from "../generic/Core"

import styled from "styled-components"
import { processLineDefaults, convertObjectToMessage } from "../../utils/defaults/processDefaults"

import CoreForm from "./CoreForm"
import Collapse from "../generic/Collapse"
import Display from '../display/Display'

//Get current Country from Profile and use Valtio to read a snapshot
import useProfile from "../../hooks/useProfile";
import { snapshot } from "valtio";

//Run Business Logic
import runLogic from '../../../../common/logic/runLogic'

const ItemWrapper = styled.div`
    margin-inline: -1rem;
    margin-top: -1rem;

    border-top-right-radius: ${(props) => props.$idx === 0 ? '0.90rem' : ''};
    border-top-left-radius: ${(props) => props.$idx === 0 ? '0.90rem' : ''};

  &:hover{
    background-color: ${({ theme }) => theme.table.hover};
  }  
`

const DisplayWrapper = styled.div`
    margin-inline: 1rem;
    padding-top: 1rem;

`

const LineWrapper = styled.div`
    position: relative;
`



const LineElement = ({ idx, section, dispatch, validated, entity, useStore, linesArray = 'lines' }) => {
    const [active, setActive] = useState(false)
    const [state, setState] = useStore(s => s)
    const prevData = { ...state }

    const lineId = idx
    //IMPORTANT NEW STATE
    const currency = state.currency || ''

    //Getting the State
    const { pState } = useProfile()
    const profile = snapshot(pState)

    const toggleActive = () => {
        setActive(!active)
    }

    // if it is empty is opened
    useEffect(
        () => {
            if (state[linesArray] && Object.keys(state[linesArray][idx]).length === 0) {
                setActive(true)
                //This code adds the defaults to a line object
                const defaults = processLineDefaults(section, profile)
                let line = { ...state[linesArray][idx], ...defaults }
                let lines = [...state[linesArray]]
                lines.splice(idx, 1, line)
                //It then updates all lines
                setState({ [linesArray]: lines })
            }
        }, []

    )

    const toggleSection = () => { }

    const localDispatch = async (action, localFormData) => {
        if (action === 'close') {
            setActive(false)
            setState({ savedOn: new Date() })
        } else if (action === 'save') {
            const processedData = await runLogic(state, state.entity, prevData)
            setState({ ...processedData, savedOn: new Date() })
            setActive(false)
        } else if (action === 'delete') {
            setActive(false)
            dispatch(action, localFormData, idx)
        } else {
            dispatch(action, localFormData, idx)
        }
    }



    // console.log(data)
    return (
        <LineWrapper>
            <Collapse name="Collapse-Button" expanded={active} toggleExpanded={toggleActive}
                top='0.275rem' right='-0.575rem'
            ></Collapse>
            <ItemWrapper name='LineFormDisplay' $idx={idx}
            >
                <DisplayWrapper>
                    {
                        !active &&
                        <>
                            <Display
                                section={section} currency={currency}
                                clickAction={toggleActive} type='lines' idx={idx}>
                            </Display>
                            
                            <Separator></Separator>
                        </>
                    }
                </DisplayWrapper>
            </ItemWrapper>
            <>
                {
                    active && <>
                        <CoreForm
                            section={section}
                            lineId={lineId}
                            dispatch={localDispatch}
                            validated={validated}
                            toggleSection={toggleSection}
                            expanded={true}
                            entity={entity}
                            useStore={useStore}
                        />
                        <Separator></Separator>
                    </>

                }
            </>
        </LineWrapper>)
}

export default LineElement