import axios from "../api/axios"
import useAuth from './useAuth'


function useRefreshToken() {
    const { Auth, setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.post(
            '/token/refresh',
            { token: Auth.refreshToken },
            { headers: { 'Content-Type': 'application/json' } }
        )

        setAuth(prev => {
            return {
                ...prev,
                accessToken: response.data.accessToken
            }
        })
        return response.data.accessToken
    }
    return refresh;
}

export default useRefreshToken