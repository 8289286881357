import styled, { keyframes } from "styled-components";

const SpinnerAnimation = keyframes`
 to { transform: rotate(360deg); }
`

export const CenterBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ height }) => height ? height : '50vh'};;
`

const Circle = styled.div`
    --spinner-width: ${({ size }) => size ? size : '2rem'};
    --spinner-height: ${({ size }) => size ? size : '2rem'};
    --spinner-vertical-align: -0.25em;
    --spinner-border-width: 0.25em;
    --spinner-animation-speed: 0.75s;
    color: ${({ theme }) => theme.font};
    border: var(--spinner-border-width) solid currentcolor;
    border-right-color: transparent;
    display: inline-block;
    width: var(--spinner-width);
    height: var(--spinner-height);
    vertical-align: var(--spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--spinner-animation-speed) linear infinite var(--spinner-animation-name);
    animation: var(--spinner-animation-speed) linear infinite ${SpinnerAnimation};
    background: transparent;
`

const CircleSpan = styled.span`
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
`

export const Spinner = ({ size }) => {
    return (
        <Circle size={size}>
            <CircleSpan></CircleSpan>
        </Circle>
    )
}


const Loader = ({ height, size }) => {
    return (
        <CenterBox height={height}>
            <Circle size={size}><CircleSpan></CircleSpan></Circle>
        </CenterBox>

    )
}

export default Loader