import * as BsIcons from 'react-icons/bs'

import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'

const Icon = styled(BsIcons.BsArrowLeftSquareFill )`
    max-width: 1.75rem;
    display: inline;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    padding: 0;
    &:hover{
        opacity: 80%;
        cursor: pointer;
    }
`

const BackButton = ({config}) => {

    const navigate = useNavigate()

    return (
            <Icon onClick={(e) => { 
                e.preventDefault()
                config.backOverride? navigate(config.backOverride): navigate(-1)
            
            
            }}/>        
    )
}

export default BackButton