/**
 * Removes elements from an array based on the screen width and dropOrder object.
 * @param {Array} arr - The original array.
 * @param {Object} dropOrder - The object specifying indices to remove for each screen width range.
 * @param {number} screenWidth - The current screen width.
 * @returns {Array} - The new array with the specified elements removed.
 */
function removeElementsByKey(arr, dropOrder, screenWidth) {
    // Check if dropOrder is empty
    if (Object.keys(dropOrder).length === 0) {
      return arr; // If dropOrder is empty, return the original array
    }
  
    // Find the appropriate drop order based on the screen width
    let indicesToRemove = [];
  
    for (const [width, indices] of Object.entries(dropOrder)) {
      if (screenWidth <= width) {
        indicesToRemove = indices;
        break;
      }
    }
  
    // If no matching screen width is found, set a default case
    if (indicesToRemove.length === 0 && dropOrder.default) {
      indicesToRemove = dropOrder.default;
    }
  
    // Create a copy of the array to avoid mutating the original array
    let newArr = [...arr];
  
    // Sort indices in descending order
    indicesToRemove.sort((a, b) => b - a);
  
    // Remove elements at the specified indices
    indicesToRemove.forEach(index => {
      if (index >= 0 && index < newArr.length) {
        newArr.splice(index, 1);
      }
    });
  
    return newArr;
  }
  
  

  export default removeElementsByKey