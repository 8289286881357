function addHeaders(arr, withHeaders) {
  const headers = [];
  const firstLetters = new Set();
  if (!arr) arr = []
  const copyArr = [...arr]; // make a copy of the original array

//It only adds Headers if withHeaders is true
  if (withHeaders && copyArr.length > 0) {
    for (let i = 0; i < copyArr.length; i++) {
      const obj = copyArr[i];
      const firstLetter = obj.name.charAt(0).toUpperCase();

      if (!firstLetters.has(firstLetter)) {
        firstLetters.add(firstLetter);
        const header = { name: firstLetter, header: true, id:`H-${firstLetter}`};
        headers.push(header);
        const idx = i;
        if (i > 0 && firstLetter === copyArr[idx - 1].name.charAt(0).toUpperCase()) {
          copyArr.splice(idx, 1);
          copyArr.splice(idx - 1, 0, header, obj);
        } else {
          copyArr.splice(idx, 0, header);
        }
      }
    }

  }
  
  const result = { list: copyArr, headers: headers };
  //console.log('RESULT:', result)

  return result
}
 

  export default addHeaders