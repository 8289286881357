import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useLocale from '../../hooks/useLocale'
import usePath from '../../hooks/usePath'

import * as BsIcons from 'react-icons/bs'

//Importing subcomponent for ensuring the correct display of the data
import DisplayElement from '../display/DisplayElement'


const TableList = styled.ol`
    padding: 0px;
    margin: 0px;
    list-style: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: ${({ theme }) => theme.table.background};
    @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: none;
    }
`

const TableListItem = styled(Link)`
    display: flex;
    flex: 1 0 auto;
    min-width: 0px;
    opacity: 1;
    min-height: 56px;
    position: relative;
    border-top: 1px solid ${({ theme }) => theme.table.borderTop};
    -webkit-box-align: center;
    align-items: center;
    color: ${({ theme }) => theme.font.color}; //Doesn't apply
    cursor: pointer;
    padding: 16px 24px;
    text-decoration: none;
    &:hover{
        background-color: ${({ theme }) => theme.table.active};
    }
`

const TableCell = styled.div`
    box-sizing: border-box;
    flex: 100 0 auto;
    min-width: 0px;
    width: ${(props) => (props.$len)} ;
    display: flex;
    margin-right: 24px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-shrink: 0 !important;
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important; 
    p {
        margin:0;
        font-size: var(--font-XS);
        line-height: 24px;
        display: block;
        color: ${({ theme }) => theme.font.color};
        font-family: "Euclid Circular B", sans-serif;
        font-weight: 500;
        text-align: inherit;
        user-select: auto;
        transition-property: color;
        transition-duration: 0.1s;
        transition-timing-function: ease-out;
        text-decoration: none;
    }

    svg{
        margin-right: 0.5rem;
    }
`

const TableDecorator = styled.span`
      font-size: var(--font-XXS);
      margin-left: 0.25rem;  
`

const NoData = (
    <TableList>
        <TableListItem>
            <TableCell>
                <BsIcons.BsExclamationOctagonFill />
                <p>No data available.</p>
            </TableCell>
        </TableListItem>
    </TableList>
)

const TableRows = ({ result, columns, slug = '', tableKey = 'id', path = '' }) => {
    const { lf } = useLocale()
    // format the path by merging the endpoint to the current url
    const { url } = usePath(path)

    let slugPath = ''
    if (slug !== '') slugPath = `/?state=${slug}`

    if (!result.data || result.data.pages.length === 0 || !Array.isArray(result.data.pages[0]?.data)) {
        // Handle the case when result data is empty
        return NoData;
    }

    return (
        <TableList>
            {result.data.pages.map((page, pageIndex) => (
                <div key={pageIndex}>
                    {
                        Array.isArray(page.data) && page.data.map(
                            (item, i) => {
                                return (
                                    <TableListItem key={i} to={`${url}${item[tableKey]}${slugPath}`} >
                                        {
                                            columns.map(
                                                (col, i) => {
                                                    return (
                                                        <TableCell key={i} $len={col.length}>
                                                            <p><DisplayElement string={item[col.id]} type={col.type} col={col} item={item}/></p>
                                                            {col.decorator ? <TableDecorator>{item[col.decorator]}</TableDecorator> : <></>}
                                                        </TableCell>
                                                    )
                                                }
                                            )
                                        }
                                    </TableListItem>
                                )
                            }
                        )
                    }
                </div>)
            )}


        </TableList>
    )
}

export default TableRows