import styled from "styled-components"
import { Text, Title } from "../generic/Core"
import useTranslation from "../../hooks/useTranslation";
import useLocale from "../../hooks/useLocale";
import DisplayElement from "./DisplayElement";
import { Tag } from "../generic/Core";

const DisplayElementWrapper = styled.div`   
`

const IconWrapper = styled.span`
`

const DisplayIcon = styled.span`
margin-right: 0.5rem;
`

const Currency = styled.span`
    font-size: 0.8em;
`

function getValuesFromKeys(object, keys) {
    const values = [];
    if (Array.isArray(keys)) {
        for (const key of keys) {
            if (object.hasOwnProperty(key)) {
                if (typeof (object[key]) === 'number') {
                    values.push(object[key].toString())
                } else {
                    values.push(object[key]);

                }
            }
        }

    }

    return values;
}

const MergedText = ({ displayData, element }) => {
    const { m } = useTranslation()
    let params = []
    if (element) { params = getValuesFromKeys(displayData, element.mergeParams) }

    return (
        element && <span>{m(element.value, params)}</span>
    )

}

const DisplayElementField = ({ displayData, element, currency }) => {
    const { t } = useTranslation()
    const { lf } = useLocale()
    return (
        <>
            <IconWrapper>
                {element.icon && <DisplayIcon>{element.icon}</DisplayIcon>}
            </IconWrapper>
            <span>{t(element.prefix)}</span>
            {element.type === 'currency' && <Currency>{currency}</Currency>}
            {element.type === 'field' && <span>{displayData[element.value]}</span>}
            {element.type === 'text' && <span>{t(element.value)}</span>}
            {element.type === 'amount' && <span>{lf(displayData[element.value], 2, 2)}</span>}
            {element.type === 'merge' && <MergedText displayData={displayData} element={element} />}
            {element.type === 'date' && <DisplayElement type='date' string={displayData[element.value]} />}
            {element.type === 'niceDate' && <DisplayElement type='niceDate' string={displayData[element.value]} />}
        </>
    )
}

const DisplayElementType = ({ displayData, element, $variant, currency }) => {
    return (
        <DisplayElementWrapper>
            {(element.size === 'title') && <Title $variant={$variant}>
                <DisplayElementField displayData={displayData} element={element} currency={currency} />
                {element.tag && <span><Tag $variant='primary'>{JSON.stringify(displayData[element.tag])}</Tag></span> }
            </Title>}
            {(element.size === 'regular') && <Text $variant={$variant}>
                <DisplayElementField displayData={displayData} element={element} currency={currency}/>
            </Text>}
        </DisplayElementWrapper>




    )


}

export default DisplayElementType