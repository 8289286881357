import React, { useState } from 'react';
import styled from 'styled-components';
import { stepViewData } from '../../model/components/stepViewData';
import { Button } from '../generic/Core';

import StepComponent from './StepComponent';
import useScreenSize from '../../hooks/useScreenSize';

const StepForm = styled.div`
    max-width: ${({ $width }) => $width ? $width : ''};
`

const StepFormHeader = styled.header`
    text-align: center;
    margin-bottom: ${props => props.$isMobile ? '1rem' : '2rem'};
`

const StepIndicator = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
`

const StepDescription = styled.div`
    margin: 0.25rem 2rem 0 0;
    color: ${({ $highlighted, theme }) => $highlighted ? theme.font.active : theme.font.color};
`

const MobileStepDescription = styled.h2`
    margin: 0 0 1rem 0;
`


const StepIndicatorContent = styled.span`
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background-color: lightgray;
    margin: 0 5px;
    display: inline-block;
    text-align: center;

    &.active {
        background-color: #007bff;
        color: white;
    }
`

const StepFormFooter = styled.footer`
    display: flex;
    justify-content: space-between;
`

const StepFormMain = styled.main`
    min-height: ${({ $height }) => $height ? $height : ''};

`


const StepView = ({ type, options }) => {
    const startStep = options?.step || 0
    const [currentStepIndex, setCurrentStepIndex] = useState(startStep);
    const { isMobile } = useScreenSize()

    //Config to read the data from the Model
    let config = stepViewData[type]

    const { title, description, steps, width, contentHeight, showPreviousNext } = config;

    const currentStep = steps[currentStepIndex];

    const goToNextStep = () => {
        if (currentStepIndex < steps.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStepIndex > 0) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    };

    return (
        <StepForm $width={width}>
            <StepFormHeader $isMobile={isMobile}>
                {!isMobile && <h1>{title}</h1>}
                {false && <p>{description}</p>}
                <StepIndicator className="step-indicator">
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <StepIndicatorContent className={index === currentStepIndex ? 'active' : ''}>
                                {index + 1}
                            </StepIndicatorContent>
                            {!isMobile && <StepDescription $highlighted={index === currentStepIndex}>{step.title}</StepDescription>}
                        </React.Fragment>
                    ))}
                </StepIndicator>
                {isMobile && <MobileStepDescription>{currentStep.title}</MobileStepDescription>}
                {false && <p>{currentStep.description}</p>}
            </StepFormHeader>

            <StepFormMain $height={contentHeight}>
                {<StepComponent component={currentStep.component} />}
            </StepFormMain>

            {showPreviousNext && <StepFormFooter>
                <Button onClick={goToPreviousStep} disabled={currentStepIndex === 0} $variant={currentStepIndex === 0 ? '' : 'primary'}>
                    Previous
                </Button>
                <Button onClick={goToNextStep} disabled={currentStepIndex === steps.length - 1} $variant={currentStepIndex === steps.length - 1 ? '' : 'primary'}>
                    Next
                </Button>
            </StepFormFooter>}
        </StepForm>
    );
};

export default StepView;
