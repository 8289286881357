import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { widgetDashboardData } from "../../model/components/widgetDashboardData";
import WidgetGrid from "./WidgetGrid";
import { Button } from "../generic/Core";
import MobileNavBar from "../mobileNav/MobileNavBar";
import * as GoIcons from 'react-icons/go'
import IconButton from "../generic/IconButton";

import useTranslation from "../../hooks/useTranslation";

const WidgetBox = styled.div` 
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 0px
`

const GridBox = styled.div` 
    display: block;
    margin: 0px 0px 0px 0px
`

const WidgetHeader = styled.div`
    display: flex;
    justify-content: space-between;


    .title {
    display:flex;  
    padding: 0 0 0.5rem 0;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
`
const WidgetActions = styled.div`
  
`

const MissingConfig = () => {
return  <h2>This WidgetPage is not configured. Add it to the model.</h2>
}

const WidgetPage = ({ draggable, resizable, type }) => {
  const [showReset, setShowReset] = useState(false)

  const initialData = widgetDashboardData[type]
  const { t } = useTranslation()

  const gridRef = useRef()
  const onResetLayout = () => {
    gridRef.current.resetLayout()
  };

  const onToggleEdit = () => {
    gridRef.current.toggleEdit()
    setShowReset(!showReset)
  }

  useEffect(() => {
  }, [])

  if (initialData === undefined) return <MissingConfig />

  return (
    <WidgetBox>
      <WidgetHeader>
        <h2 className="title">{t(initialData.metadata.title)}</h2>
        <WidgetActions>
          <IconButton icon={<GoIcons.GoGear/>} $variant='primary' clickAction={onToggleEdit}/>
        </WidgetActions>
      </WidgetHeader>
      <div>
      {showReset && <Button onClick={onResetLayout}>{t(initialData.metadata.resetButton)}</Button>}
      </div>
      <GridBox id='GridBox'>
        <WidgetGrid
          type={type}
          ref={gridRef}
          resizable={resizable}
          draggable={draggable}
          initialData={initialData}
        >
        </WidgetGrid>
      </GridBox>
      <MobileNavBar />
    </WidgetBox>
  );
};

export default WidgetPage;
