import styled from "styled-components"
import useLocale from "../../hooks/useLocale"
import useTranslation from "../../hooks/useTranslation"
import logger from "../../../../server/src/utils/logger/logger.js"

const Line = styled.div`
    margin-block:0.25rem;
    display: flex;
    justify-content: space-between;
`

const Currency = styled.span`
    margin-block: 0rem;
    font-size: 0.6em;
`

const Label = styled.div`
    text-align: left;
    margin-right:5rem;    
`

const Value = styled.div`
    text-align: end;   
`

const H = styled.h3`
    margin-block:0rem;
`
const P = styled.p`
    margin-block:0rem;
    font-size: var(--font-XS);
`


function getValues(data, params) {
    const result = [];
    const { lf } = useLocale()

    for (const param of params) {
        if (Array.isArray(param)) {
            // If param is an array with two values, multiply the value from data by the second value.
            const [key, multiplier] = param;
            if (data[key] !== undefined) {
                result.push(data[key] * multiplier);
            }
        } else {
            // If param is a single key, get the value from data.
            if (data[param] !== undefined) {
                result.push(lf(data[param], 2, 2));
            }
        }
    }

    return result;
}


export const DisplayTotalsLine = ({ size, label, value = 0, params, line, currency }) => {
    //logger.info('DisplayTotalsLine', label, value)
    const { m } = useTranslation()
    let pLabel = label
    if (params) {
        const parameters = getValues(line, params)
        pLabel = m(label, parameters)
    }


    const { lf } = useLocale()

    return (
        <>
            {(size === 'regular') && <Line >
                <Label><P>{pLabel}</P></Label>
                <Value><P>{lf(value, 2, 2)}<Currency>{` ${currency}`}</Currency></P></Value>
            </Line>}
            {(size === 'large') && <Line >
                <Label><H>{label}</H></Label>
                <Value><H>{lf(value, 2, 2)}<Currency>{` ${currency}`} </Currency></H></Value>
            </Line>}
        </>
    )
}


const DisplayTotalsSubLines = ({ field, data }) => {
    //This set VatLines to False by default
    let lines = false;
    if (data[field.subEntity]) {
        lines = data[field.subEntity]
    }

    return (
        <>
            {lines && lines.map(
                (line, i) => {
                    return (
                        <DisplayTotalsLine
                            key={i}
                            label={field.label}
                            size={field.size}
                            value={line[field.value]}
                            params={field.labelParams}
                            line={line}
                            currency={data.currency}
                        />
                    )
                }
            )
            }
        </>

    )
}

export default DisplayTotalsSubLines