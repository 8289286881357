import styled from "styled-components";
import addHeaders from "../../utils/addHeader";
import ConnectControl from "./ConnectControl";

// Setup Use of API
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';

//This allows to return the data read from the select entities
import generateSetValueObject from "./generateSetValueObject";

//Setting up the Wrapper
const SelectContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    position: relative;
`

//Select takes an entity like 'unit' a value like {name:'meter',id:'1'} a setter and options object.
const Connect = ({ 
    entity, 
    valueName, 
    valueMap, 
    setValue, 
    options, 
    isRed, 
    modalTitle, 
    placeholder, 
    menuItemField = 'name', 
    modalType='searchList', 
    radioGroups = [], 
    subObject,
    persistValue // Added persistValue at root level
}) => {
    if (!options) { options = {} }

    //Setting up API 
    const { getMe } = useApi();

    //Api Retrieval function to get list for the Entity
    const getData = async (key) => {
        let result
        const resource = `${entity}`
        const response = await getMe(resource)
        if (response.data === undefined) {
            result = addHeaders([{ name: 'no results found' }], false)
        } else {
            //This adds the headers if options.withHeaders is true
            result = addHeaders(response.data, options.withHeaders)
        }

        return result
    }

    //Return Data to Form
    const returnData = (data, valueMap) => {
        const result = generateSetValueObject(data, valueMap);
        setValue(result);
    }

    //Request to get Countries from API 
    const result = useQuery([`${entity}`], getData, { keepPreviousData: true })

    if (result.isLoading) {
        return <span>Loading...</span>
    }

    if (result.isError) {
        return <span>Error: {result.error ? result.error.message : 'Internet Issues'}</span>
    }

    if (result.isSuccess) {
        return (
            <>
                <div>
                    <SelectContainer id='SearchBarContainer'>
                        <ConnectControl
                            valueName={valueName}
                            setValue={setValue}
                            data={result.data}
                            placeholder={placeholder}
                            onSelect={selection => returnData(selection, valueMap)}
                            entity={entity}
                            modalTitle={modalTitle}
                            options={options}
                            isRed={isRed}
                            $allwaysOn={true}
                            menuItemField={menuItemField}
                            modalType={modalType}
                            radioGroups={radioGroups}
                            persistValue={persistValue} // Pass persistValue to ConnectControl
                        />
                    </SelectContainer>
                </div>
            </>
        )
    }
};

export default Connect;
