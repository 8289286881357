

import { createContext, useContext, useReducer, useEffect } from 'react';

// Initial state
const initialState = {
    show: false,
    modalConfig: {},
};

// Reducer function
const modalReducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_MODAL':
            return {
                ...state,
                show: true,
            };
        case 'HIDE_MODAL':
            return {
                ...state,
                show: false,
            };
        case 'SET_MODAL_CONFIG':
            return {
                ...state,
                modalConfig: action.payload,
            };
        default:
            return state;
    }
};

// Create the context
const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(modalReducer, initialState);

    useEffect(() => {
        //console.log('Modal State Updated:', state);
    }, [state]);

    const setShowModal = (show) => {
        if (show) {
            dispatch({ type: 'SHOW_MODAL' });
        } else {
            dispatch({ type: 'HIDE_MODAL' });
        }
    };

    const setModalConfig = (config) => {
        dispatch({ type: 'SET_MODAL_CONFIG', payload: config });
    };

    const value = {
        showModal: state.show, 
        setShowModal, 
        modalConfig: state.modalConfig, 
        setModalConfig,
    };

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    );
};

// Default export of the custom hook
const useFormModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useFormModal must be used within a ModalProvider");
    }
    return context;
};

export default useFormModal;
