import styled from "styled-components"

//API Interface
import useApi from "../../../hooks/useApi"
import { useQuery } from "react-query"
import Loader from "../../generic/Loader"

//Translations
import useTranslation from "../../../hooks/useTranslation"


//Links
import { Link } from "react-router-dom"
import { Button } from '../../generic/Core'
import { Wrapper } from "../../generic/Layout"

const ShowAll = styled(Button)`
    margin-right: 1.5rem;
`

const CardContent = styled.div`
    width: 100%;

    a:hover {
        text-decoration: underline;
    }
` 

const ListWidget = ({ type, data, labels }) => {

    const { getMe } = useApi()

    const getData = async () => {
        const resource = `${data.entity}`
        const response = await getMe(resource)
        if (response?.data) {return response.data } else return []
    }

    const result = useQuery([data.entity], getData, { keepPreviousData: true })


    const {t} = useTranslation()

    if (result.isLoading) {
        return <Loader />
    }

    if (result.isError) {
        return <span>Error: Couldn't load!</span>
    }

    if (result.isSuccess) {
        
        if (!Array.isArray(result.data)) {
            return <span>Error: Couldn't load!</span>
        }

        return (
            <CardContent>
                <Wrapper>
                    <h3>{t(labels.title)}</h3>
                    <ShowAll $variant='transparent'><Link to={data.path}>{t('widget.show.all')}</Link></ShowAll>
                </Wrapper>
                <ul>
                    {result && result?.data && result.data.map(
                        (e, i) => {
                            return (
                                <li key={e['id']}><Link to={`${data.path}/${e['id']}`}>{e[data.field]}</Link></li>)
                        }
                    )}

                </ul>
                {result.data.length === 0 ? 
                
                <>
                
                <p>{t(labels.emptyDescription)}</p>
                <Button $variant='primary'><Link to={`${data.path}/new`}>{t(labels.emptyAction)}</Link></Button>
                </>
                
                
                : <></>}
            </CardContent>
        )
    }
}

export default ListWidget

