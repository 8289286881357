import styled from "styled-components"
import IconButton from "../generic/IconButton"
import { Icon_Delete } from "../../styles/icons"

export const DisplayWrapper = styled.div`
    display: flex;
    gap: 1rem;
`

export const ImageWrapper = styled.div`
    width: 150px;
    height: 150px;
    border: 2px dashed ;
    border-color: ${({ theme }) => theme.btn.primary};
    background-color: white;
    border-radius: 1rem;
    display: flex;
  align-items: center;
  justify-content: center;

    img {
        max-width:100%;
        max-height: 100%;
        border-radius: 1rem;
        padding: 0.25rem;
    }
`


const ImageDisplay = ({ source, onDelete }) => {
    let imageUrl
    
    if (source instanceof File) {
        // Create a URL for the File object
        imageUrl = URL.createObjectURL(source);
    }

    return (
        <DisplayWrapper>
            <ImageWrapper>
                <img src={imageUrl} />
            </ImageWrapper>
            <IconButton $variant='secondary' icon={Icon_Delete} clickAction={onDelete}/>
        </DisplayWrapper>
    )
}

export default ImageDisplay