import ListView from '../../../components/listView/ListView'
import WidgetPage from '../../../components/widgetPage/WidgetPage'
import Page from '../../../components/pageView/Page'

const routesCrm = [
    {
        path: "/crm",
        element: WidgetPage,
        props: {
            type: 'crm',
            draggable: false,
            resizable: false
        }
    },
    {
        path: "/crm/customer",
        element: ListView,
        props: {
            type: 'customerList',
        }
    },
    {
        path: "/crm/contact",
        element: ListView,
        props: {
            type: 'contactList',
        }
    },
    {
        path: "/crm/contact/:id/interaction/:id2",
        element: Page,
        props: {
            type: "interactionPage",
            options: {
                mode: "edit",
                load: true
            },
        },
    },
    {
        path: "/crm/contact/:id/interaction/new",
        element: Page,
        props: {
            type: "interactionPage",
            options: {
                mode: "new",
                load: false
            },
        },
    },
    {
        path: "/crm/contact/:id/contactPerson/:id2",
        element: Page,
        props: {
            type: "contactPersonPage",
            options: {
                mode: "edit",
                load: true
            },
        },
    },
    {
        path: "/crm/contact/:id/contactPerson/new",
        element: Page,
        props: {
            type: "contactPersonPage",
            options: {
                mode: "new",
                load: false
            },
        },
    },
    {
        path: "/crm/customer/:id",
        element: Page,
        props: {
            type: 'customerCard',
            options: { mode: 'edit' }
        }
    },
    {
        path: "/crm/contact/:id",
        element: Page,
        props: {
            type: "contactMultiPage",
            options: {
                mode: "edit",
                load: true,
                collapse: true,
            },
        },
    },
    {
        path: "/crm/customer/new",
        element: Page,
        props: {
            type: 'customerCard',
            options: { mode: 'new' }
        }
    },
    {
        path: "/crm/contact/new",
        element: Page,
        props: {
            type: 'contactCard',
            options: { mode: 'new' }
        }
    },
    {
        path: "/crm/product",
        element: ListView,
        props: {
            type: 'productList',
        }
    },
    {
        path: "/crm/product/:id",
        element: Page,
        props: {
            type: "productPage",
            options: {
                mode: "edit",
                load: true
            },
        },
    },
    {
        path: "/crm/product/new",
        element: Page,
        props: {
            type: "productPage",
            options: {
                mode: "new",
                load: false
            },
        },
    }
]

export default routesCrm