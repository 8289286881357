// Import Operations
import calcFields from './calculated/calcFields.js';
import verifyLogic from './verify/verifyLogic.js';

// Import Configuration
import logicData from '../model/logic/logicData.js';

const runLogic = (data, entity, prevData = {}) => {
    let processedData = { ...data }
    if (logicData[entity]) {
        const config = logicData[entity]
        for (const operation of config) {
            if (operation.logicType === 'calcFields') {
                processedData = calcFields(processedData, operation, prevData)
            }

            if (operation.logicType === 'verify') {
                processedData = verifyLogic(processedData, operation)
            }
        }
    }
    return processedData
}

export default runLogic