import { keyframes } from "styled-components";

export const SlideUp = keyframes`
        0% {
        transform: translateY(50px); /* Start 50px below its position */
        opacity: 1; /* Optional: start with 0 opacity */
        }
        100% {
        transform: translateY(0); /* End at its position */
        opacity: 1; /* Optional: end with 1 opacity */
        } 
`