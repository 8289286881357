import React, { useRef } from 'react';
import styled from "styled-components";
import { TextControl } from "../generic/Core";

const InputField = ({
  value,
  placeholder,
  isReadOnly,
  handleArrowClick,
  onInputChange,
  onInputBlur,
  onFocus,
  isRed,
  inputMode,
  inputRef
}) => {
  // Function to handle key press
  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      handleArrowClick(1);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      handleArrowClick(-1);
    } 
  };

  const handleFocus = (e) => {
    onFocus()
    inputRef.current.select();
  };


  return (
    <TextControl
      type="text"
      inputMode={inputMode}
      value={value}
      placeholder={placeholder}
      onChange={onInputChange}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={onInputBlur}
      ref={inputRef}
      {...(isRed && { required: true })}
    />
  );
};

export default InputField;
