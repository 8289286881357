export function getFromLS(id, key) {
    try {
      // Retrieve the data associated with the given id
      const data = JSON.parse(localStorage.getItem(id));
  
      // If a key is specified, return the corresponding value
      if (key) {
        return data[key];
      } else {
        // If no key is specified, return the entire object
        return data;
      }
    } catch (error) {
      // Handle any errors that might occur (e.g., if data is not found)
      return null; // Return null to indicate failure or absence of data
    }
  }

// Function to save a key-value pair under an "id" in local storage
export function saveToLS(id, key, value) {
    try {
      // Retrieve the existing data associated with the "id" or initialize an empty object
      const existingData = JSON.parse(localStorage.getItem(id)) || {};
      
      // Update the existing data with the new key-value pair
      existingData[key] = value;
  
      // Save the updated data back to local storage
      localStorage.setItem(id, JSON.stringify(existingData));
  
      // Return true to indicate success
      return true;
    } catch (error) {
      // Handle any errors that might occur (e.g., if storage is full)
      console.error('Error saving to local storage:', error);
      return false; // Return false to indicate failure
    }
  }
  