import { snapshot } from 'valtio';
import useProfile from './useProfile';

const useLocale = () => {
  const { pState } = useProfile()
  const profile = snapshot(pState)
  const currentOrgLang = profile.currentOrgLanguage

  const decimalSeparator = (1.1).toLocaleString(currentOrgLang).substring(1, 2);

  function lf(value, min = 0, max = 20) {

    if (typeof value !== 'number' || isNaN(value)) {
      return value;
    }

    return value.toLocaleString(profile.currentOrgLanguage, {
      minimumFractionDigits: min || 0,
      maximumFractionDigits: max || 20// set a high maximum to avoid rounding
    })
  }

  const ld = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString(profile.currentOrgLanguage);
  };




  return { lf, ld, currentOrgLang, decimalSeparator }

}


export default useLocale