function getNestedValue(obj, key) {
    return key.split('/').reduce((acc, part) => acc && acc[part], obj);
}

const changeValues = (data, operation) => {

    // Loop through each field in the rootFields array
    for (const field of operation.fields) {
        const { targetField, fromValue, toValue } = field;
        // Retrieve Nested Values
        if (typeof toValue === 'string' && toValue.includes('/')) {
            data[targetField] = getNestedValue(data, toValue)
        } else 
        // Retrieve Today's Date
        if (typeof toValue === 'string' && toValue.includes('{today}')) {
            let now = new Date()
            data[targetField] = now
        } else 
        
        {

            // Check if the target field exists in data and if its value matches fromValue
            if (data[targetField] === fromValue) {
                // If it matches, change the value to toValue
                data[targetField] = toValue;
            }

            if (!fromValue) {
                //If the toValue is an actual value of the Data passed then use that
                if (data[toValue]) {
                    data[targetField] = data[toValue]
                    //Otherwise just fill in the value of the thing
                } else {
                    data[targetField] = toValue
                }

            }

        }

    }

    return data

}

export default changeValues