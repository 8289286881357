import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styled from "styled-components";

import GridLayout from "./GridLayout";
import { getFromLS, saveToLS } from "../../utils/localStore/localStore";

const WidgetWrapper = styled.div` 
    margin: 0px 0px 0px -${({ offset }) => offset}px;
`

const WidgetGrid = forwardRef(({ draggable, resizable, initialData }, ref) => {
  const id = initialData.name

  // Calculates the OffSet for the Grid to align it to the Title based on the Margin used
  const margins = initialData.setup.margin
  const keys = Object.keys(margins)
  const offset = `${margins[keys[0]][0]}`

  //Loads the Saved Data from Local Store
  let savedData = getFromLS(id, 'data');
  if (!savedData) {
    savedData = initialData;
  }
  const [layouts, setLayouts] = useState(JSON.parse(JSON.stringify(savedData.layouts)));
  const [widgetArray, setWidgetArray] = useState(JSON.parse(JSON.stringify(savedData.widgets)));
  const [isDraggable, setIsDraggable] = useState(draggable);
  const [isResizable, setIsResizable] = useState(resizable);
  const [forceUpdate, setForceUpdate] = useState(false);

  useImperativeHandle(ref, () => ({
    resetLayout() {
      setLayouts(initialData.layouts);
      setWidgetArray(initialData.widgets);
    },
    toggleEdit() {
      setIsDraggable(!isDraggable);
      setIsResizable(!isResizable);
    }
  }));

  const onLayoutChange = (layout, layouts) => {
    let data = {
      layouts: layouts,
      widgets: widgetArray
    };
    saveToLS(id, 'data', data);
    setLayouts(layouts);
  };

  const handleDelete = (key) => {
    const tempArray = widgetArray.slice();
    const index = tempArray.indexOf(tempArray.find((data) => data.i === key));
    tempArray.splice(index, 1);
    const newLayouts = updateLayouts(key);
    setLayouts(newLayouts);
    setWidgetArray(tempArray);
    setForceUpdate(!forceUpdate);
  };

  const updateLayouts = (key) => {
    const updatedLayouts = {};
    for (const breakpoint in layouts) {
      updatedLayouts[breakpoint] = layouts[breakpoint].filter((item) => item.i !== key);
    }
    return updatedLayouts;
  };

  return (
    <WidgetWrapper offset={offset}>
      <GridLayout
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        widgetArray={widgetArray}
        isResizable={isResizable}
        isDraggable={isDraggable}
        isShaking={false}
        handleDelete={handleDelete}
        setup={initialData.setup}
        key={forceUpdate}
      /> 
    </WidgetWrapper>
  );
});

export default WidgetGrid