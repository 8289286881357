import styled from "styled-components";

import ViewTable from "../listView/ViewTable";
import Page from "../pageView/Page";

const ComponentWrapper = styled.div`
    height: 400px;
    overflow: scroll;
    padding: 0.25rem;
`


const PersonalInfoForm = () => (
    <form>
        <label>
            Name:
            <input type="text" name="name" />
        </label>
        <label>
            Email:
            <input type="email" name="email" />
        </label>
    </form>
);

const PreferencesForm = () => (<p>List of all Invoices</p>)
const ReviewForm = () => (<p>Review</p>)

const ListView = ({ table, config, endpoint, filter }) => {
    return (

        <ComponentWrapper>
            <ViewTable
                table={table}
                config={config}
                endpoint={endpoint}
                filter={filter}
            />
        </ComponentWrapper>
    )
}


const PageView = ({ pageType, options }) => {
    return (
        <Page
            type={pageType}
            options={options}
        />
    )
}





// Mapping of component names to actual components
const componentMap = {
    PersonalInfoForm,
    PreferencesForm,
    ReviewForm,
    ListView,
    PageView
};


const StepComponent = ({ component }) => {
    const Component2Diplay = componentMap[component.type]

    return (
            <Component2Diplay {...component} />
    )

}

export default StepComponent