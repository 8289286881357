// Utility function to check conditions
function checkCondition(value, condition) {
  const operator = condition.match(/[<>=]+/)[0];
  const conditionValue = parseFloat(condition.replace(/[<>=]+/, ''));

  switch (operator) {
    case '<':
      return value < conditionValue;
    case '>':
      return value > conditionValue;
    case '=':
      return value === conditionValue;
    case '<=':
      return value <= conditionValue;
    case '>=':
      return value >= conditionValue;
    default:
      throw new Error(`Unsupported operator: ${operator}`);
  }
}

// Function to apply rules
function applyRules( data, rules) {
  const conditionField = rules.conditionField;
  const conditions = rules.conditionValueMap;

  let valueToCheck = data[conditionField];

  //Check if valueToCheck is Undefined and assign the value of 1
  if (valueToCheck === undefined) valueToCheck = 1


  // Check if the valueToCheck is an array and get its length if it is
  if (Array.isArray(valueToCheck)) {
    valueToCheck = valueToCheck.length;
  }

  for (const condition in conditions) {
    if (checkCondition(valueToCheck, condition)) {
      let result = conditions[condition];
      // Replace placeholder with the actual count if necessary
      if (typeof result === 'string' && result.includes('{count}')) {
        result = parseFloat(valueToCheck)
      }

      return result;
    }
  }

  return null; // If no condition matches
}


function condiSetValue(result, operation) {

  //Decoupling the data
  const data = {...result}

  //applying the rules
  const value = applyRules(data, operation);

  // Applying the result for the field
  data[operation.targetField] = value

  return data

}

export default condiSetValue