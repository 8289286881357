const appLogo = <svg
    width="24"
    height="24"
    viewBox="0 0 67.733333 67.733333"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
>
    <defs
        id="defs2" /><g
            id="layer1"><g
                id="g1095"
                transform="matrix(0.11275167,0,0,0.15687898,-4.8990446,-5.815442)"><rect
                style={{fill:'transparent', strokeWidth:10.5605}}
                id="rect289"
                width="599.69141"
                height="431.82297"
                x="44.57283"
                y="38.087524"
            />
            <g
                id="g1098"
                transform="matrix(3.3241091,0,0,3.4394945,301.9972,-425.1857)"
                style={{ fill: 'inherit' }}>
                <path
                    style={{ fill: 'currentColor', strokeWidth: 0.721515 }}
                    d="m 45.395948,234.91354 h 6.59182 l 21.475444,-69.6016 c 0,0 1.275003,-3.53785 0.246523,-5.32327 -1.075176,-1.86648 -5.053713,-1.80224 -5.053713,-1.80224 l -8.841529,0.0459 c 0,0 -4.860192,0.0614 -6.888848,1.21054 -1.992917,1.12894 -2.567209,4.06778 -2.567209,4.06778 l -17.345202,54.16904 z"
                    id="path412"
                />
                <path
                    style={{ fill: 'currentColor', strokeWidth: 0.721515 }}
                    d="m -16.694618,169.8456 c 0,0 -10.993391,0.23425 -18.978377,0.19608 -2.516921,-0.012 -12.185884,0.36022 -12.184234,-6.18648 8.49e-4,-3.36723 4.046215,-5.27948 7.591301,-5.35867 11.786883,-0.2633 31.9763787,-0.38953 31.9763787,-0.38953 0,0 4.87212,-0.14108 7.192818,0.88372 3.0079814,1.32826 5.6026946,5.86443 5.6026946,5.86443 l 47.5420477,70.38134 -28.258628,0.52981 c 0,0 -2.75642,-6.44073 -6.985154,-9.07403 -5.405109,-3.36585 -19.5235908,-6.74815 -25.1713365,-9.9015 -4.0879355,-2.28245 -4.8226435,-3.90423 -2.6402545,-7.27789 2.4085517,-3.72328 16.0360058,-3.21515 16.0360058,-3.21515 0,0 -5.32984813,-8.03601 -7.1266834,-13.77731 -1.7968357,-5.74131 -8.8291674,-6.02307 -8.8291674,-6.02307 0,0 -9.726527,0.11439 -16.73225,0.3979 -4.324938,-0.12912 -6.023167,-1.53566 -6.028346,-4.38033 -0.452645,-3.43688 4.919036,-4.05572 8.597169,-4.05853 6.749863,-0.005 10.406877,-0.16116 10.406877,-0.16116 0,0 4.034064,0.2019 4.477496,-4.09124 0.4195428,-4.06185 -6.488357,-4.35839 -6.488357,-4.35839 z"
                    id="path468"
                />
            </g>
        </g>
    </g>
</svg>

export default appLogo