import styled from "styled-components";
import { Button } from "../generic/Core";
import { CheckMark, ExclamationMark, InfoMark, IconWrapper } from "./AlertIcons";

let Alert = styled.div`
    --alert-bg: transparent;
    --alert-padding-x: 1rem;
    --alert-padding-y: 1rem;
    --alert-margin-bottom: 1rem;
    --alert-color: inherit;
    --alert-border-color: transparent;
    --alert-border: 1px solid var(--alert-border-color);
    --alert-border-radius: 0.375rem;
    position: relative;
    padding: var(--alert-padding-y) var(--alert-padding-x);
    margin-bottom: var(--alert-margin-bottom);
    color: var(--alert-color);
    background-color: var(--alert-bg);
    border: var(--alert-border);
    border-radius: var(--alert-border-radius,0); 

    //Animation
    transition: opacity .15s linear;

    &>p{
        margin:0;
        font-size: var(--font-S)
    }

    &.success {
        --alert-color: #0f5132;
        --alert-bg: #d1e7dd;
        --alert-border-color: #badbcc;
    }

    &.warning {
        --alert-color: #783305;
        --alert-bg: #FEEBC8;
        --alert-border-color: #f5ddb0;
    }

    &.info {
        --alert-color: #0d457a;
        --alert-bg: #bee3f8;
        --alert-border-color: #a9daf6;
    }

    &.error{
        --alert-color: #842029;
        --alert-bg: #f8d7da;
        --alert-border-color: #f5c2c7;
    }
`

const AlertHeading = styled.h4`
    font-size: var(--font-M);
    color: inherit;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    display: grid;
    flex-grow: 1;
`

const AlertButton = styled(Button)`
&.success{
    color: #198754;
    border: 1px solid #198754;
    background: transparent;
    &:hover{
        color: #fff;
        background: #198754
    }
    --btn-hover-border-color: #198754;
    --btn-focus-shadow-rgb: 25,135,84;
    --btn-active-color: #fff;
    --btn-active-bg: #198754;
    --btn-active-border-color: #198754;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #198754;
    --btn-disabled-bg: transparent;
    --gradient: none;
    
}
`

export const AlertClose = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem;
    cursor: pointer;
    box-sizing: content-box;
    width: 1rem;
    height: 1rem;
    color: ${({ theme }) => theme.font.color};
    background-color: transparent;
    background-position: center;
    background-size: 1rem auto;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
`
const AlertDivider = styled.hr``

export const AlertIcon = ({ type }) => {
    return (
        <IconWrapper>
            {
                type === 'success' ?
                    // Checkmark
                    CheckMark :
                    type === 'error' || type === 'warning' ?
                        // Exclamation Mark
                        ExclamationMark :
                        // Info Mark
                        InfoMark
            }
        </IconWrapper>
    )

}


Alert.Heading = AlertHeading
Alert.Close = AlertClose
Alert.Divider = AlertDivider
Alert.Button = AlertButton
Alert.Icon = AlertIcon

export default Alert