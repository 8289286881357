const headerSetValue = (data, operation) => {
    const { fieldToSet, valueToSet } = operation

    if (data) {
            data[fieldToSet] = valueToSet
        }

    return data
}

export default headerSetValue