import { useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import GridWidget from './GridWidget';

import styled, { keyframes, css } from 'styled-components';

const shakeAnimation = keyframes`
 0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-2deg);
  }
`;



//Declaring the Responsive Grid
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Grid = styled(ResponsiveReactGridLayout)`
  position: relative;
  transition: height 200ms ease;
  margin: 0 0px;

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url();
  background-position: bottom right;
  padding: 0px 3px 3px 0;
}

.react-grid-item > .react-resizable-handle {
  opacity: 100%;
  position: absolute;
  width: 40px;
  height: 40px;
}
.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 10px;
  height: 10px;
  border-right: 2px solid ${({ theme }) => theme.font.color};
  border-bottom: 2px solid ${({ theme }) => theme.font.color};
}
`

const GridLayout = ({
  layouts,
  onLayoutChange,
  widgetArray,
  isResizable,
  isDraggable,
  isShaking,
  handleDelete,
  setup }) => {

    const [isNowDraggable, setIsNowDraggable] = useState(false);

    //This re-renders the component allowing the X to be triggered.
    const onDragStart = (layout, oldItem, newItem, placeholder, e, element) => {
      setIsNowDraggable(!isNowDraggable);  // Enable dragging when the drag starts
    };
  
    const onDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
      setIsNowDraggable(!isNowDraggable);  // Disable dragging when the drag stops
    };

  return (
    <div>

      <Grid
        className="layout"
        breakpoints={setup.breakpoints}
        cols={setup.cols}
        margin={setup.margin}
        rowHeight={setup.rowHeight}
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        isDraggable={isDraggable}
        isResizable={isResizable}
        onDragStop={onDragStop}
        onDragStart={onDragStart}
        useCSSTransforms={true}
      >
        {widgetArray?.map((widget, index) => {
          return (
              <GridWidget
                type={widget.type}
                isShaking={isShaking}               
                isDraggable={isDraggable}
                handleDelete={handleDelete}
                widget={widget}
                key={widget.i}
              />
          );
        })}
        <div><p>Hello</p></div>
      </Grid>
    </div>
  );
};

export default GridLayout;
