import { useEffect } from "react";
//Importing sub-components
import styled from "styled-components";
import SelectControl from "./SelectControl";
import SelectControl_m from './SelectControl_m'
import addHeaders from "../../utils/addHeader";
import IconButton from "../generic/IconButton";
import { Icon_Edit } from "../../styles/icons";

// Setup Use of API
import useApi from '../../hooks/useApi';
import { useQuery } from 'react-query';

import { useNavigate } from "react-router-dom";

//setup for language State & profile
import { useSnapshot } from 'valtio';
import useTranslation from "../../hooks/useTranslation";
import useProfile from "../../hooks/useProfile";

import generateSetValueObject, { resetSetValueObject } from "./generateSetValueObject";

//Setting up the Wrapper
const SelectContainer = styled.div`
    width: 100%;
    margin: 0;
    position: relative;
`

const RowWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`



//Select takes an entity like 'unit' a value like {name:'meter',id:'1'} a setter and options object.
const Select = ({ entity, valueName, valueId, valueMap, setValue, options, isRed }) => {

    if (!options) { options = {} }

    //Setting up API 
    const { getMe } = useApi();
    const navigate = useNavigate();

    //Setting up translations 
    const { tState } = useTranslation()
    const snap = useSnapshot(tState)

    //Setting up the profile
    const { pState } = useProfile()
    const profile = useSnapshot(pState)
    const orgCountry = profile.currentOrgCountry

    //Setting up language Variables
    const language = snap.currentLang
    const placeholder = `${entity}.placeholder`
    const modalTitle = `${entity}.modal.title`


    const openList = () => {
        console.log('navigating to: ', entity)
        navigate(options.list)
    }

    //Api Retrieval function to get list for the Entity
    const getData = async (key) => {
        try {
            const orgCountry = key.queryKey[1]
            const resource = `${entity}/?country=${orgCountry}`
            const response = await getMe(resource)

            //Checks if the endpoint returns a meta.default
            let defaultValue = response?.meta?.default || ''
            if (!defaultValue) defaultValue = response.data[0]

            const list = addHeaders(response.data, options.withHeaders)

            //Adds the default value to the data info 
            return { list: list, defaultValue: defaultValue }

        } catch (e) {
            return { list: [], defaultValue: {} }
        }
    }

    //Return Data to Form
    const returnData = (data, valueMap) => {
        const result = generateSetValueObject(data, valueMap);
        setValue(result);
    }

    //Request to get Select Data from API 
    const result = useQuery([`${entity}`, orgCountry], getData, { keepPreviousData: true })

    // If the field is empty process the defaultValue
    // it checks if the entity is set as well as the value is empty
    useEffect(() => {
        if (result.isSuccess && valueName === '' && entity) {
            console.log(`09 Running select on ${entity}`);
            const newValue = generateSetValueObject(result.data.defaultValue, valueMap);
            setValue(newValue);
        }
    }, [result.isSuccess, valueName, entity, result.data, valueMap, setValue]); // Dependency array

    if (result.isLoading) {
        return <span>Loading...</span>
    }

    if (result.isError) {
        return <span>Error: {result.error ? result.error.message : 'Internet Issues'}</span>
    }

    if (result.isSuccess) {

        return (
            <>
                <RowWrapper name="SelectWrapper">
                    <SelectContainer name='SelectContainer'>
                        <SelectControl
                            valueName={valueName}
                            valueId={valueId}
                            setValue={setValue}
                            data={result.data.list}
                            defaultValue={result.data.defaultValue}
                            placeholder={placeholder}
                            onSelect={selection => returnData(selection, valueMap)}
                            entity={entity}
                            options={options}
                            isRed={isRed}
                        />
                        <SelectControl_m
                            valueName={valueName}
                            valueId={valueId}
                            setValue={setValue}
                            data={result.data.list}
                            defaultValue={result.data.defaultValue}
                            placeholder={placeholder}
                            onSelect={selection => returnData(selection, valueMap)}
                            entity={entity}
                            modalTitle={modalTitle}
                            options={options}
                            isRed={isRed}
                        />

                    </SelectContainer>
                    {options.edit && <IconButton clickAction={openList} icon={Icon_Edit} />}
                </RowWrapper>
            </>
        )
    }
};

export default Select;