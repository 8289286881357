import { useEffect } from 'react'
import { registerSW } from 'virtual:pwa-register'
import useProfile from './useProfile'

const usePwaUpdate = () => {
    const { pState } = useProfile()

    useEffect(() => {
        const updateSW = registerSW({
            immediate: true,
            onRegisteredSW(swUrl, r) {
                // Log successful registration in development
                if (import.meta.env.DEV) {
                    console.log('SW registered: ', swUrl)
                }
            },
            onNeedRefresh() {
                // When a new version is available, set newVersion to true
                pState.newVersion = true
            },
            onOfflineReady() {
                // App is ready for offline use
                console.log('App is ready for offline use')
            },
            onRegisterError(error) {
                console.error('SW registration error', error)
            }
        })

        // Cleanup registration on unmount
        return () => {
            updateSW && updateSW()
        }
    }, [])

    return null
}

export default usePwaUpdate
