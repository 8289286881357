import {sum} from './core.js'

const headerSumLines = (data, operation) => {
    const { fieldsToOperate, targetField, subEntity } = operation
    let result = 0

    if (data[subEntity] && Array.isArray(data[subEntity])) {
        for (const elem of data[subEntity]) {
            result += sum(elem, fieldsToOperate)
            //Rounding the amount to 2 decimals and saving it
            data[targetField] = parseFloat(result.toFixed(2));
        }
    }
    return data
}

export default headerSumLines