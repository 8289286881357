/*
This function checks if the path starts with '/'
If it does then it recalculates the URL from the current URL
Extracting the first Slug (e.g.: sales)
And rebuilding the path from there.
Otherwise it just returns the path. 
*/


const usePath = (path) => {

    function formatPath(path, firstSlug) {      

        if (path[0] === '/') {
            return '/'+ firstSlug + path;
        } else {
            return path;
        }
    }

    const currentUrl = window.location.pathname;
    // Remove the leading slash and split the path by slashes
    const parts = currentUrl.replace(/^\//, '').split('/');
    const firstSlug = parts[0];
    
    const url = formatPath(path, firstSlug)

    return {url}
}

export default usePath