function verifyStringValue(array, arrayField, id) {

    // Iterate through the array
    for (let i = 0; i < array.length; i++) {
            // Check if the arrayField contains the condition or if the length of the field is less than 5
            if (array[i][arrayField] === undefined || (array[i][arrayField].length < 5)) {
                // If either condition is true, update the arrayField with a new value based on the id and index
                array[i][arrayField] = `${id}.${i + 1}`
        }
    }
    // Return the modified array
    return array;
}

const subCheckField = (data, operation) => {

    if (!data[operation.subEntity]) {
        return data
    }

    // Extract the condition, subEntity, and subEntityField from the operation object
    const array = [...data[operation.subEntity]]
    const arrayField = operation.subEntityField

    // Call the verifyStringValue function to verify the arrayField exists and has the right value
    const verifiedArray = verifyStringValue( array, arrayField, data.id)

    // Update the subEntity in the data object with the verified array
    data[operation.subEntity] = verifiedArray

    // Return the modified data object
    return data
}

export default subCheckField
