import styled from "styled-components";

const ButtonWrapper = styled.div`
  position:absolute;
  top: 4px;
  right: 1.25rem;
  width: 2.5rem;
  height: 1rem;
  font-size: 1rem;
  &>div {
    user-select: none;
    display: inline;
    padding: 0.1rem;
    width:1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    color: ${ ({theme}) => theme.font.color};
    &:hover {
      cursor: pointer;
    }
  }
`

function UpDownArrows({ onIncrement, onDecrement }) {
  return (
    <ButtonWrapper>
      <div  onClick={onIncrement}>▲</div>
      <div  onClick={onDecrement}>▼</div>
    </ButtonWrapper>
  );
}

export default UpDownArrows;
