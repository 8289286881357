function sumFields(list, field) {
    // Check if the list is an array and field is a string
    if (!Array.isArray(list) || typeof field !== 'string') {
      console.log('Invalid input: list must be an array and field must be a string');
      return 0
    }
  
    // Use the reduce method to sum the values of the specified field
    return list.reduce((accumulator, currentObject) => {
      // Check if the current object has the specified field and the field value is a number
      if (currentObject.hasOwnProperty(field) && typeof currentObject[field] === 'number') {
        return accumulator + currentObject[field];
      } else {
        return accumulator;
      }
    }, 0); // Initialize the accumulator to 0
  }
  
export default sumFields
  