

import ListView from '../../components/listView/ListView'

import { Icon_Invoice, Icon_Interaction, Icon_People, Icon_Hall } from '../../styles/icons';

export const tabViewData = {
    contactTabView: {
        cardViewType: 'contactCard',
        width: '1024px',
        condition: {tab: 'contactPersons', field: 'companyTypeId', value: 'company'}, 
        tabs: [
            {
                id: 'contactPeopleTab',
                key: 'contactPersons',
                label: 'contact.tab.people',
                content: <ListView type='contactPersonList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_People,
                disabled: false,
            },
            {
                id: 'interactionTab',
                key: 'Interactions',
                label: 'contact.tab.interactions',
                content: <ListView type='interactionList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Interaction,
                disabled: false,
            },
            {
                id: 'invoiceTab',
                key: 'Invoices',
                label: 'contact.tab.invoices',
                content: <ListView type='contactInvoiceList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Invoice,
                disabled: false,
            }
        ]
    },
    warehouseTabView: {
        cardViewType: 'warehouseCard',
        width: '1024px',
        condition: {tab: 'halls', field: 'useHalls', value: true},
        tabs: [
            {
                id: 'hallsTab',
                key: 'halls',
                label: 'warehouse.tab.halls',
                content: <ListView type='hallList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Hall,
                disabled: false,
            }
        ]
    },
    productTabView: {
        cardViewType: 'productCard',
        width: '1024px',
        tabs: [
            {
                id: 'itemLocationsTab',
                key: 'itemLocations',
                label: 'product.tab.locations',
                content: <ListView type='itemLocationList' options={{ viewFilters: false }}></ListView>,
                icon: Icon_Hall,
                disabled: false,
            }
        ]
    }
}