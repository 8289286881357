import { checkPrevValue } from "./checkCondition.js";

const addDaysToDate = (dateString, daysToAdd = 0) => {
    // Parse the input date string to a Date object  
    const date = new Date(dateString);

    // Add the specified number of days to the date
    date.setDate(date.getDate() + daysToAdd);

    // Format the resulting date back to the same format
    const formattedDate = date.toISOString();

    return formattedDate;
};

const sumDateByDays = (data, operation, prevData) => {
    const { conditionField, fieldsToSum, targetField } = operation

    const isTrue = checkPrevValue(data, prevData, conditionField)

    //if the condition Field has changed, then Execute the logic
    if (isTrue) {
        if (data[fieldsToSum[0]] === undefined) return
        //New Date is calculated:
        const newDate = addDaysToDate(data[fieldsToSum[0]], data[fieldsToSum[1]])
        data[targetField] = newDate
    }

    return data
}

export default sumDateByDays