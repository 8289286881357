function getNestedValue(obj, key) {
    return key.split('/').reduce((acc, part) => acc && acc[part], obj);
}

const addFields = (data, operation, addData) => {

    // Loop through each field in the rootFields array
    for (const field of operation.fields) {
        const { targetField, sourceField, toValue } = field;

        // Retrieve Nested Values
        if (sourceField && typeof sourceField === 'string' && sourceField.includes('/')) {
            data[targetField] = getNestedValue(addData, sourceField)
        } else
            // Main Case assign the value of sourceField to targetField
            if (sourceField) {
                data[targetField] = addData[sourceField]
                //Secondary case assing the value of Date
            } else if (toValue) {
                if (typeof toValue === 'string' && toValue.includes('{today}')) {
                    let now = new Date()
                    data[targetField] = now
                } else
                    //Assign any other value
                    data[targetField] = toValue
            }
    }

    return data

}

export default addFields