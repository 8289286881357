const extractNames = (msg) => {

  //Check if the msg object is an array
  if (Array.isArray(msg)) {
    return msg.map(item => item.target.name);
  } else {
    //if it not an array return a one field array
    const array = []
    array.push(msg.target.name)
    return array

  }

};

export default extractNames