import React, { useState } from 'react';
import UnitSelector from "./UnitSelector";
import styled from "styled-components";

import { Tag } from "./Common";

function UnitTag({ unit, units, onChange }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(unit)

  const handleClick = () => {
    setShowModal(!showModal);
  };

  const handleUnitClick = (unit) => {
    setSelectedUnit(unit)
    onChange(unit);
    setShowModal(false);
  };

  return (
    <>
      <Tag className="unit-tag" onClick={handleClick}>
        <span>{selectedUnit}</span>
      </Tag>
      {showModal && <UnitSelector units={units} onUnitSelect={handleUnitClick} />}
    </>
  );
}


export default UnitTag;
