import styled from "styled-components"

const SelectInput = styled.select`
    background: ${ ({theme})=> theme.dropdown.background};
    color: ${ ({theme})=> theme.font.color};
    width: ${ ({width}) => width};
    border-radius: 0.375rem;
    border: 1px solid #ced4da;
    display: block;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(.75rem - 3px);
    font-size: var(--font-S);
    font-weight: 400;
    line-height: 1.25;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
`

const Option = styled.option`
    line-height: 2rem;
`


export const SimpleSelect = ({currentValue, handleChange, options, width}) => {

    return (
    <SelectInput
        value={currentValue}
        onChange={handleChange}
        width={width}
    >
        {options.map(
            (op, index) => <Option key={index} value={op}>{op.charAt(0).toUpperCase()+op.slice(1)}</Option>
        )}
    </SelectInput>
    )

}

