import { useState, useRef, useEffect, useMemo } from "react";
import styled from "styled-components";

//Import UI components to be used
import { Form } from '../form/FormComponents';
import CountryMenuItem from "./CountryMenuItem";

//setup for language State
import useTranslation from "../../hooks/useTranslation";

//Import KeyPress Utils:
import useKeyPress from "../../hooks/useKeyPress";
import { CountryFlag } from "./CountryMenuItem";

//Controlling the Outside Click
import useOutsideClick from "../../hooks/useOutsideClick";

import { CheckIcon, CrossIcon } from '../generic/Validate'


//Define Styled Components

const SearchContainer = styled.div`
    height: "100%";

    @media (max-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: none;
    }
    
`

const InputFlag = styled(CountryFlag)`
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  z-index: 2;
`
const InputField = styled(Form.Control)`
    padding-left:45px;
`

const SearchResults = styled.div`
    position: absolute;
    top: 42px;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgb(0 0 0 / 28%);
    z-index:101;
    
    &.visible {
      visibility: visible;
    }
  
    &.invisible {
        visibility: hidden!important;
    }
  `;

const ListGroup = styled.ul`
    --list-group-color: #212529;
    --list-group-bg: ${({ theme }) => theme.dropdown.background};;
    --list-group-border-color: rgba(0, 0, 0, 0.125);
    --list-group-border-width: 1px;
    --list-group-border-radius: 0.375rem;
    --list-group-item-padding-x: 1rem;
    --list-group-item-padding-y: 0.5rem;
    --list-group-action-color: #495057;
    --list-group-action-hover-color: #495057;
    --list-group-action-hover-bg: #f8f9fa;
    --list-group-action-active-color: #212529;
    --list-group-action-active-bg: #e9ecef;
    --list-group-disabled-color: #6c757d;
    --list-group-disabled-bg: #fff;
    --list-group-active-color: #fff;
    --list-group-active-bg: #0d6efd;
    --list-group-active-border-color: #0d6efd;
    display: flex;
    flex-direction: column;
    background-color: var(--list-group-bg);
    padding-left: 0;
    margin-bottom: 0;
    border-radius: var(--list-group-border-radius);
    overflow-x: hidden;
`


const CountryControl = ({ data, onSelect, placeholder, valueId, valueName, handleBlur, isRed, flags }) => {
    const [isVisbile, setVisiblity] = useState(false);
    const [search, setSearch] = useState("");
    const [cursor, setCursor] = useState(-1);

    const searchResultRef = useRef(null);

    const handleClickOutside = event => {
        hideSuggestion();
    };

    //Handle Click Outside the Object
    const myRef = useRef(null);
    useOutsideClick(myRef, handleClickOutside, 'modal');


    const { t } = useTranslation()

    //Monitoring pressing Enter
    const enterPressed = useKeyPress('Enter');

    useEffect(() => {
        if (enterPressed) {
            if (cursor >= 0) {
                setSearch(suggestions[cursor].name);
                hideSuggestion();
                onSelect(suggestions[cursor]);
            }
        }
    }, [enterPressed]);

    //Load the value that is already in the DB
    useEffect(() => {
        setSearch(valueName)
    }, [valueName])

    //Moving the cursor
    const scrollIntoView = position => {
        searchResultRef.current.parentNode.scrollTo({
            top: position,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        if (cursor < 0 || cursor > suggestions.length || !searchResultRef) {
            return () => { };
        }

        let listItems = Array.from(searchResultRef.current.children);
        listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop);
    }, [cursor]);

    //Filtering Suggestion on Search
    const suggestions = useMemo(() => {
        if (!search) return data.list;

        setCursor(-1);
        scrollIntoView(0);

        return data.list.filter(item =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );
    }, [data.list, search]);


    //Hide & Show the Menu
    const showSuggestion = () => setVisiblity(true);
    const hideSuggestion = () => setVisiblity(false);

    //Keyboard events
    const keyboardNavigation = e => {
        if (e.key === "ArrowDown") {
            isVisbile
                ? setCursor(c => (c < suggestions.length - 1 ? c + 1 : c))
                : showSuggestion();
        }

        if (e.key === "ArrowUp") {
            setCursor(c => (c > 0 ? c - 1 : 0));
        }

        if (e.key === "Escape") {
            hideSuggestion();
        }

        if (e.key === "Enter" && cursor > 0) {
            e.preventDefault()
            setSearch(suggestions[cursor].name);
            hideSuggestion();
            onSelect(suggestions[cursor]);
        }
    };

    return (
        <SearchContainer ref={myRef}>
            <Form.Group name="Country">
                <InputFlag src={flags[valueId]}></InputFlag>
                <InputField
                    type="text"
                    value={search || ''}
                    name={'name'}
                    onClick={showSuggestion}
                    onChange={e => setSearch(e.target.value)}
                    onFocus={() => showSuggestion()}
                    onKeyDown={e => keyboardNavigation(e)}
                    autoComplete="new-country"
                    placeholder={t(placeholder)}
                    onBlur={handleBlur}
                    {...(isRed && { required: true })}
                />
                {valueName && <CheckIcon ></CheckIcon>}
                {!valueName && !isRed && <CrossIcon ></CrossIcon>}
            </Form.Group>
            <SearchResults id='countries-search-result'
                className={` ${isVisbile ? "visible" : "invisible"
                    }`}
            >
                <ListGroup ref={searchResultRef} id='countries-menu'>
                    {suggestions.map((item, idx) => (
                        <CountryMenuItem
                            key={item.name}
                            onSelectItem={() => {
                                hideSuggestion();
                                setSearch(item.name);
                                onSelect(item);
                            }}
                            isHighlighted={cursor === idx ? true : false}
                            {...item}
                            flag={flags[item.id]}
                        />
                    ))}
                </ListGroup>
            </SearchResults>
        </SearchContainer>
    );
};

export default CountryControl;