import styled from "styled-components"

import { Button, TextControl } from "../generic/Core"
import IconButton from "../generic/IconButton"

export const FormShell = styled.div`
    margin-top: 1rem;
`

export const FormSection = styled.div`
    position: relative;
    max-width: ${({ width }) => width};
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.form.border};
    border-radius: 1rem;
    margin-bottom: 1rem;
    background: ${({ theme }) => theme.form.background};
    min-height: 3rem;    
    box-shadow: ${({ theme }) => theme.card.boxShadow};

    &:hover {
        border: 1px solid ${({ theme }) => theme.form.activeBorder};
    }
`

export const FormLabel = styled.label`
    margin-bottom: 0.25rem;
    width: 100%;
`

export const FormRow = styled.div`
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-direction: ${({$direction}) => $direction || "row"};
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    & >* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
`

export const FormButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const FormCol = styled.div`
    @media (min-width: 576px) {
    flex: 1 0 0%; }
`

export const FormStack = styled.div`
    flex: 1 0 0%;
`

export const FormTextArea = styled.textarea`
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.form.font};
    background: ${({ theme }) => theme.form.textControl.background};
    border: 1px solid ${({ theme }) => theme.form.border};
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    min-height: ${({ $rows }) => $rows ? `calc(1.5rem * ${$rows} + 0.75rem + 2px)` : 'calc(1.5em + 0.75rem + 2px)'};
    height: ${({ $rows }) => $rows ? `calc(1.5rem * ${$rows} + 0.75rem + 2px)` : 'auto'};
    resize: vertical;
    box-shadow: ${({ theme }) => theme.form.textControl.boxShadow};

    &:focus{
        border: ${({ theme }) => theme.form.textControl.activeBorder};   
        box-shadow: ${({ theme }) => theme.form.textControl.activeBoxShadow};
        outline: 0;
    }

    &:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}        
`
export const FormControl = TextControl
export const FormButton = Button

export const FormGroup = styled.div`
    margin-bottom: ${props => props.$margin ? props.$margin : '0.75rem'} ;
`

export const FormDataList = styled.datalist`
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
        outline: 0;
     }  
`

FormGroup.Label = FormLabel
FormGroup.Row = FormRow
FormGroup.Col = FormCol
FormGroup.Stack = FormStack
FormGroup.Control = FormControl
FormGroup.TextArea = FormTextArea


export const Form = styled.form.attrs({
    autoComplete: 'off',
})`
    display: block;
    margin-top: 0em;
`

Form.Group = FormGroup
Form.Section = FormSection
Form.Shell = FormShell
Form.Label = FormLabel
Form.Row = FormRow
Form.Col = FormCol
Form.Stack = FormStack
Form.Control = FormControl
Form.Button = FormButton
Form.IconButton = IconButton
Form.DataList = FormDataList
Form.ButtonGroup = FormButtonGroup
Form.TextArea = FormTextArea
