function verifyAmount(amount, array, arrayField) {
    // Calculate the current sum of the arrayField values
    const currentSum = array.reduce((sum, obj) => sum + obj[arrayField], 0);

    // If the current sum equals the desired amount, return the array as is
    if (currentSum === amount) {
        return array;
    }

    // Calculate the difference that needs to be adjusted
    let difference = currentSum - amount;

    // Iterate from the last element to the first element to adjust the array
    for (let i = array.length - 1; i >= 0; i--) {
        const value = array[i][arrayField];

        if (difference > 0) {
            // We need to decrease the value
            if (value > difference) {
                array[i][arrayField] -= difference;
                break;
            } else {
                difference -= value;
                array.splice(i, 1); // Remove the element as it reduces to zero or below
            }
        } else {
            // We need to increase the value
            array[i][arrayField] += -difference;
            break;
        }
    }

    return array;
}

const subCheckTotal = (data, operation) => {

    if (!data[operation.subEntity]) {
        return data
    }

    const amount = data[operation.field]
    const array = [...data[operation.subEntity]]
    const arrayField = operation.subEntityField


    const verifiedArray = verifyAmount(amount, array, arrayField)

    data[operation.subEntity] = verifiedArray
    data[operation.fieldCount] = verifiedArray.length

    return data
}

export default subCheckTotal
