import DisplayTotals from "../display/DisplayTotals"
import styled from "styled-components"

const SectionWrapper = styled.div`
    padding-inline: 1rem;
`


const CardViewFormTotals = ({ section, tData }) => {
    return (
        <SectionWrapper>
            <DisplayTotals
                section={section}
            />
        </SectionWrapper>
    )

}

export default CardViewFormTotals