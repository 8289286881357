import { multiply } from "./core.js"

const subMultiply = (data, operation) => {
    const { fieldsToOperate, targetField, subEntity } = operation
    let result

    if (data[subEntity] && Array.isArray(data[subEntity])) {
        for (const elem of data[subEntity]) {
            result = multiply(elem, fieldsToOperate)
            elem[targetField] = parseFloat(result);
        }
    }
    return data
}

export default subMultiply