import { useState, useRef } from "react";
import styled from "styled-components";

//Import UI components to be used
import { Form } from '../form/FormComponents';
import SelectModal_m from "./SelectModal_m";
import SelectInput from "./SelectInput";

//setup for language State
import useTranslation from "../../hooks/useTranslation";


const SearchContainer = styled.div`
    height: "100%";

    @media (min-width: ${({ theme }) => theme.breakpoint.xs}) {
        display: ${(props) => props.$allwaysOn ? 'block' : 'none'};
        
    }
    
`

const SelectControl_m = ({ data, onSelect, placeholder, valueId, valueName, entity, modalTitle, isRed, $allwaysOn, options = {}, defaultValue }) => {
    const [isVisible, setIsVisible] = useState(false);
    const searchContainer = useRef(null);
    const { t } = useTranslation()

    //Hide & Show the Menu
    const showModal = () => { setIsVisible(true) }

    if (valueName === '') valueName = defaultValue.name


    return (
        <SearchContainer ref={searchContainer} $allwaysOn={$allwaysOn}>
            <Form.Group >
                <SelectInput
                    readonly
                    type="text"
                    valueId={valueId || ''}
                    valueName={valueName || ''}
                    name={`${entity}Name`}
                    showModal={showModal}
                    placeholder={t(placeholder)}
                    isRed={isRed}
                    autoComplete={`new-${entity}`}
                />
            </Form.Group>
            {isVisible && <SelectModal_m
                isVisible={isVisible} setIsVisible={setIsVisible} data={data}
                valueName={valueName} onSelect={onSelect} valueId={valueId}
                entity={entity} modalTitle={modalTitle} options={options}
                defaultValue={defaultValue}
            >
            </SelectModal_m>
            }
        </SearchContainer>
    );
};

export default SelectControl_m;