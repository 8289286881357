import styled from "styled-components";

import { useEffect, useState, memo, useRef } from "react";
import throttle from 'lodash/throttle';
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "react-query";

import Loader from "../generic/Loader";
import PdfView from "./PdfView";

import { pdfData } from "../../model/components/pdfData";

const msg = {
  "localLang": "da-DK",
  "fileName": "pdf",
}

const PdfContent = styled.div`

  width: 100%;
  @media (max-width: 768px) {
    background : ${({ theme }) => theme.pdf.background};
    padding: 0.75rem;
  } 
`

const PdfWrapper = styled.div`
    width: 100%;
    height: ${(props) => `${props.height}px` || '100px'};
`

const PdfPager = memo(({ state, entity }) => {
  const [pdf, setPdf] = useState();
  const [width, setWidth] = useState(200);
  const queryClient = useQueryClient();
  const pdfWrapperRef = useRef(null);

  const config = pdfData[entity] || { images: [] };

  useEffect(() => {
    const updateWidth = () => {
      const pdfWrapperElement = document.getElementById("pdfWrapper");
      if (pdfWrapperElement) {
        setWidth(pdfWrapperElement.getBoundingClientRect().width);
      }
    };

    const handlePdfContentSizeChange = throttle(() => {
      updateWidth();
    }, 1000);

    // Initial call to set the width
    updateWidth();

    // Add resize event listener
    window.addEventListener("resize", throttle(updateWidth, 1500));

    const resizeObserver = new ResizeObserver(handlePdfContentSizeChange);
    if (pdfWrapperRef.current) {
      resizeObserver.observe(pdfWrapperRef.current);
    }

    // Cleanup: remove event listener and disconnect observer
    return () => {
      window.removeEventListener("resize", throttle(updateWidth, 1500));
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    // Invalidate query using the latest savedOn value
    queryClient.invalidateQueries([`pdf`, `${state.savedOn}`]);
  }, [state.savedOn, queryClient]);

  const { postMeStream } = useApi();

  const getPdf = async () => {
    try {
      const payload = { data: state, msg: msg, images: config.images };
      const response = await postMeStream(`pdf`, payload);
      return response;
    } catch (error) {
      console.log('Error fetching PDF:', error);
    }
  };

  const result = useQuery([`pdf`, `${state.savedOn}`], getPdf, {
    staleTime: 10000,
    onSuccess: (data) => {
      setPdf(data);
    }
  });

  return (
    <PdfContent className="contentPdf" >
      <PdfWrapper ref={pdfWrapperRef} id="pdfWrapper" height={width * 1.41 + 65}>
        {pdf && <PdfView id="pdfView" pdf={pdf} width={width} state={state} />}
        {!pdf && <Loader />}
      </PdfWrapper>
    </PdfContent>
  );
}, (prevProps, nextProps) => {
  return prevProps.state.savedOn === nextProps.state.savedOn && prevProps.entity === nextProps.entity;
});

export default PdfPager;
