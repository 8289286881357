function floorNum(num, n) {
  const roundedNum = (num * Math.pow(10, n)) / Math.pow(10, n);
  return roundedNum.toFixed(n);
}

export const addThousandSeparator = (numString, decSeparator) => {
  const isNegative = numString.startsWith('-');
  const absNumString = isNegative ? numString.slice(1) : numString;
  const [integerPart, decimalPart] = absNumString.split(decSeparator);
  const thousandSeparator = decSeparator === "," ? "." : ",";
  
  // Remove existing thousand separators before adding new ones
  const cleanIntegerPart = integerPart.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
  
  const integerWithSeparator = cleanIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  const result = decimalPart ? integerWithSeparator + decSeparator + decimalPart : integerWithSeparator;

  return isNegative ? '-' + result : result;
}

export const removeThousandSeparator = (numString, decSeparator) => {
  const isNegative = numString.startsWith('-');
  const absNumString = isNegative ? numString.slice(1) : numString;
  const thousandSeparator = decSeparator === "," ? "." : ",";
  const pattern = new RegExp(`\\${thousandSeparator}`, "g");
  const result = absNumString.replace(pattern, "");
  return isNegative ? '-' + result : result;
}

export const formatDecimalSeparator = (stringValue, decimalSeparator) => {
  const isNegative = stringValue.startsWith('-');
  const absStringValue = isNegative ? stringValue.slice(1) : stringValue;

  // Remove all characters except digits and decimal separator
  const regex = new RegExp(`[^0-9.,]`, "g");
  let formattedValue = absStringValue.replace(regex, "");

  // Ensure only one decimal separator
  const parts = formattedValue.split(/[.,]/);
  formattedValue = parts[0] + (parts.length > 1 ? decimalSeparator + parts.slice(1).join('') : '');

  return isNegative ? '-' + formattedValue : formattedValue;
}

export const formatNumber = (numValue, minDec, maxDec) => {
  if (numValue === 0) return '0';

  const isNegative = numValue < 0;
  let absNumValue = Math.abs(numValue);

  if (typeof absNumValue !== "number") {
    absNumValue = parseFloat(absNumValue.toString().replace(",", "."));
  }

  // Handle potential overflow for very large numbers
  if (!isFinite(absNumValue)) {
    return isNegative ? '-Infinity' : 'Infinity';
  }

  //Round to Max Decimals and fix trailing decimals
  const roundedString = floorNum(absNumValue, maxDec); // Round to the specified number of max decimals
  // Remove trailing zeros and the decimal separator if there are no non-zero decimals after it
  let shortenValue;
  if (roundedString.includes(".")) {
    shortenValue = roundedString.replace(/\.?0+$/, '');
  } else {
    shortenValue = roundedString;
  }

  //If Decimal Places are less than minimum, add them
  let decimalPlaces = shortenValue.split('.')[1]?.length || 0;
  let shortenNum = parseFloat(shortenValue);
  let shortString;
  if (decimalPlaces < minDec) {
    shortString = shortenNum.toFixed(minDec);
  } else {
    shortString = shortenNum.toString();
  }

  //Sanitize the Input to only include valid characters and the correct decimal separator
  return isNegative ? '-' + shortString : shortString;
}
