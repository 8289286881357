import React, { forwardRef, useEffect, useRef } from 'react';
import CalendarMonth from "./CalendarMonth";
import styled from "styled-components";

//Getting local variables from the profile
import useTranslation from "../../hooks/useTranslation";
import moment from "moment";

const CalendarWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.dropdown.background};
  top:2.75rem;
  right: ${({ $align }) => $align === 'right' ? '0' : ''};
  padding-top: 0.5rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.dropdown.border};
  z-index: 1001;
  display: ${({ $isVisible }) => $isVisible ? 'block' : 'none'};
`

const CalendarTable = styled.table`
  margin: 0rem 0.5rem;
  padding: 0.5rem;
`

const CalendarCell = styled.td`

  text-align: center;
  width: 45px;
  font-size: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.dropdown.selectedBackground};
    color: ${({ theme }) => theme.dropdown.selectedFont};
    border-radius: 25px;
  }

  &.selected {
    background-color: ${({ theme }) => theme.calendar.selectedBackground};
    color: ${({ theme }) => theme.calendar.selectedColor};
    border-radius: 25px;
  }

  &.empty {
    pointer-events: none;
  }

  &>button{
    background-color: transparent;
    color: inherit;
    border: none;
    margin:0;
    padding:0;
    width: 100%;
  }
`

const CalendarHeader = styled.th`
  text-align: center;
  width: 45px;
  font-size: 1rem;  
`


const Calendar = forwardRef(({ isVisible, date, setDate, onSelect, $align ='right' }, ref) => {
  const prevElem = useRef(null)
  const { t } = useTranslation()

  useEffect(
    () => {
      if (prevElem.current) {
        prevElem.current.classList.remove('selected')
      }

      if (date) {
        const { rowIndex, dayIndex } = getCalendarIndex(date)
        const elem = document.getElementById(`${rowIndex}-${dayIndex}`)
        prevElem.current = elem
        if (elem) {
          elem.classList.add('selected')
        }

      }
    }, [date]
  )


  const handleMonthChange = (month) => {
    setDate((prevDate) => {
      return new Date(prevDate.getFullYear(), month, prevDate.getDate());
    });

  };

  const handleYearChange = (year) => {
    setDate((prevDate) => {
      return new Date(year, prevDate.getMonth(), prevDate.getDate());
    });
  };

  const handleDaySelect = (e,day) => {
    e.preventDefault()
    const selectedDate = new Date(date.getFullYear(), date.getMonth(), day);
    //onSelect(selectedDate.toLocaleDateString(profile.currentOrgLanguage));
    onSelect(selectedDate)
    setDate(selectedDate)
  };

  //Prevents wrong submission of form 


  return (
    <CalendarWrapper $align={$align} $isVisible={isVisible} ref={ref}>
      <CalendarMonth date={date} onMonthChange={handleMonthChange} onYearChange={handleYearChange} />
      <CalendarTable>
        <thead>
          <tr>
            <CalendarHeader >{t('week.sun')}</CalendarHeader>
            <CalendarHeader>{t('week.mon')}</CalendarHeader>
            <CalendarHeader>{t('week.tue')}</CalendarHeader>
            <CalendarHeader>{t('week.wed')}</CalendarHeader>
            <CalendarHeader>{t('week.thu')}</CalendarHeader>
            <CalendarHeader>{t('week.fri')}</CalendarHeader>
            <CalendarHeader>{t('week.sat')}</CalendarHeader>
          </tr>
        </thead>
        <tbody>
          {getCalendarRows(date).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((day, dayIndex) => (
                <CalendarCell
                  id={`${rowIndex}-${dayIndex}`}
                  key={dayIndex}
                  className={day === null ? "empty" : undefined}
                  onClick={(e) => handleDaySelect(e,day)}
                >
                  <button>{day !== 0 && day}</button>
                </CalendarCell>
              ))}
            </tr>
          ))}
        </tbody>
      </CalendarTable>
    </CalendarWrapper>
  );
});

function getCalendarRows(date) {
  if (!moment(date).isValid()) return [['1', '2', '3']]
  const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
  const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const startDate = new Date(monthStart);
  startDate.setDate(startDate.getDate() - startDate.getDay()); // start on Sunday
  const endDate = new Date(monthEnd);
  endDate.setDate(endDate.getDate() + (6 - endDate.getDay())); // end on Saturday

  const rows = [];
  let row = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    if (row.length === 7) {
      rows.push(row);
      row = [];
    }
    if (currentDate < monthStart || currentDate > monthEnd) {
      row.push(null); // add an empty cell for days outside the month
    } else {
      row.push(currentDate.getDate());
    }
    currentDate = new Date(currentDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  if (row.length > 0) {
    rows.push(row);
  }

  return rows;
}

function getCalendarIndex(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = firstDayOfMonth.getDay();
  const dayOfMonth = date.getDate();

  const rowIndex = Math.floor((dayOfWeek + dayOfMonth - 1) / 7);
  const dayIndex = (dayOfWeek + dayOfMonth - 1) % 7;

  return { rowIndex, dayIndex };
}


export default Calendar;


