const fallbackLanguages = {
    'en-US': 'en-US',
    'da-DK': 'en-US',
    'it-IT': 'en-US',
    'de-DE': 'en-US',
    'fr-FR': 'en-US',
    'es-ES': 'en-US',
    'sv-SE': 'en-US',
    'no-NO': 'en-US',
    'fi-FI': 'en-US',
    'pl-PL': 'en-US',
    'pt-PT': 'en-US'
}

export default fallbackLanguages;

