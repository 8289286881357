import ListView from "../../../components/listView/ListView";
import Page from "../../../components/pageView/Page";
import WidgetPage from "../../../components/widgetPage/WidgetPage";

const routesInventory = [
  {
    path: "/inventory",
    element: WidgetPage,
    props: {
      type: "inventory",
      draggable: false,
      resizable: false,
    }
  },
  {
    path: "/inventory/product",
    element: ListView,
    props: {
      type: "productList",
    }
  },
  {
    path: "/inventory/product/:id",
    element: Page,
    props: {
      type: "productMultiPage",
      options: { mode: "edit", load: true, collapse: true }, 
    }
  },
  {
    path: "/inventory/product/new",
    element: Page,
    props: {
      type: "productPage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/inventory/warehouse",
    element: ListView,
    props: {
      type: "warehouseList",
    }
  },
  {
    path: "/inventory/warehouse/new",
    element: Page,
    props: {
      type: "warehousePage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/inventory/warehouse/:id",
    element: Page,
    props: {
      type: "warehouseTabPage",
      options: { mode: "edit", load: true, collapse: true }
    }
  },
  {
    path: "/inventory/warehouse/:id/hall/new",
    element: Page,
    props: {
      type: "hallPage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/inventory/warehouse/:id/hall/:id2",
    element: Page,
    props: {
      type: "hallPage",
      options: { mode: "edit", load: true },
    }
  },
  {
    path: "/inventory/rack",
    element: ListView,
    props: {
      type: "rackList",
    }
  },
  {
    path: "/inventory/rack/:id",
    element: Page,
    props: {
      type: "rackPage",
      options: { mode: "edit", load: true },
    }
  },
  { 
    path: "/inventory/rack/new",
    element: Page,
    props: {
      type: "rackPage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/inventory/location",
    element: ListView,
    props: {
      type: "locationList",
    }
  },
  {
    path: "/inventory/location/:id",
    element: Page,
    props: {
      type: "locationPage",
      options: { mode: "edit", load: true, collapse: true },
    }
  },
  {
    path: "/inventory/adjustment",
    element: ListView,
    props: {
      type: "stockAdjustmentList",
    }
  },
  {
    path: "/inventory/adjustment/:id",
    element: Page,
    props: {
      type: "stockAdjustmentPage",
      options: { mode: "edit", load: true },
    }
  },
  {
    path: "/inventory/adjustment/new",
    element: Page,
    props: {
      type: "stockAdjustmentPage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/inventory/movement",
    element: ListView,
    props: {
      type: "stockMovementList",
    }
  },
  {
    path: "/inventory/movement/new",
    element: Page,
    props: {
      type: "stockMovementPage",
      options: { mode: "new", load: false },
    }
  },
  {
    path: "/inventory/movement/:id",
    element: Page,
    props: {
      type: "stockMovementPage",
      options: { mode: "edit", load: true },
    }
  },
];

export default routesInventory;
