import styled from 'styled-components'
import * as RiIcons from 'react-icons/ri'
import LangSwitcher from './LangSwitcher'
import OrgSwitcher from './OrgSwitcher'
import { AppIconColor } from '../../styles/IconStyle'

import useSidebar from '../../hooks/useSidebar'
import { useSnapshot } from 'valtio'

const Circle = styled.button`
  border-radius: 50%;
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
        background: ${({ theme }) => theme.dropdown.active};
        cursor: pointer;
    }
`

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 1rem;
  height: 4rem;
`

const SettingsWrapper = styled.div`
  display: flex;
  justify-content: end;
`

const Settings = styled.div`
  width:10rem;
  border-radius: 1rem;
  &:hover{
    background-color: var(--clr-gray);
  }
`

function Header() {

  const {toggleSidebar, sbState} = useSidebar()
  const snap = useSnapshot(sbState)
  const sidebarVisibility = snap.visible

  const deactivated = <RiIcons.RiMenuUnfoldFill id='openNav' />
  const activated = <RiIcons.RiMenuFoldFill id='closeNav' />

  return (
    <HeaderWrapper id='HeaderWrapper'>
      <AppIconColor>
        <Circle id='navCollapseButton' onClick={toggleSidebar}>
          {sidebarVisibility ? activated : deactivated}
        </Circle>
      </AppIconColor>
      <SettingsWrapper name='SettingsWrapper'>
        <OrgSwitcher></OrgSwitcher>
        <LangSwitcher></LangSwitcher>
      </SettingsWrapper>
    </HeaderWrapper>
  )
}

export default Header