import styled from 'styled-components';
import InputFeedback from './InputFeedback';

const EntryInput = styled.input`
    height: 2.5rem;
    background-color: #eee;
    display: block;
    box-sizing: border-box;
    width: 300px;
    font-size: 1rem;
    margin-bottom: 1rem;
    border-radius: 0px;
    border: 0;
    padding: 0.5rem;
`

const EntryLabel = styled.label`
.valid {
    color: green
}

.invalid {
    color: indianred;
}
    
.hide,
.offscreen {
    display: none;
}
`

//Importing Icons
import { Icon_Check, Icon_InfoCircle, Icon_Clear } from '../../../styles/icons';
import { forwardRef } from 'react'

const InputElem = forwardRef((props, ref) => {
    return (
        <>
            {/*email SECTION*/}
            <EntryLabel htmlFor="emailname">
                {props.label}
                <span className={props.validInput ? 'valid' : 'hide'}>
                    {Icon_Check}
                </span>
                <span className={props.validInput || !props.value ? 'hide' : 'invalid'}>
                    {Icon_Clear}
                </span>
            </EntryLabel>
            <EntryInput
                ref={ref}
                type={props.type}
                id={props.id}
                autoComplete="off"
                onChange={props.onChange}
                required
                aria-invalid={props.validEmail ? "false" : "true"}
                aria-describedby={props.noteId}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
            <InputFeedback id={props.noteId} focus={props.focus} value={props.value} validInput={props.validInput} noteText={props.noteText} />
        </>
    )

});

export default InputElem