const reduceArray = (data, operation) => {
    if (!data || !operation) {
        return data;
    }

    const sourceArray = data[operation.sourceArray];
    const targetArray = data[operation.targetArray];
    //This collects lines with value of 0 that need to be purged
    let purgeLines = []

    if (!sourceArray || !targetArray) {
        console.log('No Source Array or Target Array found')
        return data;
    }

    const targetMap = new Map();
    //saves the all docs in targetArray in a Map for easy lookup
    for (const targetDoc of targetArray) {
        if (targetDoc && targetDoc.itemId) {
            targetMap.set(targetDoc.itemId, targetDoc);
        }
    }

    //loops through all docs in sourceArray
    for (const sourceDoc of sourceArray) {
        const sourceSubArray = sourceDoc[operation.sourceSubArray];
        //console.log('[ReduceArray] SourceSubArray:', sourceSubArray)
        if (!sourceSubArray) continue;

        //Loops through all the sub docs in sourceSubArray and subtracts the values from the targetArray
        for (const sourceSubDoc of sourceSubArray) {
            const sourceSubDocId = sourceSubDoc.itemId;
            if (!sourceSubDocId) continue;

            //Retrieves the target doc from the Map
            const targetDoc = targetMap.get(sourceSubDocId);
            //console.log('[ReduceArray] TargetDoc:', targetDoc)
            if (targetDoc) {
                for (const fieldName of operation.targetFields) {
                    if (
                        typeof targetDoc[fieldName] === 'number' &&
                        typeof sourceSubDoc[fieldName] === 'number'
                    ) {
                        //reduces the value of the field in the target doc with the value in the source doc
                        targetDoc[fieldName] = (targetDoc[fieldName] || 0) - (sourceSubDoc[fieldName] || 0);
                    }
                    //if the total value of the field is 0, then assigns the line for purging
                    if (targetDoc[fieldName] === 0) {
                        purgeLines.push(sourceSubDocId);
                    }
                }
                //Removes the target fields from the target doc
                for (const fieldName of operation.targetRemoveFields) {
                    delete targetDoc[fieldName]
                }
            }
        }
    }

    //If there are lines to purge, then removes them from the targetMap
    if (purgeLines.length > 0) {
        purgeLines.forEach(lineId => {
            targetMap.delete(lineId);
        });
    }

    //Overwrites data.lines with the new array of lines
    data.lines = Array.from(targetMap.values())
    return data;
};

export default reduceArray