import analyzeSerie from "../../validate/analyzeSerie.js"

const serieCalculate = (data, operation, prevData) => {

    if (data[operation.sourceField] !== prevData[operation.sourceField])
    {

        const nSerie = analyzeSerie(data[operation.sourceField], data.entity)
        let nSerieId = encodeURIComponent(nSerie.id)
    
        //console.log('This is the new nSerie ID:', nSerieId)
        
        data[operation.targetField] = nSerieId

    }

    return data
}

export default serieCalculate