import { axiosPrivate } from "../api/axios"
import { useEffect } from 'react'
import useRefreshToken from "./useRefreshToken"
import useAuth from "./useAuth"
import { useNavigate } from "react-router-dom";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken()
    const { Auth } = useAuth()
    const navigate = useNavigate()


    useEffect(
        () => {

            const requestIntercept = axiosPrivate.interceptors.request.use(
                config => {
                    if (!config.headers['Authorization']) {
                        config.headers['Authorization'] = `Bearer ${Auth?.accessToken}`
                    }
                    return config
                }, (error) => Promise.reject(error)
            )

            const responseIntercept = axiosPrivate.interceptors.response.use(
                response => response,
                async (error) => {
                    const prevRequest = error?.config;
                    if (!navigator.onLine) {
                        // Handle no internet connection here
                        // Display a message to the user or perform other actions
                        console.log("No internet connection.");
                    }

                    //Check is refresh fails and logs the user out
                    if (error.response.config.url === '/token/refresh') {

                        console.log('Token Refresh Failed');
                        // You can perform specific actions or error handling for a 403 here.
                        navigate('/loggedout');
                        return Promise.reject(error);
                    }

                    // Runs refresh token endpoint in case of 403
                    if (error?.response?.status === 403 && !prevRequest?.sent) {
                        console.log(error.response.config.url)
                        prevRequest.sent = true;
                        const newAccessToken = await refresh();
                        prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosPrivate(prevRequest);
                    }

                    return Promise.reject(error);
                }
            );

            return () => {
                axiosPrivate.interceptors.response.eject(requestIntercept)
                axiosPrivate.interceptors.response.eject(responseIntercept)
            }
        }, [Auth, refresh]
    )

    return axiosPrivate;
}

export default useAxiosPrivate;