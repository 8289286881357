import { Icon_Boxes, Icon_Delete, Icon_Money } from "../../styles/icons"

export const modalGroupsData = {
    invoiceDesign: {
        modalGroups: [
            {
                label: 'invoice.form.font',
                type: 'dataList',
                id: 'invoiceFont',
                placeholder: 'invoice.form.font.placeholder',
                entity: 'fonts',
                modalTitle: 'invoice.form.font.modal.title',
                modalOpacity: 0,
                isRequired: true,
                defaultType: 'value',
                defaultValue: 'Helvetica',
                valueMap: {
                    'invoiceFont': 'id',
                },
            }
        ]
    },
    invoiceOptions: {
        modalGroups: [
            {
                type: "select",
                id: "currency",
                display: "currency",
                label: "invoice.form.currency",
                entity: "currency",
                modalTitle: "invoice.form.currency.modal.title",
                valueMap: {
                    currency: "id",
                    currencyName: "name",
                },
                placeholder: "invoice.form.currency.placeholder",
                options: { withHeaders: true, displayId: true },
                isRequired: true,
                defaultType: "select",
            },
            {
                type: "select",
                id: "language",
                display: "languageName",
                label: "invoice.form.language",
                entity: "language",
                modalTitle: "invoice.form.language.modal.title",
                valueMap: {
                    language: "id",
                    languageName: "name",
                },
                placeholder: "invoice.form.language.placeholder",
                options: { withHeaders: false },
                isRequired: true,
                defaultType: "select",
            },
        ],
    },
    invoicePayments: {
        modalGroups: [
            {
                type: "list",
                id: 'paymentDate',
                section: {
                    meta: {
                        type: 'lines',
                        linesArray: 'payments',
                        linesAddAction: 'invoice.form.payment.add.item',
                        name: 'invoicePaymentsLines',
                        width: '800px',
                        nFields: { 'paymentAmount': true }
                    },
                    groups: [
                         {
                            type: 'group',
                            focus: true,
                            fields: [
                                {
                                    type: 'date',
                                    id: 'paymentDate',
                                    label: 'invoice.form.paymentDate',
                                    placeholder: 'invoice.form.paymentDate.placeholder',
                                    defaultType: 'date'
                                },
                                {
                                    type: 'number',
                                    id: 'paymentAmount',
                                    placeholder: 'invoice.form.netPrice',
                                    label: 'invoice.form.paymentAmount',
                                    variant: 'amount',
                                    isRequired: true,
                                    decimals: 2,
                                    defaultType: 'number',
                                    defaultValue: 1,
                                },
   
                            ]
                        }
                    ],
                    buttons: [
                        {
                            type: 'delete',
                            size: 'icon',
                            icon: Icon_Delete,
                            variant: 'danger',
                            label: 'all.form.button.delete',
                            action: 'delete'
                        },
                        {
                            type: 'close',
                            variant: 'primary',
                            label: 'all.form.button.save',
                            action: 'save'
                        }
                    ],
                    display: {
                        type: 'lines',
                        linesArray: 'payments',
                        emptyMessage: 'invoice.form.payment.missing.message',
                        emptyField: 'paymentAmount',
                        editPos: '25%',
                        columns: [
                            {
                                size: 7,
                                grow: '70%',
                                fields: [
                                    {
                                        size: 'title',
                                        type: 'niceDate',
                                        value: 'paymentDate'
                                    }
                                ]
                            },
                            {
                                size: 3,
                                grow: '30%',
                                align: 'end',
                                fields: [
                                    {
                                        size: 'title',
                                        type: 'amount',
                                        value: 'paymentAmount'
                                    },
                                    {
                                        size: 'regular',
                                        type: 'currency'
                                    }
                                ],
                            }
                        ]
                    }
                },
            }
        ],
    }


}
