import { DisplayWrapper } from "./ImageDisplay"
import { ImageWrapper } from "./ImageDisplay"
import Loader from "../generic/Loader"

const ImageLoader = () => {
    return (
        <DisplayWrapper>
            <ImageWrapper>
                <Loader />
            </ImageWrapper>
        </DisplayWrapper>
    )
}

export default ImageLoader