import styled from 'styled-components'
import { Row, Container } from '../generic/Layout'

import useTranslation from '../../hooks/useTranslation'

const CardBlock = styled.div`
    background: ${({theme}) => theme.card.background}; 
    width: 150px;
    height: 60px;
    margin-inline-end: 10px;
    padding: 0.5rem;
    font-size: var(--font-S);
    border-radius: 0.5rem 0.5rem ;
    box-shadow: ${({theme}) => theme.card.boxShadow};;

    &.active {
        background: ${({theme}) => theme.card.selected};      
    }

    &:hover{
        background: ${({theme}) => theme.card.active};      
    }

    @media (max-width: 768px) {
        background-color: transparent;
        width: auto;
        height: auto;
    }
`

const ViewFilter = ({ filters, filter, setFilter }) => {
    if (!filters.marginTop) {filters.marginTop = '0.5rem'}
    if (!filters.marginBottom) {filters.marginBottom = '0.5rem'}

    const {t} = useTranslation()
    return (
        <Container $marginTop={filters.marginTop} $marginBottom={filters.marginTop}>
            <Row>
                {(filter!==undefined) && filters.list.map(
                    (f, i) => {
                        return <CardBlock 
                        id={f.filterName} 
                        key={i}
                        className = {(f.filterName === filters.list[filter].filterName) ? 'active' : 'inactive' }
                        onClick = { () => setFilter(i)}
                        >{t(f.filterName)}</CardBlock>
                    }
                )}
            </Row>
        </Container>
    )
}

export default ViewFilter