//Importing the Subcomponents
import CardView from "../cardView/CardView";
import SideForm from "../sideForm/SideForm";
import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import DisplayView from "../displayView/DisplayView";

import { useStore } from "../pageView/Page";

//import resizable components
import { ResizeHandle, Panel, PanelGroup } from "../resizable/Resizable";

const MultiViewWrapper = styled.div`
  display: flex;
  gap: 1rem;
  max-width: ${({ width }) => (width ? width : "")};
  width: 100%;
  margin: -1rem 0 -1rem -1rem;

`

const Wrapper = styled.div`
  padding: 1rem 0.5rem 1rem 1rem;
`

const ResizableView = ({ options, validated, sidePanelConfig, config, dispatch }) => {

  //console.log('[] OPTIONS: ', options)
  //console.log('[] CONFIG: ', config)

  const [entity] = useStore((s) => s['entity'])
  const { isMobile } = useScreenSize()

  return (
    <>
      {!isMobile && <MultiViewWrapper width={config.width}>
        {entity &&
          <PanelGroup autoSaveId={entity} direction='horizontal'>
            {sidePanelConfig && sidePanelConfig.position === "left" && (
              <>
                <Panel>
                  <SideForm formType={config.mainFormType}
                    config={sidePanelConfig} validated={validated} options={options} ></SideForm>
                </Panel>
                <ResizeHandle></ResizeHandle>

              </>
            )}
            <Panel>
              <Wrapper>
                {config.mainFormType === 'cardView' && <CardView type={config.cardViewType} options={options} dispatch={dispatch} /> }
                {config.mainFormType === 'displayView' && <DisplayView type={config.displayViewType} options={options} />}
              </Wrapper>
            </Panel>
            {sidePanelConfig && sidePanelConfig.position === "right" && (
              <>
                <ResizeHandle></ResizeHandle>
                <Panel>
                  <SideForm formType={config.mainFormType}
                    config={sidePanelConfig} validated={validated} options={options} ></SideForm>
                </Panel>
              </>
            )}
          </PanelGroup>}
      </MultiViewWrapper>}
      {isMobile && config.mainFormType === 'cardView' && <CardView type={config.cardViewType} options={options} />}
      {isMobile && config.mainFormType === 'displayView' && <DisplayView type={config.displayViewType} options={options} />}
    </>
  );
};

export default ResizableView;