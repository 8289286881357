import { useEffect } from "react";
import { proxy, useSnapshot } from "valtio";
import extractSlug from "../utils/extractSlug";
import globals from '../txt/all/globals.json';
import fallbackLanguages from "../model/fallbackLanguages";
import texts from "../model/text";

//This defines the Translation State
export const tState = proxy({
  currentLang: 'en-US',
  currentSlug: '/',
  ready: false,
  trans: {}
})


function splitPath(path) {
  // Split the path using the '/' delimiter
  const [folder, area] = path.split('/');

  // Return an object with folder and area
  return {
    folder,
    area
  };
}

export const importJSON = async (path, lang) => {
  try {
    // Split the path in folder and area
    const { folder, area } = splitPath(path);

    // Dynamically import the JSON file
    const importedModule = await import(`../txt/${folder}/${area}/${lang}.json`);

    // The imported module will contain the JSON data as default export
    const jsonData = importedModule.default;

    // Return the JSON data as an object
    return jsonData;
  } catch (error) {
    console.error(`Error loading JSON data from ../txt/${folder}/${area}/${lang}.json:`, error);
    const emptyJson = { "import.fail": "Failed to import!" };
    return emptyJson;
  }
};


const useTranslation = () => {
  //Extracting the main path name of the URL
  let path = window.location.pathname
  let slug = extractSlug(path)
  let previousSlug = useSnapshot(tState).currentSlug
  tState.currentSlug = slug

  //Reading the state
  const snap = useSnapshot(tState)

  useEffect(
    () => {
      //   console.log('tState has changed:', tState)
    }
    , [snap]
  )

  const loadTrans = async (additionalSlug) => {

    //Determine which languages to load
    //console.log('###CURRENT SLUG: ### ', snap.currentSlug)
    let languages = [snap.currentLang]
    let fallback = fallbackLanguages[snap.currentLang]

    if (snap.currentLang !== 'en-US') { languages.push('en-US') }

    if (fallback !== 'en-US' && fallback && fallback !== snap.currentLang) {
      languages.push(fallback)
    }

    //Import Each single language
    languages.forEach(async lang => {
      try {
        const textToLoad = texts[snap.currentSlug];
        let loadedText = {};

        if (textToLoad) {
          // Use Promise.allSettled to wait for all async imports to complete
          const loadedJsonPromises = textToLoad.map((path) => importJSON(path, lang));

          // Wait for all promises to settle
          const loadedJsonResults = await Promise.allSettled(loadedJsonPromises);

          // Merge all the loaded JSON objects into the loadedText object, ignoring rejected ones
          loadedJsonResults.forEach(result => {
            if (result.status === "fulfilled") {
              loadedText = { ...loadedText, ...result.value };
            } else {
              console.error(`Failed to load JSON for path: ${result.reason}`);
            }
          });

          //console.log('LOADED JSON:', loadedText);
        }

        // We are importing the shared translations here:
        let shared = await importJSON('all/shared', lang);
        let entries = await importJSON('app/entry/_default', lang);
        let additionalTranslation = [];

        if (additionalSlug) {
          additionalTranslation = await importJSON(additionalSlug, lang);
        }

        let loadedTranslations = { ...loadedText, ...globals, ...entries, ...shared, ...additionalTranslation };
        tState.trans[lang] = loadedTranslations;
      } catch (e) {
        console.log(e);
        console.log(`There are no texts for the slug: ${snap.currentSlug}`);
      }
    }
    )
    // console.log('Translations Loaded: ', tState)
  }

  if (snap.currentSlug !== previousSlug) { loadTrans() }

  // Function to find the translation based on a label
  const t = (label) => {

    let fallback = fallbackLanguages[snap.currentLang]

    return snap.trans[snap.currentLang] && snap.trans[snap.currentLang][label]
      ? snap.trans[snap.currentLang][label]
      : snap.trans[fallback] && snap.trans[fallback][label]
        ? snap.trans[fallback][label]
        : snap.trans['en-US'] && snap.trans['en-US'][label]
          ? snap.trans['en-US'][label]
          : label
  };

  //Merges label and array of values and return the merged string with values instead of ${n}
  const m = (label, values) => {
    if (values?.length === 0 || values === undefined) {
      return t(label);
    }

    let text = t(label)

    if (text === undefined) { text ="Undefined"}
    return text.replace(/\${(\w+)}/g, function (match, placeholder) {
      var index = parseInt(placeholder) - 1;

      if (index >= 0 && index < values.length) {
        return values[index];
      } else {
        return match;
      }
    });
  }

  return { tState, t, loadTrans, m }
}

export default useTranslation
