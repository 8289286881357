import { useEffect, useRef } from "react";
import { Button } from "../generic/Core";
import Modal from "./Modal";

import useTranslation from "../../hooks/useTranslation";
import CoreForm from "../cardView/CoreForm";
import processDefaults from "../../utils/defaults/processDefaults";
import useProfile from "../../hooks/useProfile";
import { useSnapshot } from "valtio";

import { useModalStore } from "./PopUp";

export function PopUpModal({ show, setShow, config, setAction, mode, data = {} }) {
    //Translation Use on the Modal with regulat t and m for Merge fields
    const { t, m } = useTranslation()
    const closeBtnRef = useRef(null)
    const timeOutRef = useRef(null)
    const { pState } = useProfile()
    const profile = useSnapshot(pState)

    const [state, setState] = useModalStore((s) => s)

    if (config === undefined) {
        config = { header: 'undefined', body: [], footer: [] }
    }

    const localDispatch = () => {
    }

    //Function that handles the choice on the Modal
    const handleClick = (action) => {
        if (action === 'close') { setShow(false) }
        else {
            const payload = { data: state, config: config }
            setAction({ action, payload })
        }
    }

    //Function that when it detect an enter changes the focus to the close button and clicks on it.
    const handleKeyDown = (e) => {
        //Condition checks if Enter is pressed and if the target is the same as the close Button
        if (e.key === 'Enter' & e.target === closeBtnRef.current) {
            closeBtnRef.current.click()
            //If a timeOutRef exists, it is cleared.
            if (timeOutRef.current !== null) {
                clearTimeout(timeOutRef.current);
            }
        }
    };

    //Function that once the visibility of the modal changes, sets the focus on the close button with a delay of 50 mSec
    useEffect(() => {
        if (show) {
            timeOutRef.current = setTimeout(() => {
                if (closeBtnRef.current) closeBtnRef.current.focus();
            }, 50);
        }

        //If there is a form, it loads the defaults
        if (config.form) {
            const translatedConfig = config?.form?.section || {}
            const result = processDefaults([translatedConfig], profile, [], data, t)
            setState(result)
        }
    }
        , [show])

    return (Array.isArray(config.body) &&
            <Modal setShow={setShow} show={show} mode={mode} className={show && (mode === 'fullscreen') ? 'fullscreen' : 'animate'} handleKeyDown={handleKeyDown}>
                <Modal.Header setShow={setShow}>
                    <h4>{t(config.header)}</h4>
                </Modal.Header>
                <Modal.Body >
                    {config.body.map((text, i) => {
                        return (
                            <p key={i}>{m(text, config.params)}</p>
                        )
                    })}
                    {config.form && <CoreForm
                        section={config.form.section}
                        dispatch={localDispatch}
                        validated=''
                        entity='modal'
                        useStore={useModalStore}
                    />
                    }
                </Modal.Body>
                <Modal.Footer>
                    {config.footer.map(
                        (item, i) => {
                            if (item.action === 'close') return (
                                <Button key={i} ref={closeBtnRef} $variant={item.type} onClick={() => { handleClick(item.action) }}>{t(item.text)}</Button>
                            )
                            if (item.action !== 'close') return (
                                <Button key={i} $variant={item.type} onClick={() => { handleClick(item.action) }}>{t(item.text)}</Button>
                            )
                        }
                    )}
                </Modal.Footer>
            </Modal>
    );
}