import CardView from "../../cardView/CardView" 
import { tabViewData } from "../../../model/components/tabViewData"
import Tabs from "../../tabs/Tabs"
import styled from "styled-components"
import { useStore } from "../Page"

const Wrapper = styled.div`
  max-width: ${ ({width}) => width };
`

const TabView = ({ type, options, data }) => {
  const config = tabViewData[type]
  let condition, conditionValue

  if (config.condition) {
    [condition] = useStore((s) => s[config.condition.field])
    conditionValue = {
      result: config.condition.value === condition,
      tab: config.condition.tab
    }
  }


  return (
    <Wrapper width={config.width}>
      <CardView data={data} type={config.cardViewType} options={options} />
      {/* Type determine how it looks. it can be empty or "rounded" */}
      <Tabs config={config.tabs} id={type} type={''} conditionValue={conditionValue}></Tabs>
    </Wrapper>
  )
}

export default TabView