import { useNavigate } from "react-router-dom"
import { Button } from "../../generic/Core"
import useTranslation from "../../../hooks/useTranslation"


const TextWidget = ({ type, data, labels }) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    return (
        <>
            <h3>{t(labels.title)}</h3>
            <p>{t(labels.text)}</p>
            { labels.button ? <Button $variant='secondary' onClick={(e) => { navigate(data.path) }}>{t(labels.button)}</Button>: <></>}

        </>
    )
}

export default TextWidget

