const subTransfer = (data, operation, prevData) => {
    const { condition, subEntity, transferMap } = operation

    if (data[condition[0]] === condition[1]) {
        //console.log('Transfer Operation is a GO!')

        data[subEntity] = []
        let subEntityItem = {}

        // Iterate over the keys of transferMap
        for (const key in transferMap) {
            if (transferMap.hasOwnProperty(key)) { // Ensure it's an own property
                subEntityItem[transferMap[key]] = data[key];
            }
        }

        //console.log('Item transferred:', subEntityItem)
        data[subEntity].push(subEntityItem)
    }

    return data
}

export default subTransfer
