import styled from "styled-components"
import MobileOptionPanelItem from "./MobileOptionPanelItem"

const WrapperDiv = styled.div`
    margin-left: -20px;
    @media (min-width: ${({ theme }) => theme.breakpoint.s}) {
        display: none;
    }
`
const MobilePanelBox = styled.div`
    display: flex;
    padding-inline: 1rem;
    justify-content: flex-end;
    width: 100%;
    height: 55px;
    p {
        text-align: center;

    }
`

export const MobileFormSection = styled.div`
    position: relative;
    max-width: ${({ width }) => width};
    padding: 0rem 1rem 1rem;
    border: 1px solid ${({ theme }) => theme.form.border};
    border-radius: 1rem;
    margin-bottom: 1rem;
    background: ${({ theme }) => theme.form.background};
    min-height: 3rem;    
    box-shadow: ${({ theme }) => theme.card.boxShadow};

    &:hover {
        border: 1px solid ${({ theme }) => theme.form.activeBorder};
    }
    
    @media (min-width: ${({ theme }) => theme.breakpoint.s}) {
        display: none;
    }

`


const MobileOptionPanel = ({ config, validated, useStore }) => {


    return (
        <MobileFormSection name="mobilePanel">
            <WrapperDiv>
                <MobilePanelBox>
                    {config.items.map(
                        (item, i) => {
                            return <MobileOptionPanelItem key={item.title} item={item} useStore={useStore}/>
                        }
                    )}

                </MobilePanelBox>
            </WrapperDiv>
        </MobileFormSection>
    )

}

export default MobileOptionPanel