import AF from './flagsPng/af.png'
import AX from './flagsPng/ax.png'
import AL from './flagsPng/al.png'
import DZ from './flagsPng/dz.png'
import AS from './flagsPng/as.png'
import AD from './flagsPng/ad.png'
import AO from './flagsPng/ao.png'
import AI from './flagsPng/ai.png'
import AQ from './flagsPng/aq.png'
import AG from './flagsPng/ag.png'
import AR from './flagsPng/ar.png'
import AM from './flagsPng/am.png'
import AW from './flagsPng/aw.png'
import AU from './flagsPng/au.png'
import AT from './flagsPng/at.png'
import AZ from './flagsPng/az.png'
import BS from './flagsPng/bs.png'
import BH from './flagsPng/bh.png'
import BD from './flagsPng/bd.png'
import BB from './flagsPng/bb.png'
import BY from './flagsPng/by.png'
import BE from './flagsPng/be.png'
import BZ from './flagsPng/bz.png'
import BJ from './flagsPng/bj.png'
import BM from './flagsPng/bm.png'
import BT from './flagsPng/bt.png'
import BO from './flagsPng/bo.png'
import BQ from './flagsPng/bq.png'
import BA from './flagsPng/ba.png'
import BW from './flagsPng/bw.png'
import BV from './flagsPng/bv.png'
import BR from './flagsPng/br.png'
import IO from './flagsPng/io.png'
import UM from './flagsPng/um.png'
import VG from './flagsPng/vg.png'
import VI from './flagsPng/vi.png'
import BN from './flagsPng/bn.png'
import BG from './flagsPng/bg.png'
import BF from './flagsPng/bf.png'
import BI from './flagsPng/bi.png'
import KH from './flagsPng/kh.png'
import CM from './flagsPng/cm.png'
import CA from './flagsPng/ca.png'
import CV from './flagsPng/cv.png'
import KY from './flagsPng/ky.png'
import CF from './flagsPng/cf.png'
import TD from './flagsPng/td.png'
import CL from './flagsPng/cl.png'
import CN from './flagsPng/cn.png'
import CX from './flagsPng/cx.png'
import CC from './flagsPng/cc.png'
import CO from './flagsPng/co.png'
import KM from './flagsPng/km.png'
import CG from './flagsPng/cg.png'
import CD from './flagsPng/cd.png'
import CK from './flagsPng/ck.png'
import CR from './flagsPng/cr.png'
import HR from './flagsPng/hr.png'
import CU from './flagsPng/cu.png'
import CW from './flagsPng/cw.png'
import CY from './flagsPng/cy.png'
import CZ from './flagsPng/cz.png'
import DK from './flagsPng/dk.png'
import DJ from './flagsPng/dj.png'
import DM from './flagsPng/dm.png'
import DO from './flagsPng/do.png'
import EC from './flagsPng/ec.png'
import EG from './flagsPng/eg.png'
import SV from './flagsPng/sv.png'
import GQ from './flagsPng/gq.png'
import ER from './flagsPng/er.png'
import EE from './flagsPng/ee.png'
import ET from './flagsPng/et.png'
import FK from './flagsPng/fk.png'
import FO from './flagsPng/fo.png'
import FJ from './flagsPng/fj.png'
import FI from './flagsPng/fi.png'
import FR from './flagsPng/fr.png'
import GF from './flagsPng/gf.png'
import PF from './flagsPng/pf.png'
import TF from './flagsPng/tf.png'
import GA from './flagsPng/ga.png'
import GM from './flagsPng/gm.png'
import GE from './flagsPng/ge.png'
import DE from './flagsPng/de.png'
import GH from './flagsPng/gh.png'
import GI from './flagsPng/gi.png'
import GR from './flagsPng/gr.png'
import GL from './flagsPng/gl.png'
import GD from './flagsPng/gd.png'
import GP from './flagsPng/gp.png'
import GU from './flagsPng/gu.png'
import GT from './flagsPng/gt.png'
import GG from './flagsPng/gg.png'
import GN from './flagsPng/gn.png'
import GW from './flagsPng/gw.png'
import GY from './flagsPng/gy.png'
import HT from './flagsPng/ht.png'
import HM from './flagsPng/hm.png'
import VA from './flagsPng/va.png'
import HN from './flagsPng/hn.png'
import HK from './flagsPng/hk.png'
import HU from './flagsPng/hu.png'
import IS from './flagsPng/is.png'
import IN from './flagsPng/in.png'
import ID from './flagsPng/id.png'
import CI from './flagsPng/ci.png'
import IR from './flagsPng/ir.png'
import IQ from './flagsPng/iq.png'
import IE from './flagsPng/ie.png'
import IM from './flagsPng/im.png'
import IL from './flagsPng/il.png'
import IT from './flagsPng/it.png'
import JM from './flagsPng/jm.png'
import JP from './flagsPng/jp.png'
import JE from './flagsPng/je.png'
import JO from './flagsPng/jo.png'
import KZ from './flagsPng/kz.png'
import KE from './flagsPng/ke.png'
import KI from './flagsPng/ki.png'
import KW from './flagsPng/kw.png'
import KG from './flagsPng/kg.png'
import LA from './flagsPng/la.png'
import LV from './flagsPng/lv.png'
import LB from './flagsPng/lb.png'
import LS from './flagsPng/ls.png'
import LR from './flagsPng/lr.png'
import LY from './flagsPng/ly.png'
import LI from './flagsPng/li.png'
import LT from './flagsPng/lt.png'
import LU from './flagsPng/lu.png'
import MO from './flagsPng/mo.png'
import MK from './flagsPng/mk.png'
import MG from './flagsPng/mg.png'
import MW from './flagsPng/mw.png'
import MY from './flagsPng/my.png'
import MV from './flagsPng/mv.png'
import ML from './flagsPng/ml.png'
import MT from './flagsPng/mt.png'
import MH from './flagsPng/mh.png'
import MQ from './flagsPng/mq.png'
import MR from './flagsPng/mr.png'
import MU from './flagsPng/mu.png'
import YT from './flagsPng/yt.png'
import MX from './flagsPng/mx.png'
import FM from './flagsPng/fm.png'
import MD from './flagsPng/md.png'
import MC from './flagsPng/mc.png'
import MN from './flagsPng/mn.png'
import ME from './flagsPng/me.png'
import MS from './flagsPng/ms.png'
import MA from './flagsPng/ma.png'
import MZ from './flagsPng/mz.png'
import MM from './flagsPng/mm.png'
import NA from './flagsPng/na.png'
import NR from './flagsPng/nr.png'
import NP from './flagsPng/np.png'
import NL from './flagsPng/nl.png'
import NC from './flagsPng/nc.png'
import NZ from './flagsPng/nz.png'
import NI from './flagsPng/ni.png'
import NE from './flagsPng/ne.png'
import NG from './flagsPng/ng.png'
import NU from './flagsPng/nu.png'
import NF from './flagsPng/nf.png'
import KP from './flagsPng/kp.png'
import MP from './flagsPng/mp.png'
import NO from './flagsPng/no.png'
import OM from './flagsPng/om.png'
import PK from './flagsPng/pk.png'
import PW from './flagsPng/pw.png'
import PS from './flagsPng/ps.png'
import PA from './flagsPng/pa.png'
import PG from './flagsPng/pg.png'
import PY from './flagsPng/py.png'
import PE from './flagsPng/pe.png'
import PH from './flagsPng/ph.png'
import PN from './flagsPng/pn.png'
import PL from './flagsPng/pl.png'
import PT from './flagsPng/pt.png'
import PR from './flagsPng/pr.png'
import QA from './flagsPng/qa.png'
import XK from './flagsPng/xk.png'
import RE from './flagsPng/re.png'
import RO from './flagsPng/ro.png'
import RU from './flagsPng/ru.png'
import RW from './flagsPng/rw.png'
import BL from './flagsPng/bl.png'
import SH from './flagsPng/sh.png'
import KN from './flagsPng/kn.png'
import LC from './flagsPng/lc.png'
import MF from './flagsPng/mf.png'
import PM from './flagsPng/pm.png'
import VC from './flagsPng/vc.png'
import WS from './flagsPng/ws.png'
import SM from './flagsPng/sm.png'
import ST from './flagsPng/st.png'
import SA from './flagsPng/sa.png'
import SN from './flagsPng/sn.png'
import RS from './flagsPng/rs.png'
import SC from './flagsPng/sc.png'
import SL from './flagsPng/sl.png'
import SG from './flagsPng/sg.png'
import SX from './flagsPng/sx.png'
import SK from './flagsPng/sk.png'
import SI from './flagsPng/si.png'
import SB from './flagsPng/sb.png'
import SO from './flagsPng/so.png'
import ZA from './flagsPng/za.png'
import GS from './flagsPng/gs.png'
import KR from './flagsPng/kr.png'
import SS from './flagsPng/ss.png'
import ES from './flagsPng/es.png'
import LK from './flagsPng/lk.png'
import SD from './flagsPng/sd.png'
import SR from './flagsPng/sr.png'
import SJ from './flagsPng/sj.png'
import SZ from './flagsPng/sz.png'
import SE from './flagsPng/se.png'
import CH from './flagsPng/ch.png'
import SY from './flagsPng/sy.png'
import TW from './flagsPng/tw.png'
import TJ from './flagsPng/tj.png'
import TZ from './flagsPng/tz.png'
import TH from './flagsPng/th.png'
import TL from './flagsPng/tl.png'
import TG from './flagsPng/tg.png'
import TK from './flagsPng/tk.png'
import TO from './flagsPng/to.png'
import TT from './flagsPng/tt.png'
import TN from './flagsPng/tn.png'
import TR from './flagsPng/tr.png'
import TM from './flagsPng/tm.png'
import TC from './flagsPng/tc.png'
import TV from './flagsPng/tv.png'
import UG from './flagsPng/ug.png'
import UA from './flagsPng/ua.png'
import AE from './flagsPng/ae.png'
import GB from './flagsPng/gb.png'
import US from './flagsPng/us.png'
import UY from './flagsPng/uy.png'
import UZ from './flagsPng/uz.png'
import VU from './flagsPng/vu.png'
import VE from './flagsPng/ve.png'
import VN from './flagsPng/vn.png'
import WF from './flagsPng/wf.png'
import EH from './flagsPng/eh.png'
import YE from './flagsPng/ye.png'
import ZM from './flagsPng/zm.png'
import ZW from './flagsPng/zw.png'

export const Flags = {
"AF" : AF,
"AX" : AX,
"AL" : AL,
"DZ" : DZ,
"AS" : AS,
"AD" : AD,
"AO" : AO,
"AI" : AI,
"AQ" : AQ,
"AG" : AG,
"AR" : AR,
"AM" : AM,
"AW" : AW,
"AU" : AU,
"AT" : AT,
"AZ" : AZ,
"BS" : BS,
"BH" : BH,
"BD" : BD,
"BB" : BB,
"BY" : BY,
"BE" : BE,
"BZ" : BZ,
"BJ" : BJ,
"BM" : BM,
"BT" : BT,
"BO" : BO,
"BQ" : BQ,
"BA" : BA,
"BW" : BW,
"BV" : BV,
"BR" : BR,
"IO" : IO,
"UM" : UM,
"VG" : VG,
"VI" : VI,
"BN" : BN,
"BG" : BG,
"BF" : BF,
"BI" : BI,
"KH" : KH,
"CM" : CM,
"CA" : CA,
"CV" : CV,
"KY" : KY,
"CF" : CF,
"TD" : TD,
"CL" : CL,
"CN" : CN,
"CX" : CX,
"CC" : CC,
"CO" : CO,
"KM" : KM,
"CG" : CG,
"CD" : CD,
"CK" : CK,
"CR" : CR,
"HR" : HR,
"CU" : CU,
"CW" : CW,
"CY" : CY,
"CZ" : CZ,
"DK" : DK,
"DJ" : DJ,
"DM" : DM,
"DO" : DO,
"EC" : EC,
"EG" : EG,
"SV" : SV,
"GQ" : GQ,
"ER" : ER,
"EE" : EE,
"ET" : ET,
"FK" : FK,
"FO" : FO,
"FJ" : FJ,
"FI" : FI,
"FR" : FR,
"GF" : GF,
"PF" : PF,
"TF" : TF,
"GA" : GA,
"GM" : GM,
"GE" : GE,
"DE" : DE,
"GH" : GH,
"GI" : GI,
"GR" : GR,
"GL" : GL,
"GD" : GD,
"GP" : GP,
"GU" : GU,
"GT" : GT,
"GG" : GG,
"GN" : GN,
"GW" : GW,
"GY" : GY,
"HT" : HT,
"HM" : HM,
"VA" : VA,
"HN" : HN,
"HK" : HK,
"HU" : HU,
"IS" : IS,
"IN" : IN,
"ID" : ID,
"CI" : CI,
"IR" : IR,
"IQ" : IQ,
"IE" : IE,
"IM" : IM,
"IL" : IL,
"IT" : IT,
"JM" : JM,
"JP" : JP,
"JE" : JE,
"JO" : JO,
"KZ" : KZ,
"KE" : KE,
"KI" : KI,
"KW" : KW,
"KG" : KG,
"LA" : LA,
"LV" : LV,
"LB" : LB,
"LS" : LS,
"LR" : LR,
"LY" : LY,
"LI" : LI,
"LT" : LT,
"LU" : LU,
"MO" : MO,
"MK" : MK,
"MG" : MG,
"MW" : MW,
"MY" : MY,
"MV" : MV,
"ML" : ML,
"MT" : MT,
"MH" : MH,
"MQ" : MQ,
"MR" : MR,
"MU" : MU,
"YT" : YT,
"MX" : MX,
"FM" : FM,
"MD" : MD,
"MC" : MC,
"MN" : MN,
"ME" : ME,
"MS" : MS,
"MA" : MA,
"MZ" : MZ,
"MM" : MM,
"NA" : NA,
"NR" : NR,
"NP" : NP,
"NL" : NL,
"NC" : NC,
"NZ" : NZ,
"NI" : NI,
"NE" : NE,
"NG" : NG,
"NU" : NU,
"NF" : NF,
"KP" : KP,
"MP" : MP,
"NO" : NO,
"OM" : OM,
"PK" : PK,
"PW" : PW,
"PS" : PS,
"PA" : PA,
"PG" : PG,
"PY" : PY,
"PE" : PE,
"PH" : PH,
"PN" : PN,
"PL" : PL,
"PT" : PT,
"PR" : PR,
"QA" : QA,
"XK" : XK,
"RE" : RE,
"RO" : RO,
"RU" : RU,
"RW" : RW,
"BL" : BL,
"SH" : SH,
"KN" : KN,
"LC" : LC,
"MF" : MF,
"PM" : PM,
"VC" : VC,
"WS" : WS,
"SM" : SM,
"ST" : ST,
"SA" : SA,
"SN" : SN,
"RS" : RS,
"SC" : SC,
"SL" : SL,
"SG" : SG,
"SX" : SX,
"SK" : SK,
"SI" : SI,
"SB" : SB,
"SO" : SO,
"ZA" : ZA,
"GS" : GS,
"KR" : KR,
"SS" : SS,
"ES" : ES,
"LK" : LK,
"SD" : SD,
"SR" : SR,
"SJ" : SJ,
"SZ" : SZ,
"SE" : SE,
"CH" : CH,
"SY" : SY,
"TW" : TW,
"TJ" : TJ,
"TZ" : TZ,
"TH" : TH,
"TL" : TL,
"TG" : TG,
"TK" : TK,
"TO" : TO,
"TT" : TT,
"TN" : TN,
"TR" : TR,
"TM" : TM,
"TC" : TC,
"TV" : TV,
"UG" : UG,
"UA" : UA,
"AE" : AE,
"GB" : GB,
"US" : US,
"UY" : UY,
"UZ" : UZ,
"VU" : VU,
"VE" : VE,
"VN" : VN,
"WF" : WF,
"EH" : EH,
"YE" : YE,
"ZM" : ZM,
"ZW" : ZW
}

//Deprecated mid function.
const FlagsPng = ({country}) => {
    const F = Flags[country]
    if (F) return F
}

export default Flags