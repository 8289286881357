import { useRef, useEffect } from "react";
import Modal from "../modal/Modal";
import useTranslation from "../../hooks/useTranslation";
import { Button } from "../generic/Core";
import GroupFormElem from "./GroupFormElem";
import FormElem from "./FormElem";
import styled from "styled-components";
import PdfPager from "../pdf/PdfPager";
import ModalList from "../modal/ModalList";

const Spacer = styled.div`
    height: 20px;
`

const ModalForm = ({
    isVisible,
    setIsVisible,
    modalTitle,
    groups,
    lineId,
    validated,
    section,
    mode = '',
    entity,
    useStore
}) => {
    const { t } = useTranslation()
    const [state, setState] = useStore(s => s)

    const focusRef = useRef(null)
    const updateBtnRef = useRef(null)


    useEffect(() => {
        if (focusRef.current) { focusRef.current.focus() }
    }, [])

    //Function that when it detect an enter changes the focus to the close button and clicks on it.
    const handleKeyDown = (e) => {
        //Condition checks if Enter is pressed and if the target is the same as the close Button
        if (e.key === 'Enter' & e.target === updateBtnRef.current) {
            updateBtnRef.current.click()
        }
        if (e.key === 'Escape') {
            setIsVisible(false)
        }
    };

    const handleClick = (e) => {
        e.preventDefault()
        setIsVisible(false)
        setState({ savedOn: new Date() })
    }

    return (
        <Modal setShow={setIsVisible} show={isVisible} mode={mode} className={''} handleKeyDown={handleKeyDown}>
            <Modal.Header setShow={setIsVisible}>
                <h3 style={{ margin: '0px' }}>{t(modalTitle)}</h3>
            </Modal.Header>
            <Modal.Body>
                {
                    groups.map(
                        (item, j) => {
                            return (
                                item.type === 'group' ?
                                    <GroupFormElem item={item} key={`group-${j}`} lineId={lineId} validated={validated} useStore={useStore}
                                        ref={(item.focus) ? focusRef : null} section={section} entity={entity}
                                    />
                                    : item.type === 'pdf' ?
                                        <PdfPager state={state} key={`group-${j}`} entity={entity} />
                                        : item.type === 'list' ?
                                            <ModalList
                                                entity={entity}
                                                validated={validated}
                                                useStore={useStore}
                                                section={item.section}
                                                key={`group-${j}`}
                                            />
                                            : <FormElem item={item} key={`group-${j}`}
                                                validated={validated} lineId={lineId}
                                                ref={(item.focus) ? focusRef : null} section={section} entity={entity} useStore={useStore}
                                            />
                            )
                        }
                    )}

            </Modal.Body>
            <Modal.Footer>
                <Button ref={updateBtnRef} $variant='primary' onClick={handleClick}>{t('all.form.button.update')}</Button>
            </Modal.Footer>
            {(mode === 'fullscreen') && <Spacer></Spacer>}
        </Modal>
    );
};

export default ModalForm;


