import styled from 'styled-components'

const DropDownMenu = styled.div`
    inset: 0px 0px auto auto;
    transform: translate3d(0px, 42.4px, 0px);
    --dropdown-min-width: 10rem;
    --dropdown-padding-x: 0;
    --dropdown-padding-y: 0.5rem;
    --dropdown-spacer: 0.125rem;
    --dropdown-font-size: 1rem;
    --dropdown-bg: #fff;
    --dropdown-border-radius: 0.375rem;
    --dropdown-border-width: 1px;
    --dropdown-inner-border-radius: calc(0.375rem - 1px);
    --dropdown-divider-margin-y: 0.5rem;
    --dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --dropdown-link-color: #212529;
    --dropdown-link-hover-color: #1e2125;
    --dropdown-link-hover-bg: #e9ecef;
    --dropdown-link-active-color: #fff;
    --dropdown-link-active-bg: #0d6efd;
    --dropdown-link-disabled-color: #adb5bd;
    --dropdown-item-padding-x: 1rem;
    --dropdown-item-padding-y: 0.25rem;
    --dropdown-header-color: #6c757d;
    --dropdown-header-padding-x: 1rem;
    --dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index:1000;
    min-width: var(--dropdown-min-width);
    padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
    margin: 0;
    font-size: var(--dropdown-font-size);
    color: ${({ theme }) => theme.font.color};
    text-align: left;
    list-style: none;
    background-color: ${({ theme }) => theme.dropdown.background};
    background-clip: padding-box;
    border: var(--dropdown-border-width) solid ${({ theme }) => theme.dropdown.border};;
    border-radius: var(--dropdown-border-radius);  

`
const DropDownToggle = styled.button`
  cursor: pointer;
  height: 40px;
  color: ${({ theme }) => theme.font.color}; 
  border: 0;
  border-radius: 20px;
  white-space: nowrap;
    --dropdown-padding-x: 0.75rem;
    --dropdown-padding-y: 0.375rem;
    --dropdown-font-size: 1rem;
    --dropdown-font-weight: 400;
    --dropdown-line-height: 1.5;
    --dropdown-color: #212529;
    --dropdown-border-width: 1px;
    --dropdown-border-color: transparent;
    --dropdown-border-radius: 0.375rem;
    --dropdown-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    --dropdown-disabled-opacity: 0.65;
    --dropdown-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
    font-size: var(--dropdown-font-size);
    font-weight: var(--dropdown-font-weight);
    line-height: var(--dropdown-line-height);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: ${({ $bg, theme }) => $bg === 'active'? theme.dropdown.active : 'transparent'};
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:hover {
    border: 0;
    background-color: ${({ theme }) => theme.dropdown.active};
    border-radius: 20px;
  }

  &::after{
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }


`

const DropDownItem = styled.a`
    --dropdown-item-padding-y: 0.25rem;
    --dropdown-item-padding-x: 1rem;
    --dropdown-link-color: #212529;
    --dropdown-link-active-color: #fff;
    --dropdown-link-active-bg: #0d6efd;
    --dropdown-link-hover-color: #1e2125;
    --dropdown-link-hover-bg: #e9ecef;
    --dropdown-link-hover-color: #0d6efd;
    display: block;
    width: 100%;
    padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: ${({ theme }) => theme.font.color};
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:active{
      color: var(--dropdown-link-active-color);
      text-decoration: none;
      background-color: var(--dropdown-link-active-bg);
    }
    &:hover{
      color: ${({ $inactive, theme }) => $inactive ? theme.font.color : theme.dropdown.selectedFont};
      background-color: ${({ $inactive, theme }) => $inactive ? 'transparent' : theme.dropdown.selectedBackground};  
      cursor: ${({ $inactive }) => $inactive ? 'default' : 'pointer'};
    }
    &.selected{
      color: ${({ $inactive, theme }) => $inactive ? theme.font.color : theme.dropdown.selectedFont};
      background-color: ${({ $inactive, theme }) => $inactive ? 'transparent' : theme.dropdown.selectedBackground};  
      cursor: ${({ $inactive }) => $inactive ? 'default' : 'pointer'};
    }
    &.action{
      text-align: end;
      color: ${({ $inactive, theme }) => $inactive ? theme.font.color : theme.dropdown.selectedFont};
    }
`

const DropDownDivider = styled.hr`
     height: 0;
    margin: var(--dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid ${({ theme }) => theme.dropdown.divider};
    opacity: 1; 
`


const DropDown = styled.div`
    position: relative;
    margin: ${({ $margin }) => $margin ? $margin : 0};

      &::before, ::after {
        box-sizing: border-box;
      }

    &:focus-within {
        ${DropDownMenu} {
            display: block;
    } 
  }

    &:focus-visible {
        ${DropDownMenu} {
            display: block;
    }
  }
`

DropDown.Toggle = DropDownToggle
DropDown.Item = DropDownItem
DropDown.Menu = DropDownMenu
DropDown.Divider = DropDownDivider

export default DropDown