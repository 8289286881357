import { useState, useEffect } from 'react';

function useKeyboardFocus() {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    const element = window.document;
    element.addEventListener('focus', handleFocus, true);
    element.addEventListener('blur', handleBlur, true);

    return () => {
      element.removeEventListener('focus', handleFocus, true);
      element.removeEventListener('blur', handleBlur, true);
    };
  }, []);

  return isFocused;
}

export default useKeyboardFocus;
