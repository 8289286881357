import useProfile from "../../hooks/useProfile"
import { useSnapshot } from "valtio"
import useLocale from "../../hooks/useLocale"
import useTranslation from "../../hooks/useTranslation"
import styled from "styled-components"


const StyledSpan = styled.span`
    color: ${({color, theme}) => color === 'true' ? theme.badge.true : theme.badge.false};
    background-color: ${({theme}) => theme.badge.bg};
    padding: 0px 5px;
    border-radius: 10px;
    font-weight: 700;
    font-size: var(--font-XXS);
    font-family: "Prompt", sans-serif;
`

const DisplayElement = ({ string, type, item, col }) => {
    const { pState } = useProfile()
    const profile = useSnapshot(pState)
    const {lf} = useLocale()
    const { t } = useTranslation()
    //const string = lf(item[col.id], 2, 2)
    //const type = col.type

    let result = string

    if (type === 'date' || type === 'niceDate') {
        if (string === undefined) {
            string = new Date().toISOString()
        }
    }

    if (type === 'date') {
        const date = new Date(string)
        result = date.toLocaleDateString(profile.currentOrgLanguage)

    }

    if (type === 'niceDate') {
        //Formats in a nice humar readable Date like : 23. januar 2024
        const date = new Date(string)
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat(profile.currentOrgLanguage, options).format(date);       
        result = formattedDate
    }   

    if (type === 'amount') {
        result = lf(string, 2, 2)
    }

    if (type === 'boolean') {
        if (item[col.id] === true) {
        result = t(col.values[0])
    } else {
            result = t(col.values[1])
        }
    }

    return (
        <>
        { type === 'boolean' && <StyledSpan color={item[col.id] ? 'true' : 'false'} className="prompt">{result}</StyledSpan>}
        { type !== 'boolean' && <span>{result}</span>}
        </>
    )

}

export default DisplayElement

