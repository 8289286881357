import { forwardRef, useRef } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormGroup } from "../form/FormComponents"

//Filed Level Components
import CountrySelect from '../country/CountrySelect'
import Select from "../select/Select"
import Connect from "../select/Connect"
import DualConnect from "../select/DualConnect"
import DataList from "../dataList/DataList"
import DateInput from "../calendar/DateInput"
import NumericInput from "../numeric/NumericInput"
import NSerieInput from "../nSerie/NSerieInput"
import { Separator } from "../generic/Core"
import Upload from "../upload/Upload"
import GroupFormElem from "./GroupFormElem"

import { TextLine } from "../generic/Layout"
import SwitchButton from "../generic/SwitchButton"
import runFieldsLogic from "../../../../common/logic/runFieldsLogic"
import extractNames from "../../utils/msg/extractNames"

const FormElem = forwardRef(({ item, lineId, validated, section, entity, useStore }, ref) => {
    const { t } = useTranslation()

    const sectionName = section?.meta?.name
    const linesArray = section?.meta?.linesArray
    const subObject = item.subObject

    //Setting up all variables
    let fieldValue, fieldDisplay, setState, localState, lineFieldValue, linkedFields, fieldSubDisplay, fieldId, fieldCondition, showCondition

    //Setting up the main state for NON Lines.
    if (lineId === undefined) {
        if (subObject) {
            [fieldValue, setState] = useStore((s) => s[subObject]?.[item.id])
        } else {
            [fieldValue, setState] = useStore((s) => s[item.id])
        }
    }

    //Conditional State depending on type of element
    //If the element has a display value retrieve the state for that
    if (item.display && lineId === undefined) {
        if (subObject) {
            [fieldDisplay] = useStore((s) => s[subObject]?.[item.display])
        } else {
            [fieldDisplay] = useStore((s) => s[item.display])
        }
    }
    if (item.subDisplay && lineId === undefined) [fieldSubDisplay] = useStore((s) => s[item.subDisplay])
    if (item.valueId && lineId === undefined) [fieldId] = useStore((s) => s[item.valueId])
    if (item.showCondition && lineId === undefined) [fieldCondition] = useStore((s) => s[item.showCondition.field])

    //if the element has a Line retrieve the Line Value and a corresponding diplay valus if the field has that.
    if (lineId !== undefined) {
        if (subObject) {
            [lineFieldValue, setState] = useStore((s) => s[linesArray][lineId][subObject]?.[item.id])
            if (item.display) [fieldDisplay] = useStore((s) => s[linesArray][lineId][subObject]?.[item.display])
        } else {
            [lineFieldValue, setState] = useStore((s) => s[linesArray][lineId][item.id])
            if (item.display) [fieldDisplay] = useStore((s) => s[linesArray][lineId][item.display])
        }
    }

    if (item.linkedFields) {
        linkedFields = {};
        // Create a loop through item.linkedFields
        item.linkedFields.forEach(f => {
            const [v] = (useStore(s => s[f]));
            linkedFields[f] = v
        });
    }

    //Managing the state
    if (lineId !== undefined) {
        localState = lineFieldValue
    } else {
        localState = fieldValue
    }

    const handleInput = async (msg) => {
        if (msg === null) return;
        let stateValue = {}

        // Check if msg is already in the correct format (from Connect component)
        if (msg[subObject]) {
            stateValue = msg
        } else if (Array.isArray(msg)) {
            msg.forEach(
                (elem) => {
                    const target = elem.target
                    const name = target.name
                    const value = target.value
                    //Transforms strings in numbers
                    stateValue = { ...stateValue, ...{ [name]: value } }
                })
        } else if (msg.target) {
            const target = msg.target
            const name = target.name
            const value = target.value
            //Transforms strings in numbers
            stateValue = { [name]: value }
        } else {
            // Direct value object
            stateValue = msg
        }
        
        if (lineId !== undefined) {
            //Transform the State into an array with [subObj, LineId, LineValue] 
            if (subObject && !stateValue[subObject]) {
                stateValue = { [subObject]: stateValue }
            }
            setState(stateValue, { array: linesArray, itemId: lineId })
            return
        } else {
            if (item.linkedFields) {
                const fields = extractNames(msg)
                let prevData = { ...linkedFields }
                let data = { ...linkedFields, ...stateValue }
                const processedValues = await runFieldsLogic(data, entity, fields, prevData)
                stateValue = processedValues
            }
            if (subObject && !stateValue[subObject]) {
                stateValue = { [subObject]: stateValue }
            }
            setState(stateValue)
        }
    }

    //Managing the Autocomplete Value
    let autoCompleteValue = `${sectionName}-${item.id}`
    item.autoComplete ? autoCompleteValue = item.autoComplete : autoCompleteValue = `${sectionName}-${item.id}`

    return (
        <FormGroup name='FormGroup' $ctrlid={item.id} $margin={item.fgMargin} key={item.id} >
            {/*  Label  */}
            {item.label ? <FormGroup.Label>{t(item.label)}</FormGroup.Label> : <></>}

            {/*  Regular Text Input Field  */}
            {item.id && item.type === 'text' ? <FormGroup.Control type={item.type} name={item.id}
                value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)}
                autoComplete={autoCompleteValue}
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Regular Text Input Field  */}
            {item.id && item.type === 'code' ? <FormGroup.Control type={item.type} name={item.id}
                value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)}
                autoComplete={autoCompleteValue} maxLength="3"
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Email Field  */}
            {item.id && item.type === 'email' ? <FormGroup.Control type={item.type} name={item.id}
                value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)}
                inputMode='email'
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  URL Field  */}
            {item.id && item.type === 'url' ? <FormGroup.Control type={item.type} name={item.id}
                value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)}
                inputMode='url'
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  search  */}
            {item.id && item.type === 'search' ? <FormGroup.Control type={item.type} name={item.id}
                value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)}
                inputMode='search' autoComplete={autoCompleteValue}
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  phone  */}
            {item.id && item.type === 'tel' ? <FormGroup.Control type={item.type} name={item.id} value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)} inputMode='tel'
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*   Text Area Input Field  */}
            {item.id && item.type === 'textarea' ? <FormGroup.TextArea
                type={item.type} $rows={item.rows}
                name={item.id} value={localState || ''} onChange={handleInput}
                placeholder={t(item.placeholder)} autoComplete={autoCompleteValue}
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Number Input Field  */}
            {item.id && item.type === 'number' ? <NumericInput type={item.type} $variant={item.variant} name={item.id} value={localState || ''} setValue={handleInput} placeholder={t(item.placeholder)} decimals={item.decimals}
                {...((validated && item.isRequired) ? { isRed: true } : { isRed: false })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Number Serie Input Field  */}
            {item.id && item.type === 'nserie' ? <NSerieInput item={item} name={item.id} value={localState || ''} setValue={handleInput} placeholder={t(item.placeholder)} entity={item.entity}
                {...((validated && item.isRequired) ? { isRed: true } : { isRed: false })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Password Input Field  */}
            {item.id && item.type === 'password' ? <FormGroup.Control type={item.type} name={item.id} value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)}
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Read-Only Text Input Field  */}
            {item.id && item.type === 'text-readOnly' ? <FormGroup.Control type='text' name={item.id} value={localState || ''} onChange={handleInput} placeholder={t(item.placeholder)} className={'readonly'} readOnly={true}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Country Selector  */}
            {item.id && item.type === 'country' ? <CountrySelect name={item.id} valueName={fieldDisplay || ''} valueId={localState || ''} valueMap={item.valueMap} setValue={handleInput}
                {...((validated && item.isRequired) ? { isRed: true } : { isRed: false })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Generic Select Control  */}
            {item.id && item.type === 'select' ? <Select entity={item.entity} name={item.id}
                valueName={fieldDisplay || ''} valueMap={item.valueMap} setValue={handleInput}
                options={item.options}
                {...((validated && item.isRequired) ? { isRed: true } : { isRed: false })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Data List Control  */}
            {item.id && item.type === 'dataList' ? <DataList entity={item.entity} name={item.id}
                valueName={localState || ''} valueMap={item.valueMap} setValue={handleInput}
                options={item.options} modalTitle={item.modalTitle} modalOpacity={item.modalOpacity}
                {...((validated && item.isRequired) ? { isRed: true } : { isRed: false })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Generic Connect Control  */}
            {item.id && item.type === 'connect' ? <Connect entity={item.entity} name={item.id}
                valueName={fieldDisplay || ''} valueMap={item.valueMap} setValue={handleInput}
                options={item.options} modalTitle={item.modalTitle} placeholder={item.placeholder}
                menuItemField={item.menuItemField || 'name'} modalType={item.modalType || 'searchList'}
                radioGroups={item.radioGroups || []} subObject={subObject} persistValue={item.persistValue || false}
                {...((validated && item.isRequired) ? { isRed: true } : { isRed: false })}
                {...(ref && { ref })}
            /> : <></>}

            {/*  DualConnect Control  */}
            {item.id && item.type === 'dualconnect' ? <DualConnect
                mainEntity={item.mainEntity}
                subEntity={item.subEntity}
                mainValueName={fieldDisplay || ''}
                mainValueId={fieldId || ''}
                subValueName={fieldSubDisplay || ''}
                mainValueMap={item.mainValueMap}
                subValueMap={item.subValueMap}
                setMainValue={handleInput}
                setSubValue={handleInput}
                options={item.options}
                isRed={validated && item.isRequired}
                mainModalTitle={item.mainModalTitle}
                subModalTitle={item.subModalTitle}
                mainPlaceholder={t(item.mainPlaceholder)}
                subPlaceholder={t(item.subPlaceholder)}
                showCondition={fieldCondition == item.showCondition.value}
                menuItemField={item.menuItemField || 'name'}
                persistMainValue={item.persistMainValue || false} 
                persistSubValue={item.persistSubValue || false}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Date Control  */}
            {item.id && item.type === 'date' ? <DateInput type={item.type} name={item.id} value={localState || ''} setValue={handleInput} placeholder={t(item.placeholder)}
                {...(validated && item.isRequired && { required: true })}
                {...(ref && { ref })} align={item.align || 'right'}
            /> : <></>}

            {/*  Boolean Switch Control  */}
            {item.id && item.type === 'switch' ? <SwitchButton name={item.id} value={localState || ''} setValue={handleInput} switchText={t(item.switchText)}
                {...(ref && { ref })}
            /> : <></>}

            {/*  Text Line  */}
            {item.type === 'description' ? <TextLine $mt='0rem' $mb='0.75rem' name={item.id}
                {...(ref && { ref })}
            >{t(item.text)}</TextLine> : <></>}

            {/*  separator  */}
            {item.type === 'separator' ? <Separator /> : <></>}

            {/*  Upload  */}
            {item.type === 'upload' ? <Upload value={localState || ''} onChange={handleInput}
                dataItems={{ ...item }}
            /> : <></>}

            {/*  PDF  */}
            {item.type === 'pdf' ? <PdfPager fData={fieldValue} fState={fieldValue}
            /> : <></>}

            {/*  Group  */}
            {item.type === 'group' ? <GroupFormElem item={item} lineId={lineId} validated={validated} section={section} entity={entity} useStore={useStore} ref={ref} /> : <></>}

        </FormGroup>
    )
})

export default FormElem
