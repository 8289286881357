import styled from "styled-components"
import CardViewFormLines from "../cardView/CardViewFormLines"
import useTranslation from "../../hooks/useTranslation"

import useLocale from "../../hooks/useLocale"
import sumFields from "../../utils/sumFields"

import { Tag } from "../generic/Core"

const Backgrounder = styled.div`
    background-color: ${({ theme }) => theme.form.panelBg};
    padding: 1rem;
    margin-inline: -1rem;
`
const Description = styled.p`
    margin-top: 0;
`
const Footer = styled.p`
    font-weight: 600;
`

const Error = styled.p`
    font-weight: 600;
    color: red;
`

const ModalList = ({ entity, validated, useStore, section }) => {
    const { m } = useTranslation()
    const { lf } = useLocale()
    const [state, setState] = useStore((s) => s)
    const list = state['payments']
    const totalAmount = sumFields(list, 'paymentAmount')
    const isError = (totalAmount !== state['totalGrossSalesAmount'])

    return (
        <>
            <Description> {m('Your Invoice will be paid in ${1} installments:', [state['numPayments']])} </Description>
            <Backgrounder>
                <CardViewFormLines
                    expanded={false}
                    toggleExpanded={() => { }}
                    section={section}
                    linesArray={section.meta.linesArray}
                    entity={entity}
                    key={`${section.meta.name}`}
                    dispatch={() => { }}
                    validated={validated}
                    useStore={useStore}
                />
            </Backgrounder>
            <Footer>Total Amount Due {lf(state['totalGrossSalesAmount'], 2, 2)} {state['currency']}</Footer>
            {
                (isError) &&
                (<>
                    <Error><Tag $variant='danger' $margin={'0 0.5rem 0 0'}>ERROR</Tag> Amount is: {lf(totalAmount, 2, 2)} {state['currency']}</Error>
                </>)
            }
        </>
    )
}

export default ModalList