import { checkPrevValue } from "./checkCondition.js"

function calculateAmounts(amount, installments) {
  if (installments <= 0) {
    throw new Error("Installments must be a positive integer");
  }

  const baseInstallment = Math.round(amount / installments);
  let remainingAmount = amount;
  const result = [];

  for (let i = 0; i < installments - 1; i++) {
    result.push(baseInstallment);
    remainingAmount -= baseInstallment;
  }

  // The last installment is the remaining amount
  result.push(remainingAmount);

  return result;
}

function calculatePaymentDates(date, daysToFirstPayment, installments, intervalDays) {
  if (installments < 1) {
    throw new Error("There must be at least one installment.");
  }
  if (intervalDays < 1) {
    throw new Error("Interval days must be a positive integer.");
  }

  const initialDate = new Date(date);
  const paymentDates = [];

  // Calculate the first payment date
  let paymentDate = new Date(initialDate);
  paymentDate.setDate(paymentDate.getDate() + daysToFirstPayment);
  paymentDates.push(paymentDate.toISOString());

  // Calculate the subsequent payment dates
  for (let i = 1; i < installments; i++) {
    paymentDate = new Date(paymentDate);
    paymentDate.setDate(paymentDate.getDate() + intervalDays);
    paymentDates.push(paymentDate.toISOString());
  }

  return paymentDates;
}


function calculatePayments(amounts, paymentDates, transferMap) {
  if (amounts.length !== paymentDates.length) {
    throw new Error("Amounts and payment dates arrays must have the same length.");
  }

  const result = [];

  // Extract keys from transferMap
  const dateKey = transferMap['paymentDate'];
  const amountKey = transferMap['totalGrossSalesAmount'];

  for (let i = 0; i < amounts.length; i++) {
    const paymentObj = {
      [dateKey]: paymentDates[i],
      [amountKey]: amounts[i],
    };
    result.push(paymentObj);
  }

  return result;
}


export const createSinglePayment = (data, subEntity, transferMap) => {
  data[subEntity] = []
  let subEntityItem = {}

  // Iterate over the keys of transferMap
  for (const key in transferMap) {
    if (transferMap.hasOwnProperty(key)) { // Ensure it's an own property
      subEntityItem[transferMap[key]] = data[key];
    }
  }

  subEntityItem['paymentId'] = `${data.id}.1`

  data[subEntity].push(subEntityItem)
  data['numPayments'] = 1

  return data
}

const generatePaymentSchedule = (data, transferMap) => {
  //Setup the variables to use
  const daysToFirstPayment = data['paymentTermDays'] || 1
  const installments = data['paymentInstallments'] || 1
  const intervalDays = data['paymentIntervalDays'] || 30

  //Calculate 2 arrays with correct amounts and dates
  const amounts = calculateAmounts(data['totalGrossSalesAmount'], installments)
  const paymentDates = calculatePaymentDates(data['date'], daysToFirstPayment, installments, intervalDays);

  //Combine Amount and Dates using the transferMap
  const cPayments = calculatePayments(amounts, paymentDates, transferMap)

  //AddPayments Id to payments
  let payments = []

  for (let i = 0; i < cPayments.length; i++) {
    const payment = { ...cPayments[i] };
    //PaymentId will be invoice id + . tranche
    payment.paymentId = `${data.id}.${i + 1}`
    payments.push(payment)
  }

  //Returning Payments and Installments
  return { payments, installments }
}

const subCalcPayments = (data, operation, prevData) => {
  const { conditionField, subEntity, transferMap } = operation
  let result

  const isTrue = checkPrevValue(data, prevData, conditionField)

  if (isTrue) {
    if (data['totalGrossSalesAmount'] === undefined) data['totalGrossSalesAmount'] = 0

    if (data['paymentSchedule']) {
      //This generates the Payment Schedule
      const { payments, installments } = generatePaymentSchedule(data, transferMap)
      //This adds the payments and the numPayments to the state
      data[subEntity] = payments
      data['numPayments'] = installments
      //This copies the result to the returned result variable 
      result = { ...data }

    } else {
      result = createSinglePayment(data, subEntity, transferMap)
    }
  }

  return result
}

export default subCalcPayments
