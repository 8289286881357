import { useEffect, useState } from "react";
import MultiView from "../../multiView/MultiView"
import Loader from "../../generic/Loader";
import CardView from "../../cardView/CardView";
import SwitchView from "./SwitchView" 

import { useStore } from "../Page";


// FUnction that returns the filterValue in the filter array based on filterKey and filterValue
const applyFilter = (filterKey, filterValue, filterArray) => {
  const matchedFilter = filterArray.find(f => f.filterValue === filterValue);
  return matchedFilter ? matchedFilter : "empty";
};

let matchedFilter = 'empty'

const FilteredView = ({ options, dispatch, config }) => {

  const { filter } = config
  const [filtered] = useStore((s) => s[filter.filterKey])

  //Finds out which view to show based on filter and filerArray
  const [filterApplied, setFilterApplied] = useState(false)

  useEffect(
    () => {
      //Check if the filter has already being applied, if not it applies the filter and uses the result
      //to Load the correct Multiview.
      if (!filterApplied) {
        matchedFilter = applyFilter(filter.filterKey, filtered, filter.filterArray)
        setFilterApplied(true)
      }
    }, [filtered !== undefined]
  )

  //console.log( '[FilteredView] FilterResult: ', filterResult)
  //console.log('Re-rendering Filtered View')
  return (
    <>

      {filterApplied && filter.viewType === 'multiView' && matchedFilter !== 'empty' && <MultiView options={options}
        type={matchedFilter.filterResult} dispatch={dispatch} />}

      {filterApplied && filter.viewType === 'simpleCard' && matchedFilter !== 'empty' && <CardView type={matchedFilter.filterResult} options={options}  />}

      {filterApplied && filter.viewType === 'switchView' && matchedFilter !== 'empty' && <SwitchView type={matchedFilter.filterResult} options={options} view={matchedFilter.filterView} />}

      {filterApplied && matchedFilter === 'empty' && <Loader></Loader>}

    </>
  )
}

export default FilteredView