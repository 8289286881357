import { Button } from "../generic/Core"
import { FlexBox } from "../generic/Layout"
import styled from "styled-components"
import useTranslation from "../../hooks/useTranslation"
import { InfoMark } from "../alert/AlertIcons"
import useProfile from "../../hooks/useProfile"

const P = styled.p`
    margin: 0;
    padding: 0.375rem 0.75rem 0.375rem 0.25rem;
    &#NewVersionPrompt{
        font-size: var('--Font-S');
        color: black;
    }
`

const IconWrapper = styled.div`
    color: black;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
`

const Reload = () => {
    const { t } = useTranslation()
    const { pState } = useProfile()

    const ReloadApp = () => {
        localStorage.removeItem('version');
        pState.newVersion = false;
        location.reload();
    }

    return (
        <FlexBox style={{
            justifyContent: 'right',
            alignContent: 'center',
            flexWrap: 'wrap',
            height: '100%',
            marginRight: '1rem'
        }}>
            {<IconWrapper>{InfoMark}</IconWrapper>}
            <P id="NewVersionPrompt">
                {t('new.version')}
            </P>
            <Button onClick={ReloadApp}>Reload</Button>
        </FlexBox>
    )
}

export default Reload
