function calcMax(num) {
    // Check if the input is a positive integer
    if (Number.isInteger(num) && num > 0) {
        // Create a string with num 9s and convert it to an integer
        const result = parseInt('9'.repeat(num));
        return result;
    } else {
        // Handle invalid input
        //console.error("Invalid input. Please provide a positive integer.");
        return null;
    }
}

export function incrementEndingDigits(inputString) {
    // Use regular expression to match the trailing digits
    const regex = /(\d+)$/;
    const match = inputString.match(regex);
  
    if (match) {
      const digits = match[0];
      const incrementedDigits = (parseInt(digits) + 1).toString().padStart(digits.length, '0');
      const prefix = inputString.slice(0, match.index);
      return prefix + incrementedDigits;
    } else {
      // If no digits found at the end, return the original string
      return inputString;
    }
  }

function analyzeSerie(inputString, entity) {
    // Define regular expressions for matching patterns
    const numericPattern = /\d+$/;
    const textPattern = /-INV-/;
    const yearPattern = /(200[1-9]|20[1-9]\d|2[1-9]\d{2}|[3-9]\d{3})/;

    let baseSerie
    let yearBased = false
    let valid = false

    // Extract numeric values at the end of the string
    const numericMatch = inputString.match(numericPattern);
    const nDigits = numericMatch ? numericMatch[0].length : 0;
    if (nDigits > 2) { valid = true }
    baseSerie = inputString.replace(numericPattern, '{N}')

    const endNum = calcMax(nDigits)
    // Check if the text pattern exists in the string
    const textExists = textPattern.test(inputString);

    // Extract the year number from the string
    const yearMatch = baseSerie.match(yearPattern);
    if (yearMatch !== null) {
        baseSerie = baseSerie.replace(yearPattern, '{Y}')
        yearBased = true
    }

    // Extract the starting number (if numeric values are present)
    const startNum = numericMatch ? parseInt(numericMatch[0]) : null;

    // Create and return the result object
    const result = {
        id: `${entity}.${baseSerie}.${nDigits}`,
        uniqueNo : `${entity}.${inputString}`,
        entity: entity,
        baseSerie,
        nDigits: nDigits,
        startNum,
        endNum,
        nextValue:inputString,
        yearBased,
        valid
    };

    return result;
}

export default analyzeSerie