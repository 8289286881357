import styled from "styled-components"

export const Tag = styled.div`
  position: absolute;
  top: 7px;
  right: 3.5rem;
  font-size: 0.75rem;
  background-color: ${({ theme }) => theme.btn.primary};
  color:white;
  border-color: ${({ theme }) => theme.btn.primary};
  padding: 3px 8px;
  border-radius: 5px;

  &:hover{
    cursor: pointer;
  }
`

export const Selector = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.dropdown.background};
  border: 1px solid ${({ theme }) => theme.area.border};
  top: 40px;
  border-radius: 5px;
  width: 100%;
  z-index: 100;
`
export const SelectorOptions = styled.div`
  padding-inline: 0.5rem;
  font-size: 1rem;
  &:hover {
    color: ${({ theme }) => theme.dropdown.selectedFont};
    background-color: ${({ theme }) => theme.dropdown.selectedBackground};
  }
`

