import { useState, useRef, useEffect } from 'react'
import * as HiIcons from 'react-icons/hi'
import styled from 'styled-components'
import useTranslation from '../../hooks/useTranslation'
import { TextControl } from './Core'
import { AppIconColor } from '../../styles/IconStyle'

const SearchInputWrapper = styled.div`
    display: flex;
    height: 42px;
    -webkit-box-align: center;
    align-items: center;
    
    @media (max-width: 560px) {
        margin-left: 0.25rem;
    }
`

const SButton = styled.button`
    display: inline-flex;
    width: auto;
    max-width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    white-space: nowrap;
    font-family: "Euclid Circular B", sans-serif;
    cursor: pointer;
    transition-property: background-color, color, border, box-shadow, transform;
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
    border-radius: 4px;
    min-width: 40px;
    height: 40px;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 24px;
    border: none;
    background-color: transparent;
    color: ${({theme}) => theme.font.color}; ;
    font-weight: 400;
    &:hover{
        background-color: rgba(167, 169, 180, 0.25); 
    }
    &:active{
        background-color: ${ ({theme}) => theme.form.background}
    }
`

const IconWrapper = styled.figure`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0rem 0.5rem 0 0;
    `

const SearchIcon = styled(HiIcons.HiSearch)`
    max-width: 1.5rem;
    display: inline;
    margin-right: 0rem;
    margin-top: 0rem;
    padding: 0;
    &:hover{
        opacity: 80%;
        cursor: pointer;
    }
`

export const SearchButton = ({ searchTerm, setSearchTerm, searchLabel, searchPlaceholder }) => {
    const [active, setActive] = useState(false)
    const searchFieldRef = useRef()

    const {t} = useTranslation()

    function handleSearch(event) {
        event.preventDefault();
        const { value } = event.target;
        setSearchTerm(value);
    }

    //If state is active, search input Field is visible so set the focus to it.
    useEffect(() => {
        if (active) { searchFieldRef.current.focus() }
    }, [active])

    return (
        <AppIconColor >
            <SearchInputWrapper name='searchInputWrapper'>
                {!active &&
                    <SButton onClick={(e) => {
                        setActive(true)
                    }}>
                        <IconWrapper>
                            <SearchIcon />
                        </IconWrapper>
                        {t(searchPlaceholder)}
                    </SButton>}
                {active ? <TextControl ref={searchFieldRef} type='text' name='SearchButton' value={searchTerm || ''} onBlur={(e) => { setActive(false) }} onChange={handleSearch} placeholder={t(searchLabel)} /> : <></>}
            </SearchInputWrapper>
        </AppIconColor>
    )
}