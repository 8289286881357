//This function takes a pathname and returns the first slug

function extractSlug(pathname) {
    if (pathname === '/' || pathname ==='/login' || pathname === '/register' || pathname === '/loggedout'  ) {
        return 'entry'
    } else if ( pathname === '/org') { return 'org'} else {
        const slugs = pathname.match(/[^\/]+/g)
        return slugs[0]  
     }
}

export default extractSlug;