import { useState } from "react"
import styled from "styled-components"
import { Button } from "../generic/Core"
import ModalForm from "../cardView/ModalForm"
import { useStore } from "../pageView/Page"

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
`

const MobileFormSection = styled.div`
    position: relative;
    max-width: ${({ width }) => width};
    padding:  1rem;
    border: 1px solid ${({ theme }) => theme.form.border};
    border-radius: 1rem;
    margin-bottom: 1rem;
    background: ${({ theme }) => theme.form.background};
    min-height: 3rem;    
    box-shadow: ${({ theme }) => theme.card.boxShadow};

    &:hover {
        border: 1px solid ${({ theme }) => theme.form.activeBorder};
    }
    
    @media (min-width: ${({ theme }) => theme.breakpoint.s}) {
        display: none;
    }

`




const MobileActionButton = ({ config, validated }) => {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(true)
    }

    return (
        <MobileFormSection name="mobileActionButton">
            <ButtonWrapper>
                <Button width={'100%'} $variant='primary' onClick={openModal}>{config.title}</Button>
            </ButtonWrapper>
            {showModal && <ModalForm
                isVisible={showModal}
                setIsVisible={setShowModal}
                modalTitle={'Options'}
                groups={config.modalGroups}
                //validated={validated}
                sectionName={'TBD'}
                mode='fullscreen'
                entity={config.entity}
                useStore={useStore}
            >
            </ModalForm>}

        </MobileFormSection>
    )

}

export default MobileActionButton