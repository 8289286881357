import filterObject from "../../utils/objects/filterObject";
import { settingsData } from "../../model/components/settingsData";

const processSettings = (settingType, settingObject) => {

    const settingKeys = settingsData[settingType]

    const processedSettings = filterObject(settingObject, settingKeys)

    return processedSettings

}

const transferSettings = (originalObject, settingsObject, settingType) => {
    try {
        // Read the config JSON file
        const config = settingsData[settingType]
        const enrichedData = { ...originalObject }

        // Transfer information for each section
        for (const sectionKey in config) {
            if (sectionKey !== 'root') {
                const section = config[sectionKey];
                if (Array.isArray(section)) {
                    section.forEach(key => {
                        if (settingsObject[key]) {
                            enrichedData[sectionKey] = enrichedData[sectionKey] || {};
                            enrichedData[sectionKey][key] = JSON.parse(JSON.stringify(settingsObject[key]));
                        }
                    });
                }
            }
        }

        // Transfer root information
        const root = config.root || {};
        const rootKeys = Object.keys(root);

        rootKeys.forEach(key => {
            const invoiceKey = root[key];
            if (!enrichedData.hasOwnProperty(invoiceKey) && settingsObject[key]) {
                enrichedData[invoiceKey] = settingsObject[key];
            }
        });

        return enrichedData;
    } catch (error) {
        console.error('Error transferring information to invoice:', error);
        throw error;
    }
}


export default transferSettings


