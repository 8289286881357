const transitionLogicData = {
    invoiceToCreditNote: [
        // Does some change of some sort 
        {
            operationType: 'changeValues',
            fields: [
                {
                    targetField: 'entity',
                    fromValue: 'invoice',
                    toValue: 'creditNote'
                },
                {
                    targetField: 'date',
                    toValue: '{today}'
                },
                {
                    targetField: 'paymentDate',
                    toValue: '{today}'
                },
                {
                    targetField: 'linkedDocumentId',
                    toValue: 'id'
                },
                {
                    targetField: 'linkedDocumentNumber',
                    toValue: 'bookedNumber'
                },
                {
                    targetField: 'state',
                    fromValue: 'final',
                    toValue: 'draft'
                },
                {
                    targetField: 'terms',
                    toValue: ''
                },
                {
                    targetField: 'number',
                    toValue: 'settings/nSeries/creditNote/nextValue'
                },
                {
                    targetField: 'bookedNumber',
                    toValue: 'DRAFT'
                },
                {
                    targetField: 'nSerie',
                    toValue: 'settings/nSeries/creditNote/id'
                },
                {
                    targetField: 'id',
                    toValue: false
                }
            ]
        },
        {
            operationType: 'deleteFields',
            fields: ['payments', 'paymentType', 'numPayments', 'paymentSchedule', 'paymentIntervalDays', 'paymentTermDays', 'paymentTermId', 'paymentTermName']
        },
        {
            operationType: 'reduceArray',
            sourceArray:'linkedDocuments',
            sourceSubArray:'lines', 
            targetArray:'lines',
            targetFields: ['itemQuantity'],
            targetRemoveFields: ['netSalesAmount','taxAmount']
        }
    ],
    contactToInvoice: [
        {
            operationType: 'addFields',
            fields: [
                {
                    targetField: 'customerId',
                    sourceField: 'id'
                },
                {
                    targetField: 'customerName',
                    sourceField: 'name'
                },
                {
                    targetField: 'companyTypeId',
                    sourceField: 'companyTypeId'
                },
                {
                    targetField: 'customerEmail',
                    sourceField: 'email'
                },
                {
                    targetField: 'customerAddress_city',
                    sourceField: 'address_city'
                },
                {
                    targetField: 'customerAddress_street',
                    sourceField: 'address_street'
                },
                {
                    targetField: 'customerAddress_postCode',
                    sourceField: 'address_postCode'
                },
                {
                    targetField: 'customerCountry',
                    sourceField: 'country'
                },
                {
                    targetField: 'customerCountryName',
                    sourceField: 'countryName'
                },
                {
                    targetField: 'savedOn',
                    toValue: '{today}'
                }
            ]
        }
    ]
}

export default transitionLogicData