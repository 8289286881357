export const sidePanelData = {
  invoiceEditPanel: {
    entity: 'invoice',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "invoicePDF",
          width: "600px",
        }
      },
      {
        meta: {
          type: "form",
          name: "invoiceOptions",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            control: {
              id: "optionsModal",
              type: "text",
              label: "invoice.form.options",
              placeholder: "invoice.form.options.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "invoice.form.currency.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "currency",
              },
              {
                type: "text",
                text: " - ",
              },
              {
                type: "label",
                text: "invoice.form.language.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "language",
              },

            ],
            modalGroups: 'invoiceOptions'
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "invoice.form.number.missing.message",
          emptyField: "currency",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "invoice.form.options",
                },
              ],
            },
          ],
        },
      },
      {
        meta: {
          type: "form",
          name: "invoiceDesign",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            label: "This is my Modal",
            control: {
              id: "designModal",
              type: "text",
              label: "invoice.form.design",
              placeholder: "invoice.form.design.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "invoice.form.font.short",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "invoiceFont",
              }
            ],
            modalGroups: 'invoiceDesign',
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "invoice.form.number.missing.message",
          emptyField: "invoiceFont",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "invoice.form.design",
                },
              ],
            },
          ],
        },
      },
      {
        meta: {
          type: "modalSection",
          name: "invoicePayments",
          width: "600px",
        },
        groups: [
          {
            type: "modal",
            label: "This is my Modal",
            control: {
              id: "paymentModal",
              type: "text",
              label: "invoice.form.payment",
              placeholder: "invoice.form.payment.placeholder",
            },
            descriptions: [
              {
                type: "label",
                text: "Payments:",
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "field",
                field: "numPayments",
              }
            ],
            modalGroups: 'invoicePayments',
          },
        ],
        buttons: [],
        display: {
          emptyMessage: "invoice.form.number.missing.message",
          emptyField: "invoiceFont",
          columns: [
            {
              size: 1,
              grow: "100%",
              fields: [
                {
                  size: "title",
                  type: "text",
                  value: "invoice.form.payment",
                  tag:"numPayments"
                },
              ],
            },
          ],
        },
      },
    ],
  },
  invoiceViewPanel: {
    entity: 'invoice',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "invoicePDF",
          width: "600px",
        }
      }
    ],
  },
  creditNoteViewPanel: {
    entity: 'creditNote',
    position: "right",
    width: "500px",
    sections: [
      {
        meta: {
          type: "pdf",
          name: "creditNotePDF",
          width: "600px",
        }
      }
    ],
  },
};
