export const multiply = (data, fieldsToMultiply) => {
    let result = 1;
    for (const field of fieldsToMultiply) {
      result *= data[field];
    }

    return result
}

export const sum = (data, fieldsToAdd) => {
  let result = 0;

  if (Array.isArray(fieldsToAdd)) {
    for (const field of fieldsToAdd) {
      result += data[field];
    }
  
  } else {
    result += data[fieldsToAdd];
  } 

  return isNaN(result) ? 0 : result;
}
