// Function to aggregate data based on specified fields
function aggregate(data, aggregateBy, sumFields, transferFields) {
    const aggregatedData = {};

    data.forEach(item => {
        // Get the key to aggregate by
        const agKey = item[aggregateBy[0]];

        if (!aggregatedData[agKey]) {
            // If this key doesn't exist yet, create a new entry
            aggregatedData[agKey] = { [aggregateBy[1]]: agKey };
            
            // Initialize sum fields
            sumFields.forEach(field => {
                aggregatedData[agKey][field[1]] = item[field[0]];
            });
            
            // Transfer fields that don't need to be summed
            transferFields.forEach(field => {
                aggregatedData[agKey][field[1]] = item[field[0]];
            });
        } else {
            // If the key already exists, sum the specified fields
            sumFields.forEach(field => {
                aggregatedData[agKey][field[1]] += item[field[0]];
            });
        }
    });

    // Convert the aggregated data object to an array
    return Object.values(aggregatedData);
}

// Main function to perform sub-aggregation
const subAggregate = (data, operation) => {
    let response = data;

    // Get the object to aggregate from the data
    const objectToRead = data[operation.entityToAggregate] || [];
    if (!Array.isArray(objectToRead)) {
        console.warn(`Data for ${operation.entityToAggregate} is not an array. Skipping aggregation.`);
        return data;
    }

    // Perform the aggregation
    const aggregatedObject = aggregate(
        objectToRead, 
        operation.aggregateBy, 
        operation.sumFields, 
        operation.transferFields
    );

    // Add the aggregated object to the response under the specified target entity
    response[operation.targetEntity] = aggregatedObject;

    // Return the response if it exists, otherwise return the original data
    return response || data;
}

export default subAggregate;