import React, { useCallback, useMemo, useReducer, useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";

import InputField from "./InputField";
import NumPad from "./NumPad";
import UnitTag from "./UnitTag";
import UnitSelector from "./UnitSelector";
import UpDownArrows from "./UpDownArrows";

import useProfile from "../../hooks/useProfile";
import { useSnapshot } from "valtio";
import useKeyboardFocus from "../../hooks/useKeyboardFocus";

import {
  addThousandSeparator,
  removeThousandSeparator,
  formatDecimalSeparator,
  formatNumber,
} from "../../utils/decimalSeparator";

const units = ['Pcs', 'Hours', "Boxes"];
const currencies = ['USD', 'DKK', 'EUR'];

const NumericInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const initialState = {
  stringValue: '',
  showNumpad: false,
  showUnitSelector: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STRING_VALUE':
      return { ...state, stringValue: action.payload };
    case 'TOGGLE_NUMPAD':
      return { ...state, showNumpad: !state.showNumpad };
    case 'TOGGLE_UNIT_SELECTOR':
      return { ...state, showUnitSelector: !state.showUnitSelector };
    default:
      return state;
  }
}

const NumericInput = React.memo(({ 
  $variant,
  name, 
  unit, 
  value, 
  decimals = 0, 
  placeholder, 
  setValue, 
  isRed,
  allowNegative = true
}) => {
  const { pState } = useProfile();
  const profile = useSnapshot(pState);
  const decimalSeparator = profile.decimalSeparator;

  const inputRef = useRef(null);
  const isFocused = useKeyboardFocus();
  const formatTimeoutRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { stringValue, showNumpad, showUnitSelector } = state;

  const variantConfig = useMemo(() => {
    switch ($variant) {
      case 'amount':
        return { tag: currencies, minDec: 2, maxDec: 2, inputMode: 'decimal' };
      case 'price':
        return { tag: currencies, minDec: 2, maxDec: Math.max(2, decimals), inputMode: 'decimal' };
      case 'quantity':
        return { tag: units, minDec: 0, maxDec: Math.max(0, decimals), inputMode: 'numeric' };
      default:
        return { tag: null, minDec: 0, maxDec: decimals, inputMode: 'decimal' };
    }
  }, [$variant, decimals]);

  const loadValues = useCallback(() => {
    let initialValue = formatNumber(value, variantConfig.minDec, variantConfig.maxDec);
    initialValue = formatDecimalSeparator(initialValue, decimalSeparator);
    initialValue = addThousandSeparator(initialValue, decimalSeparator);
    dispatch({ type: 'SET_STRING_VALUE', payload: initialValue });
  }, [value, variantConfig, decimalSeparator]);

  const saveValue = useCallback((strValue) => {
    const cleanValue = removeThousandSeparator(strValue, decimalSeparator);
    const nValue = parseFloat(cleanValue.replace(",", "."));
    if (!isNaN(nValue)) {
      if (allowNegative || nValue >= 0) {
        setValue({ target: { name, value: nValue } });
      } else {
        setValue({ target: { name, value: 0 } });
      }
    } else if (strValue === '-' && allowNegative) {
      dispatch({ type: 'SET_STRING_VALUE', payload: '-' });
    } else {
      setValue({ target: { name, value: null } });
    }
  }, [name, setValue, decimalSeparator, allowNegative]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  useEffect(() => {
    if (value !== undefined && decimalSeparator && !isFocused) {
      loadValues();
    }
  }, [value, decimalSeparator, isFocused, loadValues]);

  const handleUnitSelect = useCallback((selectedUnit) => {
    dispatch({ type: 'TOGGLE_UNIT_SELECTOR' });
    // Handle the unit change here
  }, []);

  const handleArrowClick = useCallback((amount) => {
    const oldValue = removeThousandSeparator(stringValue, decimalSeparator);
    const parsedOldValue = parseFloat(oldValue.replace(",", "."));
    let newValue = (isNaN(parsedOldValue) ? 0 : parsedOldValue) + amount;
    if (!allowNegative) {
      newValue = Math.max(0, newValue);
    }
    let formattedValue = formatNumber(newValue, variantConfig.minDec, variantConfig.maxDec);
    formattedValue = formatDecimalSeparator(formattedValue, decimalSeparator);
    saveValue(formattedValue);
    dispatch({ type: 'SET_STRING_VALUE', payload: formattedValue });
  }, [stringValue, decimalSeparator, variantConfig, saveValue, allowNegative]);

  const formatInput = useCallback((value) => {
    const nValue = parseFloat(value.replace(",", "."));
    if (!isNaN(nValue)) {
      let validValue = allowNegative ? nValue : Math.max(0, nValue);
      let numString = formatNumber(validValue, variantConfig.minDec, variantConfig.maxDec);
      numString = formatDecimalSeparator(numString, decimalSeparator);
      numString = addThousandSeparator(numString, decimalSeparator);
      dispatch({ type: 'SET_STRING_VALUE', payload: numString });
      saveValue(numString);
    } else if (value === '-' && allowNegative) {
      dispatch({ type: 'SET_STRING_VALUE', payload: '-' });
    } else {
      dispatch({ type: 'SET_STRING_VALUE', payload: '' });
      saveValue('');
    }
  }, [variantConfig, decimalSeparator, allowNegative, saveValue]);

  const handleInputBlur = useCallback((e) => {
    formatInput(e.target.value);
  }, [formatInput]);

  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    // Allow more lenient input during typing
    const sanitizedValue = newValue.replace(new RegExp(`[^0-9${decimalSeparator}${allowNegative ? '-' : ''}]`, 'g'), '');
    
    dispatch({ type: 'SET_STRING_VALUE', payload: sanitizedValue });

    // Clear any existing timeout
    if (formatTimeoutRef.current) {
      clearTimeout(formatTimeoutRef.current);
    }

    // Set a new timeout to format the input after the user stops typing
    formatTimeoutRef.current = setTimeout(() => {
      formatInput(sanitizedValue);
    }, 1000); // Adjust the delay as needed
  }, [decimalSeparator, allowNegative, formatInput]);

  const handleFocus = useCallback(() => {
    const oldValue = removeThousandSeparator(stringValue, decimalSeparator);
    dispatch({ type: 'SET_STRING_VALUE', payload: oldValue });
  }, [stringValue, decimalSeparator]);

  // Clear the format timeout on unmount
  useEffect(() => {
    return () => {
      if (formatTimeoutRef.current) {
        clearTimeout(formatTimeoutRef.current);
      }
    };
  }, []);

  return (
    <NumericInputWrapper>
      <InputField
        inputRef={inputRef}
        value={stringValue}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        handleArrowClick={handleArrowClick}
        onFocus={handleFocus}
        isReadOnly={false}
        isRed={isRed}
        inputMode={variantConfig.inputMode}
        aria-label={`${name} input`}
      />
      {!isRed && (
        <UpDownArrows
          onIncrement={() => handleArrowClick(1)}
          onDecrement={() => handleArrowClick(-1)}
        />
      )}
      {showNumpad && (
        <NumPad
          decimalSeparator={decimalSeparator || '.'}
          onKeyPress={(key) => dispatch({ type: 'SET_STRING_VALUE', payload: stringValue + key })}
          onClose={() => dispatch({ type: 'TOGGLE_NUMPAD' })}
          allowNegative={allowNegative}
        />
      )}
      {unit && (
        <UnitTag
          unit={unit}
          units={variantConfig.tag}
          onChange={handleUnitSelect}
        />
      )}
      {showUnitSelector && (
        <UnitSelector
          units={variantConfig.tag}
          selectedUnit={unit}
          onSelect={handleUnitSelect}
        />
      )}
    </NumericInputWrapper>
  );
});

NumericInput.propTypes = {
  name: PropTypes.string.isRequired,
  $variant: PropTypes.oneOf(['amount', 'price', 'quantity']),
  unit: PropTypes.string,
  value: PropTypes.number,
  decimals: PropTypes.number,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  isRed: PropTypes.bool,
  allowNegative: PropTypes.bool,
};

export default NumericInput;
