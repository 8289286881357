//Importing UI Components
import { useState } from 'react'
import { Form } from '../form/FormComponents'
import { memo } from 'react'

//Setting up subcomponents
import Display from '../display/Display'
import ModalForm from './ModalForm'
import processModalGroups from '../../utils/model/processModalGroups'

const ModalSection = memo(({ section, expanded, toggleExpanded, validated, dispatch, entity, useStore }) => {
    const [showModal, setShowModal] = useState(false)

    //Determines if it needs to load ModalGroups or if it is embedded
    const modalGroups = processModalGroups(section.groups[0])

    if (!section) {
        // Handle the case when section is not provided or is empty
        return <div>No section data available.</div>;
    }

    const runAction = () => {
        setShowModal(true)
    }

    return (

        <Form.Section name='FormSection' width={section.meta.width}>
            <Display
                type='root' section={section} clickAction={runAction} showAction={true} >
            </Display>
            {showModal && <ModalForm
                name='ModalForm'
                mode=''
                isVisible={showModal}
                setIsVisible={setShowModal}
                modalTitle={section.groups[0].control.label}
                groups={modalGroups}
                lineId={0}
                validated={validated}
                section={section}
                entity={entity}
                useStore={useStore}
            >
            </ModalForm>}
        </Form.Section>

    )

})


export default ModalSection