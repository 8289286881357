import { useState } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormGroup } from "../form/FormComponents"
import { RelativeWrapper } from "../generic/Layout"
import Triangle from "../generic/Triangle"
import ModalForm from "./ModalForm"

import generateStrings from "../../utils/generateStrings"
import processModalGroups from "../../utils/model/processModalGroups"

const ModalFormElem = ({ item, lineId, validated, section, entity, useStore }) => {
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()

    const [state] = useStore(s => s)

    let valueField

    //Determines if it needs to load ModalGroups or if it is embedded
    const modalGroups = processModalGroups(item)

    //Checks if there is a value for the first field in the Block
    if (!state[modalGroups[0].id]) {
        valueField = t(item.control.placeholder)
    } else {
        const updatedDescriptions = item.descriptions.map(description => {
            //Translating all the labels before processing.
            if (description.type === 'label') {
                return {...description, text: t(description.text) };
            }
            return description;
        });
        valueField = generateStrings(updatedDescriptions, state)
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    return (
        <>
            <FormGroup name='FormGroup' $ctrlid={item.id} $margin={item.fgMargin} key={item.id}>
                {item.control.label ? <FormGroup.Label>{t(item.control.label)}</FormGroup.Label> : <></>}
                <RelativeWrapper>
                    <FormGroup.Control type={item.control.type} placeholder={t(item.control.placeholder)}
                        onClick={toggleModal} value={valueField}
                        {...(validated && item.isRequired && { required: true })}
                        readOnly
                        style={{ paddingRight: '2rem', textOverflow: 'ellipsis' }}
                    ></FormGroup.Control>
                    <Triangle />
                </RelativeWrapper>
            </FormGroup>
            {showModal && <ModalForm
                name='ModalForm'
                mode=''
                isVisible={showModal}
                setIsVisible={setShowModal}
                modalTitle={item.control.placeholder}
                groups={modalGroups}
                lineId={lineId}
                validated={validated}
                section={section}
                entity={entity}
                useStore={useStore}
            >
            </ModalForm>}
        </>
    )
}

export default ModalFormElem