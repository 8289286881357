import { useEffect } from 'react';

//Function that determines if the ref passed to the Hook is actually visible within the screen area.
import isElementOnScreen from '../utils/isElementOnScreen';

function useOutsideClick(ref, handleClickOutside, mode = 'normal') {
    useEffect(() => {
        function handleClick(e) {
            if (isElementOnScreen(ref.current)) {
                // For the Sidebar, we are checking that the click is outside the sidebar and we check if it is in Mobile mode                
                if (mode === 'sidebar') {
                    if ( ref.current && !ref.current.contains(e.target)) {

                        let element = document.getElementById('sideBarWrapper')
                        let position = window.getComputedStyle(element, null).position;
                        let display = window.getComputedStyle(element, null).display;

                        // Check if The SideBarWrapper has Fixed Position to know it is on mobile.
                        // Check if Target Tag is SVG or Path to know if the user is click the toggle button 
                        if (position === 'fixed' && display === 'flex' && e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
                            handleClickOutside();
                        }
                    }
                }
                //For Modals, we just check if we clicked on the background slur which contains the ref = the modal
                if (mode === 'modal') {
                    if (e.target.contains(ref.current)) {
                        handleClickOutside();
                    }
                }
                //For dropdowns like those used by the switcher component we just check if the ref to the drop down menu exist and if the target is not the actual toggle button. 
                if (mode === 'dropdown') {
                    if(ref.current && e.target.name!== 'Toggle') {
                        handleClickOutside();
                      }
                }
                //For dropdowns like those used by the switcher component we just check if the ref to the drop down menu exist and if the target is not the actual toggle button. 
                if (mode === 'date') {
                    if (ref.current) {
                        if(!ref.current.contains(e.target) && e.target.name!== 'Toggle') {
                            handleClickOutside();
                          }    
                    }
                }
                //For normal cases, we just check if the ref of the object to click outside exists & what is clicked is not contained within it.
                if (mode === 'normal') {
                    if (ref.current && !ref.current.contains(e.target)) {

                        handleClickOutside();
                    }
                }


            }
        }

        //Her we add and remove the eventListener
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref, mode, handleClickOutside]);
}

export default useOutsideClick;