import { Icon_Invoice, Icon_Arrow_Right } from '../../styles/icons'


export const stepViewData = {
  creditNoteWizard: {
    title: "Credit an Invoice",
    description: "Follow the steps to credit your invoice.",
    width: "1024px",
    contentHeight: "512px",
    showPreviousNext: false,
    steps: [
      {
        title: "Select Invoice",
        description: "Select the invoice you want to credit",
        component: {
          type: "ListView",
          endpoint: "invoice",
          config: {
            filters: {
              list: [
                {
                  filterName: 'invoice.list.filter.2',
                  entity: 'invoice',
                  applyFilter: true,
                  filters: [
                    { id: 'state', value: 'final' },
                    { id: 'archived', value: 'false' }
                  ],
                  filterSlug: 'booked',
                  serverFilters: [
                    { id: 'paid', value: 'false' }
                ]
                }
              ],
              marginTop: '0.5rem'
            },
          },
          table: {
            title: 'invoice.list.table.title',
            searchLabel: 'all.list.search',
            searchPlaceholder: 'all.list.search.placeholder',
            orderBy: 'bookedNumber',
            tableKey: 'id',
            columns: [{
              id: 'bookedNumber',
              name: 'invoice.list.table.number',
              length: '100px'
            },
            {
              id: 'customerName',
              name: 'invoice.list.table.customerName',
              length: '250px'
            },
            {
              id: 'date',
              name: 'invoice.list.table.date',
              length: '100px',
              type: 'date'
            },
            {
              id: 'totalGrossSalesAmount',
              name: 'invoice.list.table.amount',
              length: '150px',
              type: 'amount',
              decorator: 'currency'
            }
            ],
            dropOrder: {
              850: [0, 2],
              1024: [2],
            },
            mobile: {
              leftTag: { field: 'customerName' },
              leftSubTag: { field: 'bookedNumber' },
              rightTag: { field: 'totalGrossSalesAmount', type: 'amount' },
              rightSubTag: { field: 'date', type: 'date' },
              leftBadge: null,
              rightArrow: null,
              leftIcon: Icon_Invoice,
              rightIcon: Icon_Arrow_Right
            }
          },
          filter: 0
        }
      },
      {
        title: "Create Credit Note",
        description: "Select what part of this nice invoice you really want to Credit",
        component: { 
          type: "PageView",
          pageType: "creditNoteNewPage",
          options: {
            mode: "new",
            load: true,
            collapse: true,
            resizeable: true,
          }
         }
      }    ]
  }
}