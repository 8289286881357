import WidgetPage from '../../../components/widgetPage/WidgetPage'

const routesService = [
    {
        path: "/service",
        element: WidgetPage,
        props: {
            type: 'service',
            draggable: false,
            resizable: false
        }
    }
]

export default routesService