//Importing the Subcomponents
import styled from "styled-components";
import SideForm from "../sideForm/SideForm";
import PageView from "../pageView/PageView";

const MultiViewWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 1rem;
  max-width: ${({ width }) => (width ? width : "")};
  width: 100%;
`

const FixedView = ({ options, fState, fData, validated, sidePanelConfig, config }) => { 
    return (
      <MultiViewWrapper width={config.width}>
        {sidePanelConfig && sidePanelConfig.position === "left" && (
          <SideForm
            fData={fData}
            fState={fState}
            config={sidePanelConfig}
            validated={validated}
            options={options}
          ></SideForm>
        )}
        <PageView type={config.cardViewType} options={options} fData={fData}
            fState={fState}/>
        {sidePanelConfig && sidePanelConfig.position === "right" && (
          <>
            <SideForm
              fData={fData}
              fState={fState}
              config={sidePanelConfig}
              validated={validated}
              options={options}
            ></SideForm>
          </>
        )}
      </MultiViewWrapper>
    );
  };
  
  export default FixedView;