import { useRef, createContext, useContext, useCallback, useSyncExternalStore, useEffect } from "react";

export default function createFormContext(initialState) {


  function useStoreData() {
    const store = useRef(initialState);
    const subscribers = useRef(new Set());

    const get = useCallback(() => store.current, []);

    const set = useCallback((value, options = {}) => {
      const { replace = false, array = undefined, itemId = undefined } = options; 

      if (array !== undefined) {
        // Handle line update
        let currentLines = store.current[array] || [];
        const lineIndex = itemId;
        currentLines[lineIndex] = replace ? value : { ...currentLines[lineIndex], ...value };
        store.current = { ...store.current, [array]: currentLines };
      } else if (value) {
        // Handle regular state update
        store.current = replace ? value : { ...store.current, ...value };
      }

      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  }

  function FormStateProvider({ children }) {

    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }

  const StoreContext = createContext({});

  function useStore(selector) {
    const store = useContext(StoreContext);

    if (!store) {
      throw new Error("Store not found");
    }

    const state = useSyncExternalStore(
      store.subscribe,
      () => selector(store.get()),
      () => initialState,
    );

    useEffect(() => {
      //console.log('State is: ', state);
    }, [state]);

    return [state, store.set];
  }


  return {
    FormStateProvider,
    useStore,
  };
}