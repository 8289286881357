import React, { useState } from 'react';

// Translation Dependencies
import { useSnapshot } from 'valtio';
import useTranslation from '../../hooks/useTranslation';
import languages from '../../model/languages'

//Import the useClick Outside & the Switcher
import { Switcher } from '../generic/Switcher';

//Import the popUp things I need
import { PopUp } from '../modal/PopUp';
import { modalData } from '../../model/components/modalData';

function LangSwitcher({bg}) {
  const { t, tState } = useTranslation()
  //State to show the Confirmation PopUp
  const [show, setShow] = useState(false)
  const snap = useSnapshot(tState)

  let config = modalData['langSwitch']


  //TEMPORARY FIX
  const language = snap.currentLang

  //This is the function run by selecting an option on Switcher
  const handleSelect = (option) => {
    //Changing the Language
    tState.currentLang = option.id
    localStorage.setItem('language', option.id)
    //Passing the Language to the PopUp and showing the PopUp
    config.params[0] = t(`LanguageSwitcher.${option.id}`)
    setShow(true)
  }

  const options = languages.map(lang => {
    return lang !== language ? (
      //The Option array passed to switcher expects: type, id and name.
      { type: 'item', id: lang, name: t(`LanguageSwitcher.${lang}`) }) : (
      ''
    );
  });

  return (
    <>
      <Switcher
        bg={bg}
        currentOption={t(`LanguageSwitcher.short.${language}`)}
        options={options}
        handleSelect={handleSelect}>
      </Switcher>
      <PopUp show={show} setShow={setShow} config={config} />
    </>
  )


}

export default LangSwitcher
