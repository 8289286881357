// Hook to deal with Profile
import { useEffect } from "react"
import useAuth from "./useAuth"
import useApi from './useApi'
import { pState } from "../context/profileContext"
import countryLanguages from '../data/countryLanguages.json'
import countryCurrencies from '../data/countryCurrencies.json'
import settings from '../../../common/data/settings.js'

//Import Read and Save to Local Store
import { getFromLS, saveToLS } from "../utils/localStore/localStore"

const useProfile = () => {
  const { Auth } = useAuth()
  const { getMe } = useApi()
  let currentLang
  let currentCurrency

useEffect(
  
    () => {
//      console.log('pState has change')
    }
  , [pState]
)

  const initializeProfile = async () => {
    if (Auth.orgId) {
      let response = await getMe(`organization/${Auth.orgId}`)
      saveToLS("cachedProfile", "data", response)

      if (!response.length > 0) {
        response = getFromLS("cachedProfile", "data")
      }

      const organization = response.data
      pState.pagination = settings.pagination
      pState.currentUser = Auth.id
      pState.currentOrg = Auth.orgId
      pState.currentOrgName = organization.name
      pState.currentOrgCountry = organization.country
      pState.currentOrgCountryName = organization.countryName
      if (countryLanguages[organization.country]) {
        currentLang = countryLanguages[organization.country][0]
      } else {
        currentLang = 'en-US'
      }
      if (countryCurrencies[organization.country]) {
        currentCurrency = countryCurrencies[organization.country].currency
      } else {
        currentCurrency = 'USD'
      }
      if (organization?.profile) { pState.profile = organization.profile } else {
        pState.profile = {}
      }
      pState.currentOrgLanguage = currentLang
      pState.currentOrgCurrency = currentCurrency
      pState.decimalSeparator = (1.1).toLocaleString(currentLang).substring(1, 2);
    }
    console.log('PROFILE: ', pState)
  }

  return { initializeProfile, pState };
}

export default useProfile;