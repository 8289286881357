import styled from 'styled-components'
import { Icon_InfoCircle } from '../../../styles/icons'

//Importing Icons
import { forwardRef } from 'react'


const Message = styled.p`
    display: block;
    box-sizing: border-box;
    width: 300px;
    font-size: 1rem;
    margin-bottom: 1rem;
    border-radius: 0px;
    border: 0;
    padding: 0.5rem;

    &.offscreen {
        display: none;
    }    
`

const InputFeedback = forwardRef((props, ref) => {
    return (
        <Message id={props.noteId} className={props.focus && props.value && !props.validInput ? '' : 'offscreen'}>
            {Icon_InfoCircle}
            {props.noteText}
        </Message> 
  )
});

export default InputFeedback