import React from 'react';
import styled from "styled-components";
import useTranslation from "../../hooks/useTranslation";

const CalendarSelect = styled.select`
  border: none;
  margin-inline: 0.5rem;
  font-size: 1.15rem;
  background-color: ${ ({theme}) => theme.dropdown.background };
  color: ${ ({theme}) => theme.font.color };
`


const CalendarMonth = ({ date, onMonthChange, onYearChange }) => {
  const {t} = useTranslation()

  const months = [
    t('calendar.jan'),
    t('calendar.feb'),
    t('calendar.mar'),
    t('calendar.apr'),
    t('calendar.may'),
    t('calendar.jun'),
    t('calendar.jul'),
    t('calendar.aug'),
    t('calendar.sep'),
    t('calendar.oct'),
    t('calendar.nov'),
    t('calendar.dec'),
  ];

  const handleMonthChange = (event) => {
    const month = parseInt(event.target.value);
    onMonthChange(month);
  };

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    onYearChange(year);
  };

  return (
    <div>
      <CalendarSelect value={date.getMonth()} onChange={handleMonthChange}>
        {months.map((month, index) => (
          <option key={index} value={index}>
            {month}
          </option>
        ))}
      </CalendarSelect>
      <CalendarSelect value={date.getFullYear()} onChange={handleYearChange}>
        {getYearOptions().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </CalendarSelect>
    </div>
  );
};

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear - 50; year <= currentYear + 50; year++) {
    years.push(year);
  }
  return years;
};

export default CalendarMonth;
