import {
    Icon_Apps, Icon_Back, Icon_Boxes, Icon_Cart, Icon_Gear, Icon_Help, Icon_Home, Icon_Message, Icon_Org, Icon_Paper, Icon_People, Icon_Invoice, Icon_CreditNote, Icon_Contacts,
    Icon_Location,
    Icon_Warehouse,
    Icon_Rack,
    Icon_Pallet,
    Icon_StockMovement,
    Icon_StockAdjustment
} from '../../styles/icons'


export const sidebarData = {
    home: [
        {
            title: 'home.menu01',
            icon: Icon_Apps,
            path: '/home/apps'
        },
        {
            title: 'home.menu02',
            icon: Icon_Org,
            path: '/home/organization'
        },
        {
            title: 'home.menu03',
            icon: Icon_People,
            path: '/home/user'
        },
        {
            title: 'home.menu04',
            icon: Icon_Gear,
            path: '/home/settings'
        }
    ],
    crm: [
        {
            title: 'crm.menu00',
            path: '/home',
            icon: Icon_Back
        },
        {
            title: 'crm.menu01',
            path: '/crm',
            icon: Icon_Home
        },
        {
            title: 'crm.menu03',
            path: 'crm/contact',
            icon: Icon_People,
        },
        {
            title: 'crm.menu04',
            path: 'crm/product',
            icon: Icon_Boxes,
        },
        {
            title: 'crm.menu05',
            icon: Icon_Home,
            subNav: [
                {
                    title: 'crm.menu05.subMenu01',
                    path: '/home',
                    icon: Icon_Paper
                },
                {
                    title: 'crm.menu05.subMenu02',
                    path: '/reports/reports2',
                    icon: Icon_Paper
                },
                {
                    title: 'crm.menu05.subMenu03',
                    path: '/reports/reports3',
                    icon: Icon_Paper
                }
            ]
        },
        {
            title: 'crm.menu06',
            icon: Icon_Message,
            subNav: [
                {
                    title: 'crm.menu06.subMenu01',
                    path: '/hello',
                    icon: Icon_Paper
                },
                {
                    title: 'crm.menu06.subMenu02',
                    path: '/messages/Message2',
                    icon: Icon_Paper
                },
                {
                    title: 'crm.menu06.subMenu03',
                    path: '/messages/Message3',
                    icon: Icon_Paper
                }
            ]

        },
        {
            title: 'crm.menu07',
            path: '/service',
            icon: Icon_Help,
        }
    ],
    sales: [
        {
            title: 'all.menu.backHome',
            path: '/home',
            icon: Icon_Back
        },
        {
            title: 'all.menu.overview',
            path: '/sales',
            icon: Icon_Home
        },
        {
            title: 'sales.menu.documents',
            icon: Icon_Paper,
            subNav: [
                {
                    title: 'sales.menu.invoices',
                    path: 'sales/invoice',
                    icon: Icon_Invoice,
                },
                {
                    title: 'sales.menu.creditNotes',
                    path: 'sales/creditNote',
                    icon: Icon_CreditNote
                }]
        },
        {
            title: 'sales.menu.contacts',
            path: 'sales/contact',
            icon: Icon_Contacts,
        },
        {
            title: 'sales.menu.products',
            path: 'sales/product',
            icon: Icon_Boxes,
        },
        {
            title: 'sales.menu.settings',
            path: 'sales/settings',
            icon: Icon_Gear,
        },
    ],
    hello: [
        {
            title: 'hello.menu01',
            path: '/home',
            icon: Icon_Home
        },
        {
            title: 'hello.menu02',
            path: '/crm',
            icon: Icon_People
        }

    ],
    service: [
        {
            title: 'service.menu00',
            path: '/home',
            icon: Icon_Back
        },
        {
            title: 'service.menu01',
            path: '/service',
            icon: Icon_Home
        },
        {
            title: 'service.menu02',
            path: 'service/customer',
            icon: Icon_People,
        },
        {
            title: 'service.menu04',
            path: 'crm/product',
            icon: Icon_Cart,
        },
        {
            title: 'service.menu05',
            icon: Icon_Home,
            subNav: [
                {
                    title: 'service.menu05.subMenu01',
                    path: '/home',
                    icon: Icon_Paper
                },
                {
                    title: 'service.menu05.subMenu02',
                    path: '/reports/reports2',
                    icon: Icon_Paper
                },
                {
                    title: 'service.menu05.subMenu03',
                    path: '/reports/reports3',
                    icon: Icon_Paper
                }
            ]
        }
    ],
    inventory: [
        {
            title: 'all.menu.backHome',
            path: '/home',
            icon: Icon_Back
        },
        {
            title: 'all.menu.overview',
            path: '/inventory',
            icon: Icon_Home
        },
        {
            title: 'inventory.menu.products',
            path: '/inventory/product',
            icon: Icon_Boxes
        },
        {
            title: 'inventory.menu.adjustments',
            path: '/inventory/adjustment',
            icon: Icon_StockAdjustment
        },
        {
            title: 'inventory.menu.movements',
            path: '/inventory/movement',
            icon: Icon_StockMovement
        },
        {
            title: 'inventory.menu.locations',
            icon: Icon_Location,
            subNav: [
                {
                    title: 'inventory.menu.warehouses',
                    path: 'inventory/warehouse',
                    icon: Icon_Warehouse,
                },
                {
                    title: 'inventory.menu.racks',
                    path: 'inventory/rack',
                    icon: Icon_Rack
                },
                {
                    title: 'inventory.menu.bins',
                    path: 'inventory/location',
                    icon: Icon_Pallet
                }
            ]
        },
    ],
    purchases: [
        {
            title: 'all.menu.backHome',
            path: '/home',
            icon: Icon_Back
        },
        {
            title: 'all.menu.overview',
            path: '/purchases',
            icon: Icon_Home
        },
        {
            title: 'purchases.menu.contacts',
            path: '/purchases/contact',
            icon: Icon_Contacts
        }
    ]
}