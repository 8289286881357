import styled from "styled-components";

const SwitchButtomWrapper = styled.div`
    display: flex;
    align-items: center ;
`

const FlipSwitch = styled.input`
    width: 3rem;
    height: 1.5rem;
    margin-left: 0rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23868686'/%3e%3c/svg%3e");
    background-position: left center;
    transition: background-position .15s ease-in-out;
    margin-right: 0.5rem;
    background-color: ${ ({theme} ) => theme.btn.grayBg};
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid ${ ({theme} ) => theme.form.border};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2rem;

&:focus {
    outline: 0;
    box-shadow: ${ ({theme} ) => theme.form.textControl.shadow};
}

&:checked {
    background-color:  ${ ({theme} ) => theme.btn.primary};
    border-color: ${ ({theme} ) => theme.btn.primary};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-position: right center;

}

`

const SwitchButton = ({value = false, setValue, switchText, name}) => {

    const handleChange = () => {
        setValue(
            { target: { name: name, value: !value } }
            )
    }

    return (
        <SwitchButtomWrapper >
            <FlipSwitch type="checkbox" id={name} checked={value} onChange = {handleChange} />
            <label htmlFor={name} >{switchText}</label>
        </SwitchButtomWrapper>
    )
}

export default SwitchButton