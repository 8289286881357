// Import Operations
import calcFields from './calculated/calcFields.js';

// Import Configuration
import fieldsLogicData from '../model/logic/fieldsLogicData.js';

const runFieldsLogic = (data, entity, fields, prevData) => {
    let processedData = { ...data }
    
    if (fieldsLogicData[entity]) {
        const config = fieldsLogicData[entity]
        let fieldConfig

        //Loops true the fields to extract the Field Config and runs it.
        fields.forEach(field => {
            if (config[field] !== undefined) {
              fieldConfig = config[field]
            }
            if (fieldConfig) {
                for (const operation of fieldConfig) {
                    if (operation.logicType === 'calcFields') {
                        processedData = calcFields(processedData, operation, prevData)
                    } else
                        if (operation.logicType === 'other') {
                        }
                }
            }
          });
    }

    return processedData
}

export default runFieldsLogic