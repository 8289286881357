function generateStrings(descriptions, state) {
    const result = [];
  
      let generatedString = '';
  
      for (const item of descriptions) {
        if (item.type === 'text' || item.type === 'label') {
          generatedString += item.text;
        } else if (item.type === 'field' && typeof item.field === 'string') {
          // Check if the field exists in the state object
          const fieldParts = item.field.split('.');
          let fieldValue = state;
  
          for (const fieldPart of fieldParts) {
            fieldValue = fieldValue[fieldPart];
            if (fieldValue === undefined) {
              // Field not found, break the loop
              break;
            }
          }
  
          // Append the field value (if found) to the generated string
          if (fieldValue !== undefined) {
            generatedString += fieldValue;
          }
        }
      }
  
      result.push(generatedString);

  
    return result;
  }

  export default generateStrings