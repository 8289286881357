import React from 'react';
import PropTypes from "prop-types";

const NumPad = ({ onKeyPress }) => {
  const handleKeyPress = (value) => {
    if (onKeyPress) {
      onKeyPress(value);
    }
  };

  const handleClear = () => {
    if (onKeyPress) {
      onKeyPress("clear");
    }
  };

  const handleBackspace = () => {
    if (onKeyPress) {
      onKeyPress("backspace");
    }
  };

  const handleDecimal = () => {
    if (onKeyPress) {
      onKeyPress(".");
    }
  };

  return (
    <div className="numpad">
      <div className="numpad-row">
        <button onClick={() => handleKeyPress("1")}>1</button>
        <button onClick={() => handleKeyPress("2")}>2</button>
        <button onClick={() => handleKeyPress("3")}>3</button>
      </div>
      <div className="numpad-row">
        <button onClick={() => handleKeyPress("4")}>4</button>
        <button onClick={() => handleKeyPress("5")}>5</button>
        <button onClick={() => handleKeyPress("6")}>6</button>
      </div>
      <div className="numpad-row">
        <button onClick={() => handleKeyPress("7")}>7</button>
        <button onClick={() => handleKeyPress("8")}>8</button>
        <button onClick={() => handleKeyPress("9")}>9</button>
      </div>
      <div className="numpad-row">
        <button onClick={() => handleClear()}>C</button>
        <button onClick={() => handleKeyPress("0")}>0</button>
        <button onClick={() => handleBackspace()}>←</button>
      </div>
      <div className="numpad-row">
        <button onClick={() => handleDecimal()}>.</button>
      </div>
    </div>
  );
};

NumPad.propTypes = {
  onKeyPress: PropTypes.func.isRequired,
};

export default NumPad;
