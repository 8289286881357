const lf = (value, min = 0, max = 20, lang) => {

    if (typeof value !== 'number' || isNaN(value)) {
        return value;
    }

    return value.toLocaleString(lang, {
        minimumFractionDigits: min || 0,
        maximumFractionDigits: max || 20// set a high maximum to avoid rounding
    })
}

const ld = (date, lang) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date.toLocaleDateString(lang);
};


export function localizeObj(obj, lang) {
   
    function roundAndApply(inputObj) {
        const newObj = {}; // Create a new object to store modified values
        for (const key in inputObj) {
            if (typeof inputObj[key] === 'object' && inputObj[key] !== null) {
                newObj[key] = roundAndApply(inputObj[key]); // Recursively apply localization to nested objects
            } else {
                if (key.toLowerCase().includes('amount')) {
                    if (typeof inputObj[key] === 'number') {
                        newObj[key] = lf(inputObj[key], 2, 2, lang);
                    } else {
                        newObj[key] = inputObj[key]; // If not a number, retain the original value
                    }
                } else if (key.toLowerCase().includes('date')) {
                    newObj[key] = ld(inputObj[key], lang);
                } else {
                    newObj[key] = inputObj[key]; // Copy non-amount and non-date properties directly
                }
            }
        }
        return newObj;
    }

    const localizedObj = roundAndApply(obj);
    return localizedObj;
}




function localizeObject(obj, lf, ld) {
    function roundAndApply(obj) {
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                roundAndApply(obj[key]);
            } else {
                if (key.toLowerCase().includes('amount')) {
                    if (typeof obj[key] === 'number') {
                        obj[key] = lf(obj[key], 2, 2);
                    }
                }
                if (key.toLowerCase().includes('date')) {
                    obj[key] = ld(obj[key]);
                }
            }
        }
    }
    roundAndApply(obj);
    return obj;
}

export default localizeObject