import * as BsIcons from 'react-icons/bs'
import styled from "styled-components";

export const CheckIcon = styled(BsIcons.BsCheck)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 2;
`

export const CrossIcon = styled(BsIcons.BsX)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 2;
`

