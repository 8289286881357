const fieldsLogicData = {
    invoice: {
        paymentTermId: [
            // this recalculates the paymentDate when Payment Term is changed
            {
                logicType: 'calcFields',
                operationType: 'sum.dateByDays',
                conditionField: 'paymentTermId',
                fieldsToSum: ['date', 'paymentTermDays'],
                targetField: 'paymentDate'
            },
            // this sets the value of PaymentDataCalculation to payment Term when Payment Term is changed
            {
                logicType: 'calcFields',
                operationType: 'header.setValue',
                fieldToSet: ['paymentDateCalculation'],
                valueToSet: 'paymentTerm'
            },
            // this resets the Payments array when we change the Payment Term
            // It calculates Installments if paymentSchedule is true
            {
                logicType: 'calcFields',
                operationType: 'sub.calcPayments',
                conditionField: 'paymentTermId',
                subEntity: 'payments',
                transferMap: {
                    'paymentDate': 'paymentDate',
                    'totalGrossSalesAmount': 'paymentAmount',
                }
            },

        ],
        paymentDate: [
            {
                logicType: 'calcFields',
                operationType: 'header.setValue',
                fieldToSet: ['paymentDateCalculation'],
                valueToSet: 'paymentDate'
            }
        ],
        date: [
            {
                logicType: 'calcFields',
                operationType: 'sum.dateByDays',
                conditionField: 'date',
                fieldsToSum: ['date', 'paymentTermDays'],
                targetField: 'paymentDate'
            },
            {
                logicType: 'calcFields',
                operationType: 'header.setValue',
                fieldToSet: ['paymentDateCalculation'],
                valueToSet: 'paymentTerm'
            }
        ]
    },
}

export default fieldsLogicData