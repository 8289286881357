import { IconContext } from 'react-icons/lib'
import styled from 'styled-components';

const IconProvider = ({className, children}) => <IconContext.Provider value={{className}}>{children}</IconContext.Provider>;

export const NavIconColor = styled(IconProvider)` 
  color: ${({theme}) => theme.nav.font};
  stroke: ${({theme}) => theme.nav.font};
  //fill: ${({theme}) => theme.nav.font};
  `

export const AppIconColor = styled(IconProvider)` 
  color: ${({theme}) => theme.font.color};
  stroke: ${({theme}) => theme.font.color};
  //fill: ${({theme}) => theme.font.color};
  `

//App Icons for TABs Below
export const TabIconColor = styled(IconProvider)` 
  color: ${({theme}) => theme.tab.navLinkColor};
  stroke: ${({theme}) => theme.tab.navLinkColor};
 // fill: ${({theme}) => theme.tab.navLinkColor};
  `

export const TabIconColorActive = styled(IconProvider)` 
  color: ${({theme}) => theme.tab.activeColor};
  stroke: ${({theme}) => theme.tab.activeColor};
  fill: ${({theme}) => theme.tab.activeColor};
  `

export const TabIconColorDisabled = styled(IconProvider)` 
  color: ${({theme}) => theme.tab.disabledColor};
  stroke: ${({theme}) => theme.tab.disabledColor};
  fill: ${({theme}) => theme.tab.disabledColor};
  `
