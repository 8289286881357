import { forwardRef } from "react"
import useTranslation from "../../hooks/useTranslation"
import { FormGroup } from "../form/FormComponents"
import FormElem from "./FormElem"

const GroupFormElem = forwardRef(({ item, lineId, validated, section, entity, useStore }, ref) => {
    const { t } = useTranslation();
    return (
        <FormGroup name='FormGroup' key={item.name}>
            <FormGroup.Row $direction={item.direction}>
                {item.fields.map((elem, ix) => {
                    return (
                            <FormGroup.Col key={`subGroup-${ix}`}>
                                {/*elem.label ? <FormGroup.Label>{t(elem.label)}</FormGroup.Label> : <></>*/}
                                {/* Forward the ref to the FormElem component */}
                                <FormElem item={elem} key={`group-${ix}`}
                                    validated={validated} section={section} lineId={lineId} entity={entity} useStore={useStore}
                                    ref={(elem.focus) ? ref : null}
                                />
                            </FormGroup.Col>
                    );
                })}
            </FormGroup.Row>
        </FormGroup>
    );
});

export default GroupFormElem;