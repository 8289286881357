import ListView from "../../../components/listView/ListView";
import Page from "../../../components/pageView/Page";
import WidgetPage from "../../../components/widgetPage/WidgetPage";

const routesPurchases = [
  {
    path: "/purchases",
    element: WidgetPage,
    props: {
      type: "purchases",
      draggable: false,
      resizable: false,
    },
  },
  {
    path: "/purchases/contact",
    element: ListView,
    props: {
      type: "contactList",
    },
  },
  {
    path: "/purchases/contact/:id",
    element: Page,
    props: {
      type: "contactPage",
      options: { mode: "edit", load: true },
    },
  },
  {
    path: "/purchases/contact/new", 
    element: Page,
    props: {
      type: "contactPage",
      options: { mode: "new", load: false },
    },
  },
];

export default routesPurchases;
