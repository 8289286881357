import useAxiosPrivate from './useAxiosPrivate'

const useApi = () => {
    const axiosPrivate = useAxiosPrivate()

    const getMe = async (resource) => {
        const controller = new AbortController();

        try {
            const url = `api/${resource}`
            const response = await axiosPrivate.get(url, {
                signal: controller.signal
            })
            return response.data
        } catch (err) {
            controller.abort()
        }
    }

    const getMeStream = async (entity) => {
        const controller = new AbortController();

        try {
            const url = `api/${entity}`;
            const response = await axiosPrivate.get(url, {
                signal: controller.signal,
                responseType: 'blob', // Set responseType to 'stream' for handling streams
            });

            if (response.status === 500) {
                return { error: 'File doesn\'t exist anymore'}
            }

            console.log('response',response)
            const file = new File([response.data], 'logo.png', { type: response.data.type });

            // Directly return the stream object
            return file;

        } catch (err) {
            console.log('There is an Error')
            console.log('error:',err)
            controller.abort();
            return err.response;
        }
    };

    const putMe = async (entity, data, id) => {
        const controller = new AbortController();

        try {
            let url
            //omits the id in the url if it is a setting we are retrieving.
            if (entity.toLowerCase().includes('settings')) {
                url = `api/${entity}`
            } else {
                url = `api/${entity}/${id}`
            }

            const response = await axiosPrivate.put(url, data, {
                signal: controller.signal
            })
            return response.data
        } catch (err) {
            controller.abort()
            return (err.response.data)
        }
    }

    const postMe = async (entity, data) => {
        const controller = new AbortController();
        try {
            const url = `api/${entity}`
            const response = await axiosPrivate.post(url, data)
            return response.data
        } catch (err) {
            controller.abort()
            return (err.response.data)
        }
    }

    const postMeStream = async (entity, data) => {
        const controller = new AbortController();

        try {
            const url = `api/${entity}`;
            const response = await axiosPrivate.post(url, data, {
                signal: controller.signal,
                responseType: 'blob', // Set responseType to 'stream' for handling streams
            });

            const file = new Blob([response.data],
                { type: 'application/pdf' });

            // Directly return the stream object
            return file;
        } catch (err) {
            controller.abort();
            return (err.response.data)
        }
    };


    const postMeForm = async (entity, file, dataItems) => {
        const controller = new AbortController();

        const formData = new FormData();

        //Append the File to formData
        formData.append('file', file);

        // Append each key-value pair from dataItems
        Object.entries(dataItems).forEach(([key, value]) => {
            formData.append(key, value);
        });

        try {
            const url = `api/${entity}`;
            const response = await axiosPrivate.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: controller.signal,
            });

            // Directly return the stream object
            return response;

        } catch (err) {
            controller.abort();
            return (err.response.data)
        }
    };



    const deleteMe = async (entity, id) => {
        const controller = new AbortController();
        try {
            const url = `api/${entity}/${id}`
            const response = await axiosPrivate.delete(url)
            return response.data
        } catch (err) {
            controller.abort()
            return (err.response.data)
        }
    }

    const testMe = async (entity, id) => {
        axiosPrivate(`${apiURL}/pdf`, {
            method: 'GET',
            responseType: 'blob' //Force to receive data in a Blob Format
        })
            .then(response => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return { getMe, putMe, postMe, deleteMe, postMeStream, getMeStream, postMeForm };
}

export default useApi;