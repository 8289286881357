import axios from 'axios'

//let BASE_URL = 'https://uservice.onrender.com/'
let BASE_URL = 'https://userver-22gy.onrender.com'

if (import.meta.env.VITE_LOCAL_MODE) {
    BASE_URL = 'http://localhost:8000/'
} 

export default axios.create(
    {
        baseURL: BASE_URL
    }
);

export const axiosPrivate = axios.create(
    {
        baseURL: BASE_URL, 
    }
);