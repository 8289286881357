import CardView from "../../cardView/CardView"
import DisplayView from "../../displayView/DisplayView"

const SwitchView = ({ type, options, view }) => { 
  return (
    <>
      {view === 'cardView' && <CardView type={type} options={options} />}
      {view === 'displayView' && <DisplayView type={type} options={options} />}
    </>
  )
}

export default SwitchView