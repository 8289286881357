
export const resetSetValueObject = (valueMap) => {
    const setValueObject = [];
  
    for (const key in valueMap) {
      if (valueMap.hasOwnProperty(key)) {
        const target = {
          name: key,
          value: ''
        };
        setValueObject.push({ target });
      }
    }
  
    return setValueObject;

    
}


function generateSetValueObject(data, valueMap) {
    const setValueObject = [];
  
    for (const key in valueMap) {
      if (valueMap.hasOwnProperty(key)) {
        const target = {
          name: key,
          value: data[valueMap[key]]
        };
        setValueObject.push({ target });
      }
    }
  
    return setValueObject;
  }

  export default generateSetValueObject
  
  
  