import { proxy } from "valtio";

export const pState = proxy({
    currentUser: 'en-US',
    currentOrg: '',
    currentOrgName: '',
    currentOrgCountry: 'US',
    currentOrgCountryName: 'United States',
    currentOrgLanguage: 'en-US',
    currentOrgCurrency: 'USD',
    currentVersion:'1.0.0',
    theme: 'standard',
    pagination: '100',
    newVersion: false
})


