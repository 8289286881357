import PageView from "./PageView"
import createFormContext from '../../context/formContext'
import { ModalProvider } from "../cardView/useFormModal";

const { FormStateProvider, useStore } = createFormContext({
    id: "",
    loaded: false,
    savedOn: "",
});

export { useStore };

const { FormStateProvider: MessageProvider, useStore: useMessage } = createFormContext({
    show: false,
    title: 'ok',
    message: 'Ok',
    type: 'success'
});

export { useMessage };


const Page = ({ type, options, dispatch = (action, data) => { } }) => {

    //console.log('re-rendering Page')
    return (
        <FormStateProvider>
            <MessageProvider>
                <ModalProvider>
                    <PageView type={type} options={options} dispatch={dispatch} />
                </ModalProvider>
            </MessageProvider>
        </FormStateProvider>
    )

}

export default Page