import React from 'react';
import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import useSectionState from "../../hooks/useSectionState";

import { Form } from "../form/FormComponents";
import CardViewFormSection from "../cardView/CardViewFormSection";
import PdfPager from "../pdf/PdfPager";
import { useStore } from "../pageView/Page";
import ModalSection from "../cardView/ModalSection";

const SidePanel = styled.div`
  background: ${({ theme }) => theme.form.panelBg};
  //width: ${(props) => (props.width ? props.width : "400px")};
  width: 100%;
  padding: 1rem;
`;

const SideForm = ({ config, options, formType }) => {
  const [state] = useStore(s => s)

  const { isSmallTablet } = useScreenSize();

  const { expanded, toggleExpanded } = useSectionState(config, options);

  //   This ensures the Sideform is only shown when the Filter is correct.

  return (
    <SidePanel name="SidePanel" width={config.width}>
      <Form>
        <Form.Shell name="Form-Shell" width={config.width}>
          {config.sections &&
            config.sections.map((section, i) => {
              return (
                <React.Fragment key={i}>
                  {section.meta.type === "form" ? (
                    <CardViewFormSection
                      expanded={expanded[section.meta.name]}
                      toggleExpanded={toggleExpanded}
                      section={section}
                      key={i}
                      entity={config.entity}
                      //      dispatch={dispatch}
                      //      validated={validated}
                      useStore={useStore}
                    />
                  ) : section.meta.type === "pdf" ? (
                    <PdfPager entity={config.entity} state={state} />
                  ) : 
                    section.meta.type === "modalSection" ? (
                      <ModalSection
                        expanded={expanded[section.meta.name]}
                        toggleExpanded={toggleExpanded}
                        section={section}
                        key={i}
                        entity={config.entity}
                        //      dispatch={dispatch}
                        //      validated={validated}
                        useStore={useStore}
                      />) : (
                      <></>
                    )
                  }
                  </React.Fragment>
              );
            })}
        </Form.Shell>
      </Form>
    </SidePanel>
  );
};

export default SideForm;
