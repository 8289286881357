// Hook to Change Organization.
// Usage const switchOrg = useOrg()
// switchOrg(orgId)


import useApi from "./useApi"
import useAuth from "./useAuth"
import { useQueryClient } from "react-query"

const useOrg = () => {
  //Declare the variables extracted from the Hooks: for API, Auth and Query Client
  const { postMe } = useApi()
  const { Auth, setAuth } = useAuth()
  const queryClient = useQueryClient()
  
  const switchOrg = async (orgId) => {


    // Setup the needed resources
    const resource = 'organization/switch'
    const data = { id: orgId }

    // run the switch org Endpoint
    const response = await postMe(resource, data)
    const { accessToken, refreshToken } = response
    const { email, id } = Auth

    // Update the Auth
    await setAuth({ email, accessToken, refreshToken, id, orgId })

    // First Refresh the Org List and wait on it to be done
    await queryClient.invalidateQueries('userOrgs')
    // Now you can refetch all Queries: Hurra!
    await queryClient.refetchQueries()

  }

  return { switchOrg }

}

export default useOrg