export const modalData = {
    orgSwitch: {
        header: 'orgSwitch.title',
        body: ['orgSwitch.body.01', 'orgSwitch.body.02'],
        params: [],
        footer: [
            {
                type: 'danger',
                action: 'close',
                text: 'orgSwitch.button.close'
            }
        ]
    },
    langSwitch: {
        header: 'langSwitch.title',
        body: ['langSwitch.body.01'],
        params: [],
        footer: [
            {
                type: 'danger',
                action: 'close',
                text: 'langSwitch.button.close'
            }
        ]
    },
    customerConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['customer.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    contactConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['contact.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    confirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['all.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    productConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['product.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    interactionConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['interaction.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    contactPersonConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['contactPerson.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    organizationConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['organization.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    userConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['user.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    invoiceConfirmDelete: {
        header: 'all.confirm.delete.title',
        body: ['invoice.confirm.delete.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'delete',
                text: 'all.confirm.delete.accept'
            }
        ]
    },
    invoiceConfirmCredit: {
        header: 'all.confirm.credit.title',
        body: ['invoice.confirm.credit.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'credit',
                text: 'all.confirm.credit.accept'
            }
        ]
    },
    invoiceConfirmBook: {
        header: 'all.confirm.book.title',
        body: ['invoice.confirm.book.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'book',
                text: 'all.confirm.book.accept'
            }
        ]
    },
    invoicePreBook:
    {
        header: 'all.confirm.book.title',
        body: ['success.booking.ready.to.go'],
        params: [],
        footer: [
            { type: 'secondary', action: 'close', text: 'all.confirm.cancel' },
            { type: 'primary', action: 'book', text: 'all.confirm.book.accept' }
        ]
    },
    confirmBook: {
        header: 'all.confirm.book.title',
        body: ['all.confirm.book.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'book',
                text: 'all.confirm.book.accept'
            }
        ]
    },
    preBook:
    {
        header: 'all.confirm.book.title',
        body: ['success.booking.ready.to.go'],
        params: [],
        footer: [
            { type: 'secondary', action: 'close', text: 'all.confirm.cancel' },
            { type: 'primary', action: 'book', text: 'all.confirm.book.accept' }
        ]
    },
    invoiceSendDialog:
    {
        header: 'invoice.send.modal.title',
        body: [],
        params: [],
        footer: [
            { type: 'secondary', action: 'close', text: 'all.confirm.cancel' },
            { type: 'primary', action: 'send', text: 'all.confirm.send.accept' }
        ],
        form: {
            section: {
                meta: {
                    type: 'form',
                    name: 'invoiceMessage',
                    width: '512px'
                },
                groups: [
                    {
                        type: 'text',
                        id: 'to',
                        label: 'invoice.send.modal.to',
                        placeholder: 'invoice.send.modal.to.placeholder',
                        isRequired: true,
                        defaultType: 'merge',
                        defaultValue: 'invoice.send.modal.messageTo'
                    },
                    {
                        type: 'text',
                        id: 'subject',
                        label: 'invoice.send.modal.subject',
                        placeholder: 'invoice.send.modal.subject.placeholder',
                        isRequired: true,
                        defaultType: 'merge',
                        defaultValue: 'invoice.send.modal.messageTitle'
                    },
                    {
                        type: 'textarea',
                        id: 'message',
                        label: 'invoice.send.modal.description',
                        rows: 4,
                        placeholder: 'invoice.send.modal.description.placeholder',
                        isRequired: true,
                        defaultType: 'merge',
                        defaultValue: 'invoice.send.modal.message'
                    }
                ],
                buttons: [
                ],
                display: {
                    emptyField: 'subject',
                    columns: [
                        {
                            size: 1,
                            grow: '100%',
                            fields: [
                                {
                                    size: 'title',
                                    type: 'field',
                                    value: 'subject'
                                },
                                {
                                    size: 'regular',
                                    type: 'field',
                                    value: 'messsage'
                                }
                            ]
                        }
                    ]
                }
            },
        },
        msg: {
            localLang: "da-DK",
            invoiceTitle: "Invoice No: ",
            companyName: "Code Setters ApS",
        }
    },
    creditNoteConfirmSave: {
        header: 'all.confirm.save.title',
        body: ['document.confirm.save.body'],
        params: [],
        footer: [
            {
                type: 'secondary',
                action: 'close',
                text: 'all.confirm.cancel'
            },
            {
                type: 'primary',
                action: 'save',
                text: 'all.confirm.save.accept'
            }
        ]
    },
}