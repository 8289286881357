import useApi from "../../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import useTranslation from "../../hooks/useTranslation";
import pause from "../../utils/pause";

//Form State
import useFormValidation from "./useFormValidation";
import useFormModal from "./useFormModal";
import updateObject from "../../utils/updateObject";
import { modalData } from "../../model/components/modalData";
import generateEndpoint from "../../utils/generateEndpoint";
import { useMessage } from "../pageView/Page";


function convertLineBreaksToHTML(text) {
  return text.replace(/\n/g, "<br>");
}

function generateUrl(target, id) {
  return target.replace(':id', id);
}


import { useStore } from "../pageView/Page";

const useFormActions = (mode, config) => {

  //Setting up the State & validation
  const [state, setState] = useStore((s) => s)
  const { setValidated } = useFormValidation()
  const [message, setMessage] = useMessage((s) => s)
  const { setShowModal, setModalConfig } = useFormModal()

  //Id of the entity shown taken from the URL
  const { id, id2 } = useParams()

  //this formats the endpoint to use the first id in the main route in case of a subroute
  let endpoint;
  if (config && config.path !== undefined) {
    endpoint = generateEndpoint(config.path, id);
  }


  //Setting up API and Translation
  const { deleteMe, putMe, postMe } = useApi()

  //Sets up Navigation, QueryClient & back OnSubmit Function
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const backOnSubmit = config?.backOnSubmit || false
  const noNavigation = config?.noNavigation || false
  const submitBehaviour = config?.submitBehaviour || 'back'

  const { t, m, tState } = useTranslation()

  //01 async function called by mutateForm function
  const saveFormFn = async (option) => {
    console.log('Option:', option)
    let response
    let executionMode = mode
    if (option.mode) executionMode = option.mode

    setValidated(true)

    if (executionMode === 'edit' || executionMode === 'confirm') {
      response = await putMe(endpoint, state, state.id)
    } else if (executionMode === 'new') {
      console.log('State on New is now:', state)
      response = await postMe(endpoint, state)
    }
    if (response && !response.error) {
      let title = t('success.updated')
      setMessage({ show: true, title: title, type: 'success', message: '' })
      await pause(1200)
      if (!noNavigation) {
        if (backOnSubmit) { navigate(-1) } else {
          if (submitBehaviour === 'back' && executionMode === 'new') { navigate(`/${tState.currentSlug}/${endpoint}/${response.data.id}`) } else
            if (submitBehaviour === 'list') { navigate(`/${tState.currentSlug}/${endpoint}`) }
        }
      }
    } else if (response.error) {
      let title = t(response.error.name)
      let message = response.error.message
      setMessage({ show: true, title: title, type: 'error', message: message })
    }
    return ({ action: 'completed' })
  }

  //02 Delete Function
  const deleteFormFn = async () => {
    setShowModal(false)
    const response = await deleteMe(endpoint, state.id)
    if (response.error) {
      let title = t(response.error.name)
      let message = response.error.message
      setMessage({ show: true, title: title, type: 'error', message: message })
      await pause(1200)
    } else {
      if (config.entity === 'organization') { navigate('/', { replace: true }) } else {
        navigate(-1)
      }
    }
  }

  // 03 Generic Post Action function
  const postActionFn = async (option) => {
    setShowModal(false)

    const response = await postMe(`${endpoint}/${state.id}/${option.action}`, state)
    if (response.status === 'success') {
      const actions = config.cardTitle.actions
      const title = actions[option.action].message
      setMessage({ show: true, title: title, type: 'success', message: '', action: '' })
      await pause(1000)
      navigate(-1)
    }
    else if (response.status === 'error') {
      console.log(response)
      const title = m(response?.title)
      const message = m(response.message, response.params)
      const actionMessage = m(response?.action?.message, [response?.nextValue])
      const action = { message: actionMessage, formChanges: response?.action?.formChanges }
      setMessage({ show: true, title: title, type: 'warning', message: message, action: action })
      await pause(1000)
    } else if (response.status === 'warning') {
      console.log(response)
      const title = m(response.message, response.params)
      setMessage({ show: true, title: title, type: 'warning', message: '', action: '' })
      await pause(2000)
      if (config.entity === 'organization') { navigate('/', { replace: true }) } else {
        navigate(-1)
      }
    }
    //Necessary so the mutation doesn't fail 
    return response
  }


  //04 Send Function
  const sendFormFn = async (payload) => {
    const msg = { ...payload.config.msg, ...payload.data }
    msg.from = "Velto@velto.io"
    msg.message = convertLineBreaksToHTML(msg.message)
    const data = { ...state }
    const mailBody = { data: data, msg: msg }
    await postMe(`email`, mailBody)
    setShowModal(false)
    setMessage({ show: true, title: 'email Sent', type: 'success', message: 'Your email is on its way' })
    await pause(1000)
  }

  const goTo = (target) => {
    const url = generateUrl(target, state.id)
    navigate(url)
  }

  //This is the function that decides what gets triggered
  const actionHandler = async (option) => {
    let response
    console.log('Option:', option)

    switch (option.action) {
      case 'delete': deleteFormFn()
        break;
      case 'book': await postActionFn(option)
        break;
      case 'archive': await postActionFn(option)
        break;
      case 'unarchive': await postActionFn(option)
        break;
      case 'save': response = await saveFormFn(option)
        break;
      case 'send': await sendFormFn(option.payload)
        break;
      case 'credit': goTo('/sales/creditNote/newFromInvoice/:id')
        break;
      default: await postActionFn(option)
        break;
    }
    return response
  }

  //All actions will run through Form Mutation     
  const formMutation = useMutation({
    mutationFn: actionHandler,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [`${endpoint}`] })
      //Reload user Organizations if organization is changed
      if (config.entity === 'organization') {
        await queryClient.invalidateQueries({ queryKey: [`userOrgs`] })
      }
    },
    onError: async (error) => {
      // Only show generic error if we don't have a structured response
      if (!error?.status) {
        setMessage({ show: true, message: 'There was an error!', type: 'error' })
      }
    }
  })

  // Handle form Change Function
  const handleFormChange = (formChanges) => {

    const updatedData = updateObject(state, formChanges)
    setState(updatedData)

    putMe(endpoint, state, state.id)
    setMessage({ show: false })
  }

  // Run Pre FLight Ops
  const runPreflight = async (option) => {
    setShowModal(false)

    //Check if the action can be posted using: ?trial=true
    const response = await postMe(`${endpoint}/${state.id}/${option.action}?trial=true`, state)

    //If it can run, just show the Confirmation 
    if (response.status === 'success') {
      setModalConfig(modalData[option.modalConfig])
    } else
      // Handle the need for change
      if (response.status === 'error') {
        console.log('error message:', response)
        //This updates what must change
        handleFormChange(response.action.formChanges)
        //This configures the Modal
        const modalDialog = modalData[option.modalPreflight]
        //Update the Modal Text Dynamically
        modalDialog.body = [m(response.message, response.params)]
        console.log(modalDialog.body)
        setModalConfig(modalDialog)
      }
    //This shows the Modal
    setShowModal(true)
  }

  const runConfirm = async (option) => {
    //Fills the modal with the needed data. 
    setModalConfig(modalData[option.modalConfig])
    setShowModal(true)

  }


  //This is the entry function that runs Preflight, Confirm and then ActionHandler
  const handleActions = async (option) => {

    let response
    //01 Runs the preflight first
    if (option.preflight) runPreflight(option)
    //02 Runs the confirm Next else runs the action = option.action
    if (option.confirm) { runConfirm(option) }
    else if (!option.preflight) {
      // Run the Form-Mutation to process the actions -> action handler
      response = await formMutation.mutateAsync(option)
    }
    return response
  }

  return {
    handleActions,
    handleFormChange,
  };
}

export default useFormActions;
