import { sum } from "./core.js"

const headerSum = (data, operation) => {
    const { fieldsToOperate, targetField } = operation
    let result = 0

    if (data) {
            result += sum(data, fieldsToOperate)
            data[targetField] = parseFloat(result);
        }

    return data
}

export default headerSum