import styled from "styled-components";

const Triangle = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(90deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent ${({ theme }) => theme.font.color} transparent;
  display: ${ ({display}) => display }
`;

export default Triangle