import React from 'react';

import { Selector } from "./Common";
import { SelectorOptions } from "./Common";


function UnitSelector(props) {
  const { units, selectedUnit, onUnitSelect } = props;

  const handleClick = (unit) => {
    onUnitSelect(unit);
  };

  return (
    <Selector className="unit-selector">
      {units.map((unit) => (
        <SelectorOptions key={unit} 
          className={`unit-selector__btn ${unit === selectedUnit ? 'selected' : ''}`}
          onClick={() => handleClick(unit)}
        >
          {unit}
        </SelectorOptions>
      ))}
    </Selector>
  );
}

export default UnitSelector;
